.sol-shortcut-container {
  padding: 20px 0;
  background-color: fadeout(@color-1, 90%);

  @media @sm {
    padding: 0;
  }

  & .sv-grid-main {
    padding-right: 0;
    padding-left: 0;
  }
}
.sol-shortcut {
  margin: 10px 40px;

  @media @md {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 30px 0;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 0 0;
  }

  a {
    text-decoration: none;
    max-width: 150px;
    display: inline-block;
  }
  &-container{
    .sol-clearfix;
  }
  &-item {
    display: inline-block;
    float: left;
    height: 110px;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    border-right: 1px solid @gray-3;

    &:last-of-type {
      border-right: none;
    }

    @media (max-width: 1024px) {
      width: 33% !important;
      margin-bottom: 0px;
      height: 170px;
      padding-bottom: 20px;
      padding-top: 25px;
      border-bottom: 1px solid @gray-3;

      & a {
        height: 100%;
      }

      &:nth-child(3) {
        border-right: none;
      }
      &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        border-bottom: none;
      }

    }

    @media @xs {
      width: calc(50% - 1px) !important;
      margin: auto;
      height: 154px;
      padding-top: 15px;
      padding-left: 8px;
      padding-right: 8px;

      @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
        &:first-of-type {
          margin-left: -0.5px;
        }
      }
      &:nth-child(odd):last-child{
        border: none;
      }
      &:nth-child(3) {
        border-right: 1px solid @gray-3;
      }
      &:nth-child(4) {
        border-bottom: 1px solid @gray-3;
      }
      &:nth-child(2), &:nth-child(4) {
        border-right: none;
      }
      &:nth-child(5), &:nth-child(6) {
        border-bottom: none;
      }
      &:nth-child(2), &:nth-child(4), &:nth-child(6) {
        width: 50% !important;
      }
    }

    & a {
      display: block;
      width: 140px;
      height: 100%;
      margin: 0 auto 10px;
      letter-spacing: 0.5px;
      //border: 1px solid @gray-3;
      //border-radius: 50%;
      background-color: transparent;
      padding-top: 20px;
      color: @footer-shortcut-text;
      font-size: 21px;
      text-align: center;
      line-height: 54px;
      transition-property: color, text-decoration;
      transition-duration: 200ms;
      transition-timing-function: ease;

      &:focus, &:hover {
        color: @color-1;
        cursor: pointer;

        & i {
          color: @color-1;
        }
        & span {
          color: @color-1;
          text-decoration: underline;
        }
      }

      & i.fa, i.fal, i.far, i.fas, i.fab {
        font-size: 40px;
        transition-property: color;
        transition-duration: 200ms;
        transition-timing-function: ease;
      }
    }

    & span {
      display: block;
      color: @footer-shortcut-text;
      font-size: 15px;
      line-height: 23px;
      font-weight: 400;
      text-transform: none;
      .transition(color);
    }
  }
}
