.sol-subpage-top-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & .sol-subpage-description {
    flex: 1 0 auto;
    max-width: 770px;
    margin-right: 56px;

    @media @md {
      flex: 1 0 100%;
      margin-right: 0;
    }
  }

  & .sol-subpage-links {
    flex: 1 0 auto;
    max-width: 350px;
    padding: 0 2px;

    @media @md {
      flex: 1 0 100%;
      max-width: none;
    }

    & .sv-text-portlet {
      position: relative;
      margin: 10px 0;
      border: 1px solid @gray-1;
      transition: background-color 0.15s ease-out;
      transition: background-color 0.15s ease-out;
      transition: background-color 0.15s ease-out;
      transition: background-color 0.15s ease-out;

      &:hover {
        color: @white;
        background-color: @color-1;
        border: 1px solid @color-1;
        transition: background-color 0.15s ease-out;
        transition: background-color 0.15s ease-out;
        transition: background-color 0.15s ease-out;
        transition: background-color 0.15s ease-out;

        & a {
          color: @white;
          transition: 0.15s ease-out;
          transition: 0.15s ease-out;
          transition: 0.15s ease-out;
          transition: 0.15s ease-out;

          &::after {
            color: @color-1;
            background-color: @white;
          }
        }
      }

      & p,
      & a {
        display: block;
        width: 100%;
        height: 40px;
        margin: 0;
        color: black;
        font-weight: 400;
        font-size: 21px;
        line-height: 42px;
        text-decoration: none;

        @media @md {
          font-size: 18px;
        }
      }

      & a {
        padding: 0 46px 0 10px;

        &::after {
          position: absolute;
          top: 2.5px;
          right: 10px;
          width: 28px;
          height: 26px;
          padding-top: 6px;
          padding-left: 4px;
          color: @color-1;
          font-weight: 100;
          font-size: 19px;
          font-family: 'Font Awesome 5 Pro';
          line-height: normal;
          text-align: center;
          border: 1px solid @color-1;
          border-radius: 50%;
          content: '\f054';
        }
      }
    }
  }
}
