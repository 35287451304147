.button() {
  .focus-outline;

  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: @button-text;
  font-weight: 400;
  font-size: 19px;
  font-family: @main-font;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: @button-bg;
  border: 1px solid @gray-3;
  cursor: pointer;
  .transition(~  'background-color, color, border');

  &:hover {
    color: @button-text-hover;
    background-color: @button-bg-hover;
    border: 1px solid @color-1;
  }

  &:active {
    top: 1px;
    box-shadow: inset 0 -1px 0 0 @button-box-shadow;
  }

  & i.fa,
  & i.fal,
  & i.far,
  & i.fas,
  & i.fab {
    margin-right: 10px;
    margin-left: -5px;
    font-weight: 400;
  }
}
.button-small() {
  padding: 14px 20px;
  color: @button-small-text;
  font-weight: 400;
  font-size: 18px;
  line-height: 10px;
  text-transform: none;
  background-color: @button-small-bg;
  border: 1px solid @gray-3;
  box-shadow: none;

  &:hover {
    color: @button-small-text-hover;
    background-color: @button-small-bg-hover;
  }

  &:active {
    top: 0;
    box-shadow: none;
  }
}
