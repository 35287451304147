.sol-article-wrapper {
  max-width: 828px;
  margin-right: auto;
  margin-left: auto;

  .sol-article-date {
    display: inline-block;
    width: auto;
    margin-bottom: 10px;
    padding: 0;
    background-color: transparent;

    &-day {
      display: inline-block;
      color: @black;
      font-size: 14px;
    }

    &-month {
      display: inline-block;
      color: @black;
      font-size: 14px;
    }

    &-year {
      display: inline-block;
      color: @black;
      font-size: 14px;
    }
  }

  .sv-image-portlet {
    position: relative;
    overflow: hidden;
  }

  & .pagecontent {
    .sol-clearfix;
  }
}

.sol-news-menu {
  .sol-menu-item[data-expanded='false'] {
    .sol-menu-list {
      display: none;
    }
  }
}
