.sol-widget-decoration-wrapper a {
  text-decoration: none;
}

.sol-widget-decoration {
  overflow: hidden;
  text-decoration: none;
  background-color: @widget-bg;

  & &-img {
    position: relative;
    height: 235px;
    background-position: 50% 50%;
    background-size: cover;
    border-bottom: 2px solid @white;

    @media @md {
      height: 175px;
    }

    @media @sm {
      display: none;
      height: 98px;

      &.keep-img {
        display: block;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: @widget-hover-color;
      opacity: 0%;
      content: '';
      .transition(opacity);
    }
  }

  & .sv-text-portlet {
    position: relative;
    max-height: 175px;
    padding: 5px 25px 5px 12px;
    font-size: 13px;
    line-height: 20px;
    background-color: @color-1;
  }

  & .font-widget-heading {
    display: block;
    margin-top: 12px;
    margin-bottom: 14px;
    font-weight: 300;
    font-size: 22px;
    font-family: @main-font;
    line-height: 30px;
    letter-spacing: 0.5px;
    .transition(color);

    & a {
      position: relative;
      color: @widget-heading-text;
      font-family: @main-font;
      text-decoration: none;

      &[rel='external'] {
        & img {
          display: none;
        }

        &::after {
          position: absolute;
          top: 0;
          left: calc(100% + 8px);
          font-size: 16px;
          font-family: 'Font Awesome 5 Pro';
          content: '\f35d';

          @media @sm {
            top: -4px;
          }
        }
      }
    }

    @media @md {
      font-size: 19px;
    }

    @media @sm {
      font-size: 18px;
    }
  }

  & .font-normal {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.4em;
  }

  &:hover {
    .sol-widget-decoration-img::before {
      opacity: 30%;
    }

    .font-widget-heading {
      & a {
        color: @widget-heading-text-hover;
        text-decoration: underline;
      }
    }

    & .sv-text-portlet {
      background-color: @color-1;
    }
  }
}
