// FILDELNINGSMODUL
.sv-file-portlet {
  .sv-file-portlet-fileicon {
    width: 26px;

    img {
      width: 100%;
    }
  }

  .sv-file-portlet-file {
    width: auto;
  }
}

// LINK LIST PAGINATION
.sv-linklist-portlet > div > div:nth-child(3) {
  & a,
  & span {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-top: 0;
    margin-right: 2px;
    margin-left: 2px;
    padding: 4px 0 0;
    color: @pagination-text;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
    background-color: @pagination-bg;
    border: none;
    border-radius: 100%;
    box-shadow: none;

    &:hover {
      background-color: @pagination-hover-bg;
    }
  }

  & span,
  & span {
    color: @pagination-current-text;
    background-color: @pagination-current-bg;

    & strong {
      font-weight: 300;
    }
  }
}
