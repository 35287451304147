.sol-header-tourism-link {
  position: absolute;
  right: 55px;
  z-index: 2;
  width: 95px;
  height: 55px;
  padding-top: 12px;
  text-align: center;
  background-color: #d12e65;
  border-right: 2px solid #fff;

  & p {
    & a {
      display: block;
      color: #fff !important;
      font-weight: 900;
      font-size: 13px !important;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none !important;
    }
  }

  @media (min-width: 767px) {
    display: none;
  }
}
