@import (inline) 'node_modules/flatpickr/dist/themes/dark.css';

.flatpickr-calendar {
  border-radius: 0;
}

.flatpickr-months .flatpickr-month {
  height: 35px;
}

span.flatpickr-day:not(.startRange):not(.inRange) + span.flatpickr-day.selected.endRange {
  border-radius: 50px;
}

span.flatpickr-day.startRange + span.flatpickr-day.selected.endRange:hover {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  color: white;
  background: @color-1;
  border-color: @color-2;
}

.flatpickr-day.inRange {
  box-shadow: -5px 0 0 #f2f2f2, 5px 0 0 #f2f2f2;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  color: @white;
  background: @color-1;
  border-color: @color-2;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 @color-1;
}
