.sol-pinboard {
  & .sv-defaultlist {
    & .sv-channel-item {
      margin: 10px 0;
      padding: 0;
      padding-top: 35px;
      border-top: 1px solid @gray-2;

      &:last-of-type {
        border-bottom: 1px solid @gray-2;
      }

      & .sol-article-item {
        display: flex;
        flex-wrap: wrap;
        min-height: 110px;
        padding: 0;

        &-date {
          flex: 0 0 78px;
          height: 78px;
          padding-top: 15px;
          text-align: center;
          border: 2px solid white;
          border-radius: 50%;

          @media @sm {
            flex: 0 0 58px;
            height: 58px;
            padding-top: 8px;
          }

          &-month {
            display: block;
            color: @white;
            font-weight: 300;
            font-size: 17px;
            text-align: center;
            text-transform: uppercase;

            @media @sm {
              font-size: 15px;
            }
          }

          &-day {
            display: block;
            margin-bottom: 2px;
            color: @white;
            font-weight: 400;
            font-size: 24px;
            text-align: center;

            @media @sm {
              margin-bottom: 0;
              font-size: 21px;
            }
          }
        }

        &-content {
          flex: 1 0;
          padding: 0 20px;

          @media @sm {
            padding: 0 0 0 10px;
          }
        }

        &-heading {
          margin-bottom: 12px;

          @media @sm {
            margin-bottom: 8px;
          }
        }

        &-desc {
          display: -webkit-box;

          /* autoprefixer: on */
          margin-bottom: 15px;
          overflow: hidden;
          -webkit-line-clamp: 2;

          /* autoprefixer: off */
          -webkit-box-orient: vertical;
        }

        &-event-info-item {
          font-size: 15px;
        }
      }
    }
  }
}
