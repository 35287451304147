.sol-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0 0 0 / 80%);
  transition: opacity 300ms ease;

  &--noscript {
    overflow: auto;
  }
}

.sol-menu-slide-enter,
.sol-menu-slide-leave,
.sol-menu-slide-leave-active {
  opacity: 0%;
}

.sol-menu-slide-enter-active {
  z-index: 9999;
}
