.sol-popular-services {
  & a {
    position: relative;
    display: block;
    padding: 8px 10px 9px 35px;
    color: @services-text;
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    border-bottom: 1px solid @services-border;
    .transition(color);

    &:hover {
      color: @services-text-hover;
      text-decoration: underline;
    }

    & i.fa,
    i.fal,
    i.far,
    i.fas,
    i.fab {
      position: absolute;
      top: 50%;
      left: 9px;
      width: 18px;
      height: 18px;
      margin-top: -9px;
      margin-right: 8px;
      padding-top: 1px;
      padding-left: 1px;
      color: @services-icon;
      font-size: 14px;
      text-align: center;
      border: 1px solid @services-icon;
      border-radius: 50%;
    }
  }
}
