.sol-top-search-field {
  position: relative;

  /* Change Autocomplete styles in Chrome */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px @search-top-field-bg inset;
  }

  input[type='search'],
  input[type='text'] {
    .no-outline-focus;
    .no-appearance;
    .transition(border-color);

    width: 100%;
    height: 50px;
    padding: 0 11px;
    padding-right: 85px;
    color: @search-top-field-text;
    font-weight: 300;
    font-size: 18px;
    font-family: @main-font;
    line-height: 22px;
    background-color: @search-top-field-bg;

    @media @sm {
      height: 40px;
      padding-right: 75px;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
    }

    &:focus {
      border-color: @search-top-field-border-hover;
      box-shadow: 0 0 0 1000px @search-top-field-bg inset;
    }
  }

  button[type='submit'] {
    .no-appearance;
    .focus-outline;
    .transition(background-color);

    position: absolute;
    top: -5px;
    right: 10px;
    width: 60px;
    height: 60px;
    padding-right: 0;
    padding-left: 0;
    color: @search-top-button-text;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    background-color: @search-top-button-bg;
    border: none;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;

    @media @sm {
      top: -6px;
      width: 50px;
      height: 50px;
    }

    & > i.fa,
    & > i.fal,
    & > i.fas,
    & > i.fab,
    & > i.far {
      position: relative;
      top: -2px;
    }
  }

  & > div:hover {
    input[type='search'],
    input[type='text'] {
      border-color: @search-top-field-border-hover;
    }

    button {
      color: @search-top-button-hover-text;
      background-color: @search-top-button-hover;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: #fff !important;
    border: 2px solid white;
  }
}
