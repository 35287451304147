.sol-hamburger {
  .outline-focus;

  width: 30px;
  padding: 0;

  & > span {
    .transition(~'transform, width', 250ms);

    display: block;
    width: 30px;
    height: 2px;
    background-color: @black;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & &__middle {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  &--active &__top {
    width: 32px;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(6.5px) translateZ(0);
  }

  &--active &__middle {
    width: 0;
  }

  &--active &__bottom {
    width: 32px;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(6.5px) translateY(-7.5px) translateZ(0);
  }
}
