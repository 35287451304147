.sol-info-container {
  position: relative;
  padding: 10px 0;
  color: @crisis-text;
  background-color: @info-bg;
  .transition(margin-top, 150ms, linear);

  & .sv-row {
    position: relative;
    display: inline-block;
    margin-bottom: 2px !important;
    padding: 25px 25px 20px 155px;

    @media @md {
      padding: 20px 20px 20px 115px;
    }

    @media @sm {
      padding: 20px 20px 20px 60px;
    }

    & > a {
      position: relative;
      width: 100%;
      text-decoration: none;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:hover {
        text-decoration: underline;
        opacity: 70%;
        transition: opacity 300ms ease;
      }

      @media @sm {
        display: block;
      }

      > i.fa-chevron-right {
        position: absolute;
        top: 50%;
        left: -140px;
        width: 100px;
        height: 100px;
        margin-top: -55px;
        color: @white;
        font-size: 70px;
        line-height: 100px;
        text-align: center;
        vertical-align: middle;
        background-color: @color-1;
        border-radius: 50%;

        @media @md {
          top: 20px;
          left: -85px;
          width: 50px;
          height: 50px;
          margin-top: 0;
          font-size: 30px;
          line-height: 50px;
        }

        @media @sm {
          left: -42px;
          width: 30px;
          height: 30px;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  .sol-info-message {
    .sol-clearfix;

    &-title {
      display: block;
      margin-bottom: 10px;
      color: @info-text;
      font-weight: 400;
      font-size: 25px;
      line-height: 28px;
      text-align: left;
      text-decoration: none;

      @media @sm {
        margin-bottom: 6px;
        font-size: 21px;
        line-height: 24px;
      }
    }

    &-desc {
      color: @info-desc-text;
      font-weight: 400;
      line-height: 1.4em;

      @media @md {
        margin-bottom: 10px;
      }

      @media @sm {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-more {
      display: block;
      text-align: left;

      & span {
        margin-right: 10px;
        color: @info-btn-text;
        font-weight: 600;
        text-decoration: none;
        background-color: @info-btn-bg;
        .transition(background-color, color);

        @media @sm {
          font-weight: 400;
          font-size: 14px;
        }

        .fa-chevron-right {
          margin-left: 5px;
        }
      }
    }
  }

  .sv-grid-main.sv-fluid-grid .sv-row {
    justify-content: flex-start;
  }
}
