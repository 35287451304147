.sol-events-listing-page {
  & li.sv-channel-item {
    padding: 20px 0;
    border-bottom: 1px solid @gray-2;

    @media @sm {
      padding: 20px 0 5px;
    }
  }

  .sol-article-item {
    position: relative;
    min-height: 180px;
    padding: 12px 0 0 330px;

    @media @md {
      padding: 0;
    }

    &.sol-no-img {
      padding-right: 60px;
      padding-left: 20px;
    }

    &-img {
      left: 0;

      @media @md {
        position: relative;
        overflow: hidden;
      }
    }

    &.sol-no-img &-date {
      background-color: @news-item-border;
    }

    &-heading {
      margin-bottom: 16px;
      line-height: normal;
    }

    &-event-info {
      .sol-clearfix;

      margin-top: 10px;

      @media @sm {
        position: relative;
        top: unset;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
      }

      &-item {
        float: left;
        margin-right: 0;
        font-size: 11px;

        & i {
          margin-right: 6px;
          color: @event-item-icon-bottom;
        }

        &.date {
          position: absolute;
          top: calc(50% - (78px / 2));
          right: -36px;
          z-index: 2;
          display: block;
          width: 78px;
          height: 78px;
          padding-top: 17px;
          color: @white;
          background-color: @color-4;
          border: 2px solid @white;
          border-radius: 50%;

          @media @md {
            top: auto;
            bottom: -20px;
            left: -15px;
            z-index: 2;
            margin-left: 0;
          }

          & .sol-article-item-date-day {
            display: block;
            margin-bottom: 2px;
            font-weight: 400;
            font-size: 24px;
            text-align: center;

            @media @sm {
              position: relative;
              top: -4px;
              left: 28px;
              display: inline-block;
              margin-bottom: 0;
              font-size: 19px;
            }
          }

          & .sol-article-item-date-month {
            display: block;
            font-weight: 300;
            font-size: 17px;
            text-align: center;
            text-transform: uppercase;

            @media @sm {
              position: relative;
              top: 13px;
              left: 3px;
              display: inline-block;
              font-size: 15px;
            }
          }
        }

        &.startTime {
          position: relative;
          display: inline-block;
          float: none;
          margin-bottom: 5px;
          color: @black;
          font-size: 16px;

          & i {
            color: @color-4;
            font-size: 20px;
          }
        }

        &.endTime {
          position: relative;
          display: inline-block;
          float: none;
          color: @black;
          font-size: 16px;

          & i {
            color: @color-4;
            font-size: 20px;
          }
        }

        &.location {
          position: relative;
          float: none;
          margin-bottom: 5px;
          color: @black;
          font-size: 16px;

          & i {
            color: @color-4;
            font-size: 20px;
          }
        }

        &.inline-date {
          position: relative;
          display: block;
          float: none;
          margin-bottom: 5px;
          color: #2c2c2c;
          font-size: 16px;
        }
      }

      & .sol-deviding-dash {
        display: inline-block;
        margin-left: 3px;
        font-size: 16px;
      }
    }

    &-content {
      @media @md {
        margin-top: 16px;
        margin-bottom: 12px;
      }
    }
  }
}

.sol-event-pink {
  p.font-btn {
    margin-bottom: 0;
    padding-bottom: 0;

    & a:hover {
      background-color: @color-4;
      border: 1px solid @color-4;
    }
  }
}

.sol-menu-container.pinboard .sol-menu-item .sol-menu-button {
  right: 10px;
}
