.sol-print {
  margin-bottom: 0 !important;

  & > button {
    float: right;
    color: @print-btn-text;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    .no-appearance;

    @media @sm {
      font-size: 15px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  & i.fa,
  & i.fal,
  & i.far,
  & i.fas,
  & i.fab {
    margin-right: 5px;
  }
}
