body {
  overflow-x: hidden;
  color: @general-text;
  font-weight: 300;
  font-size: 18px;
  font-family: @main-font;
  line-height: 22px;
  letter-spacing: 0.4px;
  background-color: @body-bg;
}

p,
p.font-normal,
.font-normal,
.sol-plusbox-anchor,
p.font-description,
.font-description {
  margin-top: 0;
  margin-bottom: 16px;
  color: @normal-text;
  font-weight: 300;
  font-size: 18px;
  font-family: @main-font;
  line-height: 26px;

  @media @md {
    font-size: 19px;
  }

  @media @sm {
    font-size: 17px;
  }

  a&,
  a {
    color: @normal-link;
    text-decoration: underline;

    &:hover {
      color: @normal-link-hover;
    }
  }
}

p.font-description {
  color: @desc-text;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.5px;

  a&,
  a {
    color: @desc-link;

    &:hover {
      color: @desc-link-hover;
    }
  }

  @media @md {
    font-size: 23px;
  }

  @media @xs {
    font-size: 21px;
    line-height: 30px;
  }
}

h1,
.font-heading-1,
h1.font-heading-1 {
  margin-top: 0;
  margin-bottom: 15px;
  color: @h1-text;
  font-weight: 300;
  font-size: 48px;
  font-family: @main-font;
  line-height: 54px;
  letter-spacing: 0.6px;

  @media @md {
    font-size: 31px;
    line-height: 25px;
  }

  @media @sm {
    font-size: 26px;
  }

  @media @xs {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 29px;
    line-height: 31px;
  }
}

h2,
h2.font-heading-2,
.font-heading-2 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: @h2-text;
  font-weight: 400;
  font-size: 22px;
  font-family: @main-font;
  line-height: 1.2em;

  @media @xs {
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
  }
}

h3,
h3.font-heading-3,
.font-heading-3 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: @h3-text;
  font-weight: 400;
  font-size: 18px;
  font-family: @main-font;
  line-height: 1.2em;

  @media @xs {
    font-size: 19px;
  }
}

h4,
h4.font-heading-4,
.font-heading-4 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: @h4-text;
  font-weight: 400;
  font-size: 16px;
  font-family: @main-font;
  line-height: 1.2em;

  @media @xs {
    font-size: 15px;
  }
}

h5,
h5.font-heading-5,
.font-heading-5 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: @h5-text;
  font-weight: 400;
  font-size: 15px;
  font-family: @main-font;
  line-height: 1.2em;

  @media @xs {
    font-size: 14px;
  }
}

p.font-quote,
.font-quote,
blockquote.sv-blockquote p,
blockquote.sv-blockquote-edit p,
p q {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  color: @quote-text;
  font-weight: 300;
  font-size: 23px;
  font-family: @main-font;
  line-height: 29px;

  @media @md {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }

  @media @sm {
    font-size: 18px;
    line-height: 25px;
  }

  @media @xs {
    padding-left: 30px;
  }

  &::before {
    .icon(@fa-var-quote-right, 20px);

    position: absolute;
    top: 0;
    left: 20px;
    line-height: 35px;
    opacity: 30%;

    @media @md {
      left: 5px;
      line-height: 33px;
    }

    @media @xs {
      left: 5px;
      line-height: 31px;
    }
  }
}

blockquote.sv-blockquote,
blockquote.sv-blockquote-edit {
  &::before {
    display: none;
  }
}

ol,
ul {
  &.sol-bullet-list,
  &.sol-numbered-list {
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 30px;
    list-style: none !important;

    & li {
      position: relative;
      margin-bottom: 8px;
      padding-left: 19px;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        font-weight: 700;
      }

      & a {
        color: #0071bb;
        text-decoration: underline;
      }
    }
  }
}

.sol-bullet-list li::before {
  color: @list-icon;
  font-size: 11px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f111';
}

ol,
ul {
  &.sol-numbered-list {
    padding-left: 21px;

    & li {
      padding-left: 36px;
      counter-increment: step-counter;

      &::before {
        top: -6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.6em;
        height: 1.6em;
        color: @white;
        font-weight: 300;
        font-size: 23px;
        font-family: 'Roboto', sans-serif;
        text-align: center;
        background-color: @color-1;
        border-radius: 50%;
        transform: scale(0.6);
        content: counter(step-counter);
      }
    }
  }
}

.env-form-radio__fake {
  &::before {
    top: 2.5px;
    left: 0;
  }
}
