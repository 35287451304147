.sol-news-listing-page {
  & ul.sv-defaultlist {
    margin: 30px 0;

    & li.sv-channel-item {
      padding-bottom: 20px;
      border-bottom: 1px solid @gray-2;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.sol-right {
  &.sol-show-sm {
    display: block;
    margin-top: -10px;
    margin-bottom: 30px;
  }
}
