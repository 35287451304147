.sol-btn {
  .button;
}

.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;

  & > a {
    .button;

    margin-right: 10px;
    margin-bottom: 10px;
    padding-top: 9px;
    padding-bottom: 7px;
  }

  .sol-external-link-icon {
    display: none;
  }
}

.sol-btn-small {
  .button-small();
}

.sv-text-portlet.sol-font-btn-small {
  margin: 20px 0;

  .font-btn {
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 5px;

    & > a {
      .button-small;
    }
  }
}
