.sol-crisis-container {
  position: relative;
  padding: 10px 0;
  color: @crisis-text;
  background-color: @crisis-bg;
  .transition(margin-top, 150ms, linear);

  & .sv-row {
    position: relative;
    display: inline-block;
    margin-bottom: 2px !important;
    padding: 25px 25px 20px 155px;

    @media @md {
      padding: 20px 20px 20px 115px;
    }

    @media @sm {
      padding: 20px 20px 20px 60px;
    }
  }

  & i.fa-exclamation-triangle {
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -55px;
    font-size: 100px;
    line-height: 110px;

    @media @md {
      top: 20px;
      left: 30px;
      margin-top: 0;
      font-size: 45px;
      line-height: 50px;
    }

    @media @sm {
      left: 20px;
      font-size: 25px;
      line-height: 30px;
    }
  }

  .sol-crisis-message {
    .sol-clearfix;

    &-title {
      display: block;
      margin-bottom: 10px;
      color: @crisis-text;
      font-weight: 700;
      font-size: 25px;
      line-height: 28px;
      text-decoration: none;

      @media @sm {
        margin-bottom: 6px;
        font-size: 21px;
        line-height: 24px;
      }
    }

    &-desc {
      color: @crisis-text;
      font-weight: 400;
      line-height: 1.4em;

      @media @md {
        margin-bottom: 10px;
      }

      @media @sm {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-more {
      display: block;
      text-align: left;

      & a {
        margin-right: 10px;
        padding: 10px 12px;
        color: @crisis-btn-text;
        font-weight: 600;
        text-decoration: none;
        background-color: @crisis-btn-bg;
        border: 1px solid @crisis-btn-border;
        border-radius: 0;
        .transition(background-color, color);

        @media @sm {
          padding: 6px 10px;
          font-weight: 400;
          font-size: 12px;
        }

        &:hover {
          color: @black;
          text-decoration: underline;
          background-color: @white;
        }
      }
    }
  }

  .sol-close-crisis {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    color: @crisis-text;
    font-weight: 400;
    cursor: pointer;

    @media @md {
      font-size: 0;
    }

    & i.fa,
    & i.fal,
    & i.fas,
    & i.fab,
    & i.far {
      margin-left: 10px;
      font-weight: 700;
      opacity: 100%;

      @media @md {
        font-size: 20px;
      }
    }
  }

  .sv-grid-main.sv-fluid-grid .sv-row {
    justify-content: flex-start;
  }
}

.sol-no-script {
  i.fa-exclamation-triangle {
    margin-top: -57px;
    font-size: 50px;
  }

  .sv-row {
    padding: 15px 20px 0 90px;
  }

  .sol-crisis-message-title {
    margin-bottom: 5px;
  }
}
