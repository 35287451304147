.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: @header-top-bg;

  @media @md {
    position: absolute;
    top: 84px;
    display: block;
    width: 100%;
    height: 0;

    &.sol-hide-md {
      display: none;
    }
  }

  @media @sm {
    top: 65px;

    &.sol-show-sm {
      display: block;
    }
  }

  & > div {
    position: relative;
    height: 0;
  }

  @media @md-strict {
    .sol-mobile-logo {
      display: none;
    }
  }

  .sol-mobile-logo {
    float: left;
    max-width: 110px;
    max-height: 35px;
    margin-top: -115px;
    margin-left: 12px;
  }

  .sol-menu-toggle-button {
    .focus-outline;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 55px;
    height: 55px;
    padding-top: 35px;
    color: @white;
    font-size: 12px !important;
    text-align: center;
    background-color: @header-menu-bg;
    border: 0;

    @media @md-strict {
      top: 22px;
    }

    @media @sm {
      top: unset;
      bottom: -54px;
    }

    &--active {
      position: fixed;
      z-index: 99999 !important;
      background-color: @color-1;

      @media @md {
        top: 0;
        right: 0;
      }

      & .sol-hamburger {
        width: 65px !important;
        height: 65px;
        margin-top: -18px;
        margin-left: -36px !important;
        padding-top: 18px;
        padding-left: 18px;
      }
    }

    & .sol-hamburger {
      position: absolute;
      top: 8px;
      left: 50%;
      z-index: 9999;
      width: 35px;
      margin-left: -18px;

      & > span {
        display: block;
        width: 35px;
        height: 3px;
        background-color: @white;
        border-radius: 2px;
        transition: transform 200ms;
      }

      & .sol-hamburger__middle {
        margin-top: 6px;
        margin-bottom: 6px;
        transition: width 200ms;
      }

      & .sol-hamburger__bottom {
        margin-bottom: 5px;
      }

      &.sol-hamburger--active {
        & > span {
          margin-left: 2px;
        }

        .sol-hamburger__top {
          width: 37px;
          transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0);
        }

        .sol-hamburger__middle {
          width: 0;
        }

        .sol-hamburger__bottom {
          width: 37px;
          transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0);
        }
      }

      @media @sm {
        right: 10px;
      }
    }

    & .sol-hamburger-text {
      color: @white;
      font-weight: bold;
      font-size: 12px;
      font-family: @main-font;
    }
  }
}

header .sol-mobile-header .sol-menu-toggle-button {
  @media (min-width: 767px) and (max-width: 991px) {
    display: none;
  }
}

header.sol-original-header .sol-mobile-header .sol-menu-toggle-button {
  @media (min-width: 767px) and (max-width: 991px) {
    display: block;
  }
}
