.sol-contact-card {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);

  & .sv-text-portlet {
    min-height: 51px;
    margin-bottom: 10px;
    border-bottom: 1px solid @gray-2;

    .sv-portlet-toolbar {
      font-weight: 600;
    }
  }

  & h1.font-heading-1 {
    .font-normal;

    font-weight: normal;
  }

  & h2.font-heading-2 {
    .font-heading-4;
  }

  & h1.font-heading-1,
  & h2.font-heading-2,
  & h3.font-heading-3,
  & h4.font-heading-4,
  & p.font-normal {
    margin: 0;
  }

  .sv-html-portlet {
    display: none;

    p {
      b {
        font-weight: 700;
      }
    }

    .sv-editing-mode & {
      display: block;
    }
  }
}
