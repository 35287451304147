.env-button {
  border-radius: 0;

  &--primary {
    background-color: @color-1;
  }

  &--ghost {
    font-weight: 400;

    &:hover {
      background-color: @color-1;
    }
  }
}

.env-link {
  text-decoration: underline;
}

.env-switch__slider {
  &::before {
    top: -2px;
  }
}

.env-modal-dialog {
  p.env-text {
    @media @sm {
      font-size: 16px;
    }
  }
}
