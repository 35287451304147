.sol-event-item {
  display: block;
  margin: 12px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  .transition(opacity);

  &:hover {
    opacity: 90%;
  }

  &-content {
    padding: 4px 10px;
    color: @event-item-text-top;
    text-align: left;
    background-color: @event-item-bg-top;
  }

  &-heading {
    display: block;
    font-weight: 700;
    font-size: 13px;
  }

  &-desc {
    display: block;
    margin-bottom: 4px;
    overflow: hidden;
    font-size: 13px;
    line-height: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-info {
    .sol-clearfix;

    padding: 4px 10px;
    color: @event-item-text-bottom;
    font-size: 11px;
    line-height: 19px;
    background-color: @event-item-bg-bottom;

    &-item {
      display: inline-block;
      float: left;
      margin-right: 13px;

      & i.fa,
      & i.fas,
      & i.fal,
      & i.fab,
      & i.far {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
}
