.sv-standardsearch-portlet {
  .sol-did-you-mean,
  .sol-num-hits {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 12px;
  }

  .sv-search-hit {
    position: relative;
    margin-bottom: 20px;
    padding: 20px 50px 20px 40px;
    overflow: hidden;
    background-color: @search-hit-bg;
    border-radius: 2px;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);

    & .font-heading-3 {
      display: block;
      margin-top: 0;
      margin-bottom: 3px;
      color: @search-hit-text;
      font-weight: 800;
      font-size: 15px;
      text-decoration: none;

      &:hover {
        color: @search-hit-text-hover;
        text-decoration: underline;
      }
    }

    & .font-normal {
      font-size: 15px;
      line-height: 1.4em;
    }

    & .sol-hit-info-text {
      font-size: 13px;

      & i.fa,
      & i.fal,
      & i.far,
      & i.fas,
      & i.fab {
        margin-right: 5px;
        margin-left: 10px;
        color: @search-hit-icon;

        &:first-child {
          margin-left: 0;
        }
      }

      & > strong {
        margin-left: 10px;
      }

      & > strong:first-of-type {
        margin-left: 0;
      }
    }

    & .sol-hit-path-text {
      font-size: 12px;

      & a {
        color: @search-hit-path;

        &:hover {
          color: @search-hit-path-hover;
          text-decoration: underline;
        }
      }
    }
  }
}

.sol-section {
  & form.sol-top-search-field {
    & input {
      border-radius: 0;
    }

    & button {
      right: -14px;
    }
  }

  & ol.sv-search-result {
    max-width: 870px;

    & .sv-search-hit {
      padding-top: 20px !important;
      border-bottom: 1px solid @color-2;
      border-radius: 0;

      & img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      & p:last-of-type {
        margin-top: 0;
        font-weight: 400;
        font-size: 17px;

        @media @sm {
          font-size: 14px;
        }
      }
    }
  }
}

.pagination {
  .sol-clearfix;

  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 420px;
  margin-top: 20px;
  padding-bottom: 50px;

  & a,
  & span.current {
    width: 36px;
    height: 36px;
    margin-top: 0;
    margin-right: 10px;
    margin-left: 10px;
    padding: 8px 0 0;
    color: @pagination-text;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
    background-color: @pagination-bg;
    border: none;
    border-radius: 100%;
    box-shadow: none;

    &:hover {
      color: @color-1;
      background-color: @pagination-hover-bg;
    }
  }

  & span.current,
  & span.current:hover {
    color: @pagination-current-text;
    font-weight: 700;
    background-color: @pagination-current-bg;

    & strong {
      font-weight: 300;
    }
  }

  & > *:first-child:not(span),
  & > *:last-child:not(span) {
    position: absolute;
    top: 40px;
    width: auto;
    height: auto;
    margin-top: 20px;
    margin-right: 15px;
    margin-left: 15px;
    padding: 6px 16px;
    color: white;
    font-size: 17px;
    text-decoration: none;
    background-color: @color-1;
    border: 1px solid @color-1;
    border-radius: 0;

    &.prev {
      left: 0;
    }

    &.next {
      right: 100px;
    }

    &:hover {
      color: @pagination-current-bg;
      background-color: @pagination-bg;
    }
  }

  & span.current.next,
  & span.current.prev {
    display: none;
  }
}

.sv-facetedsearch-portlet {
  & > div:last-of-type {
    & > p:first-of-type {
      margin-top: 10px;
      font-size: 16px;

      & strong {
        margin: 0 4px;
      }
    }

    & > div:first-of-type {
      margin-top: 20px;

      @media @sm {
        margin-top: 35px;
        margin-bottom: 20px;
      }
    }
  }

  ol.sv-search-result {
    max-width: none;
  }

  .sol-did-you-mean,
  .sol-num-hits {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .sol-num-hits {
    margin-bottom: 20px;
    font-weight: 400;
  }

  .font-btn {
    .no-appearance;

    padding: 14px 20px;
    color: @black;
    font-weight: 400;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    line-height: 10px;
    text-transform: none;
    background-color: @white;
    border: 1px solid @gray-3;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      color: @white;
      background-color: @color-1;
    }

    i {
      &::before {
        position: relative;
        top: 1px;
      }
    }
  }

  .sv-search {
    &-facets {
      display: flex;
      justify-content: space-between;
      order: 0;
      float: none;
      width: 100%;
      max-width: none !important;
      margin-top: 0.7em;
      margin-left: 0;
      padding: 0 !important;
      background-color: transparent;

      @media @sm {
        flex-direction: column;
      }
    }

    &-facet {
      position: relative;
      width: 45%;
      margin-bottom: 20px;
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      &:nth-child(1) {
        z-index: 5;
      }

      @media @sm {
        width: 100%;
      }

      &-name {
        display: none;
      }

      &__button {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 34px;
        height: 34px;
        background: @color-1;
        border-radius: 50%;
        transform: translate(-50%, -50%);

        @media @sm {
          right: -8px;
        }

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 20px;
          height: 1.5px;
          margin-top: -1px;
          margin-left: -10px;
          background-color: @white;
          transition-timing-function: ease;
          transition-duration: 200ms;
          transition-property: all;
          content: '';
        }

        &::after {
          transform: rotate(90deg);
        }
      }

      &__header {
        .no-appearance;

        position: relative;
        display: block;
        width: 100%;
        margin: 0;
        padding: 15px 50px 16px 15px;
        color: @black;
        font-weight: 400;
        font-size: 19px;
        line-height: 1.1;
        text-align: left;
        text-decoration: none;
        background-color: @white;
        border: 1px solid @gray-1;
        cursor: pointer;

        @media @sm {
          font-size: 17px;
        }

        &.sol-active {
          background-color: @color-1;

          &.sv-search-facet__header {
            color: @white;
          }

          .sv-search-facet__button {
            background-color: @white;

            &::after {
              background-color: @color-1;
              transform: rotate(0deg);
            }
          }
        }
      }

      &-item {
        text-align: left;
        border-bottom: none;

        &-selected {
          a {
            &::before {
              display: inline-block;
              margin-right: 10px;
              font-family: 'Font Awesome 5 Pro';
              content: '\f00d';
            }
          }
        }
      }

      &-items {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        padding: 20px;
        background-color: @white;
        border: 1px solid @gray-2;
        border-bottom: 3px solid @color-1;

        a {
          float: none;
          margin-right: 0;
          color: @color-1;
          font-weight: 400;
          text-decoration: underline;

          &:hover {
            color: @black;
          }
        }
      }
    }

    &-hit {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px 0;
      background-color: @white;
      border-radius: 2px;

      a {
        color: @color-1;
        font-size: 19px;
        text-decoration: underline;

        &:hover {
          color: @black;
        }

        span {
          &:first-child {
            display: table-cell;
          }

          &.sv-search-hit__icon {
            display: inline-block;
            margin-left: 10px;
            padding: 4px;
            color: @white;
            font-size: 17px;
            text-decoration: none;
            background-color: @color-1;
          }
        }
      }

      p {
        margin-bottom: 10px;
      }

      .sol-hit-path-text {
        display: block;
        margin-top: 5px;
        font-size: 15px;

        a {
          font-weight: 400;
          text-decoration: underline;
        }

        img {
          display: none;
        }
      }

      .sol-hit-info-text {
        font-weight: 400;
        font-size: 15px;
      }
    }
  }

  form {
    position: relative;
    max-width: none;

    input[type='text'] {
      .no-appearance;

      box-sizing: border-box;
      width: 100%;
      padding: 0 0 0 25px;
      font-weight: 300;
      font-size: 25px;
      line-height: 34px;
      background-color: @white;
      border: none;
      border-radius: 6px;
      transition-timing-function: ease;
      transition-duration: 200ms;
      transition-property: border-color;

      @media @sm {
        padding: 0 0 0 15px;
        font-size: 16px;
      }
    }

    button[type='submit'] {
      .no-appearance;

      position: absolute;
      right: 0;
      display: block;
      width: 60px;
      padding-right: 0;
      padding-left: 0;
      color: @white;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      background-color: @color-1;
      border: none;
      box-shadow: none;
      cursor: pointer;
      transition-timing-function: ease;
      transition-duration: 200ms;
      transition-property: background-color;

      @media @sm {
        width: 50px;
      }

      i {
        position: relative;
        top: 11px !important;
        width: 60px;
        padding-bottom: 20px;
        font-weight: 300;
        font-size: 25px;

        @media @sm {
          left: -5px;
        }
      }
    }
  }

  .sol-pagination {
    text-align: center;

    .pagination {
      display: inline-block;
      margin-top: 50px;

      span.current.prev,
      span.current.next {
        display: none;
      }
    }
  }

  & .sol-top-search {
    & form {
      padding: 2px;
      background-color: @color-2;

      & > div {
        & > button {
          top: -4px;
          right: 12px;

          @media @sm {
            top: -2px;
          }

          &:focus {
            outline: none !important;
          }
        }

        & > input {
          height: 48px;
          color: @black;
          font-size: 18px;

          @media @sm {
            height: 42px;
          }
        }
      }
    }
  }
}

::-webkit-search-cancel-button {   /* select all "X" buttons in search inputs */
  width: 15px;
  height: 15px;

  /*
   * Base64 encoded custom "X" icon
   * Natively 30x30, but downscaled for highres screens
   */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15px;
  appearance: none;      /* hide the default "X" button */
}
