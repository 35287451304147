.sol-contact,
.sol-related {
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 25px 20px 15px;
  background-color: @related-box-bg;
}

.sol-related {
  &-item {
    display: inline-block;
    margin-bottom: 12px;
    line-height: 23px;
    text-decoration: none;

    div &-item &-item-name {
      color: @related-box-text;
      text-decoration: none;
    }

    &-name {
      color: @related-box-link-text;
      text-decoration: none;
      .transition(color);
    }

    &-size {
      display: inline-block;
      margin-left: 10px;
      color: @related-box-link-text;
      font-size: 19px;
    }

    & i.fal,
    & i.fas,
    & i.far,
    & i.fab {
      position: relative;
      top: 2px;
      width: 27px;
      height: 27px;
      margin-right: 7px;
      padding-top: 5px;
      color: @color-1;
      font-size: 23px;
      line-height: 15px;
      text-align: center;
      border-radius: 50%;

      &.sol-link-icon {
        padding-left: 2px;
        border: 1px solid @related-box-icon;
      }
    }

    &:hover &-name {
      color: @related-box-link-hover;
    }
  }

  & a {
    &:hover {
      & .sol-related-item-name {
        text-decoration: underline;
      }
    }
  }
}

.sol-contact {
  &-item {
    h3 {
      font-weight: 400;
    }

    h4 {
      display: block;
      font-weight: 400;
    }

    h5 {
      display: block !important;
      font-weight: 400;
    }

    p {
      margin: 5px 0;

      & strong {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}
