.sol-more-links {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: auto;
  padding: 45px 45px 30px;

  @media @md {
    padding: 20px 0;
  }

  @media @sm {
    padding: 0 0 20px 5px;
  }

  &-item {
    flex: 0 0 32%;
    padding: 30px 25px;
    text-decoration: none;
    word-break: break-word;
    border-top: 1px solid @gray-2;
    border-right: 1px solid @gray-2;
    border-bottom: none;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: 0 0 29.5%;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      border-top: none;
    }

    &:nth-child(3n) {
      padding-right: 0;
      border-right: none;
    }

    &:nth-child(3n+1) {
      padding-left: 0;
    }

    &:nth-child(3n+2) {
      flex: 0 0 36%;
      padding-right: 25px;
      padding-left: 25px;

      @media @sm {
        flex: 0 0 100%;
        padding: 20px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: 0 0 29.5%;
      }
    }

    &:hover {
      text-decoration: none;

      & > :not(i) {
        text-decoration: underline;
      }

      & i {
        color: @white;
        background-color: @color-1;

        @media @sm {
          color: @color-1;
          background-color: fadeout(@color-1, 90%);
        }
      }

      @media @sm {
        text-decoration: none;
      }
    }

    @media @md-strict {
      flex: 0 0 50% !important;
      padding: 30px !important;
      border: none !important;
    }

    @media @sm {
      flex: 0 0 100%;
      flex: 1 0 100%;
      padding: 20px 0 !important;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-top: none;
      border-right: none;
      border-bottom: 1px solid #e0e0e0 !important;
      border-left: none;
    }

    &--header {
      display: inline-block;
      max-width: calc(100% - 40px);
      padding-top: 2px;
      padding-left: 10px;
      font-weight: 300;
      font-size: 24px;
      line-height: 1.2em;
      vertical-align: middle;

      @media @sm {
        display: inline;
        max-width: none;
        padding-top: 0;
        padding-left: 10px;
        font-size: 18px;
      }
    }

    &--desc {
      display: block;
      margin-top: 10px;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.6em;

      @media @sm {
        display: none;
        font-size: 16px;
      }
    }

    & i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding-left: 0.2em;
      color: @color-1;
      font-weight: 300;
      font-size: 24px;
      line-height: normal;
      text-align: center;
      vertical-align: middle;
      background-color: fadeout(@color-1, 90%);
      border-radius: 50%;
      transition-timing-function: ease;
      transition-duration: 400ms;
      transition-property: background-color, color;

      @media @sm {
        width: 23px;
        height: 23px;
        font-size: 16px;
      }
    }

    &.hide-sm {
      @media @sm {
        display: none;
      }
    }
  }
}

.sol-wide-content-wrapper {
  & .sol-more-links {
    padding: 30px 0;

    @media @sm {
      padding-top: 0;
    }
  }
}

.sol-startpage-wrapper {
  & .sol-more-links {
    margin: 40px auto 0;

    @media @md {
      margin: 10px auto 0;
    }

    @media @sm {
      margin: 5px auto;
    }

    &-item {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 0;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        padding-bottom: 0;
      }

      @media @sm {
        padding-left: 12px !important;
      }

      @media @xs {
        padding-left: 6px !important;
      }
    }
  }
}
