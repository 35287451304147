.sol-section {
  padding-top: 35px;
  padding-bottom: 50px;

  @media @md {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  @media @sm {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @media @xs {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.sol-wide-content-wrapper {
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
}

.sol-article-wrapper,
.sol-subpage-wrapper {
  & .sv-image-portlet {
    margin-bottom: 20px;

    .sv-portlet-image-caption {
      margin-top: 0;
      padding: 8px 5px;
      color: @img-text;
      font-weight: 300;
      font-size: 14px;
      font-family: @main-font;
      line-height: 15px;
      text-align: right;
      background-color: @img-text-bg;
    }
  }

  & .sv-linkicon {
    display: none;
  }

  & .sv-decoration-content {
    & .sv-image-portlet {
      margin-bottom: 0;
    }
  }
}

.sol-startpage-wrapper {
  & .sol-section {
    @media @sm {
      padding-top: 0;
      padding-bottom: 10px;
    }
  }
}
