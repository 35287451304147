.sol-vuejobs__transition-enter-active,
.sol-vuejobs__transition-leave-active {
  transition: opacity 0.2s;
}

.sol-vuejobs__transition-enter,
.sol-vuejobs__transition-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0%;
}

.sol-vuejobs {
  &__list {
    select {
      &:extend(.sol-form-decoration form select);

      // width: 250px;
    }

    &-sorting {
      margin-bottom: 20px;

      label {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &-filters {
      margin-bottom: 20px;

      label {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
      }
    }

    ul {
      margin: 0;
      margin-bottom: 20px;
      padding: 0;
      list-style-type: none;
    }

    &-item {
      &:first-of-type {
        border-top: 1px solid @gray-2;
      }

      padding: 20px 0;
      border-bottom: 1px solid @gray-2;

      h2 {
        font-weight: 300;
        font-size: 24px;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;

          &::before {
            .icon(@fa-var-chevron-right, 24px);

            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            color: @color-1;
            font-weight: 300;
            background-color: fadeout(@color-1, 90%);
            border-radius: 50%;
            transition-timing-function: ease;
            transition-duration: 400ms;
            transition-property: background-color, color;
          }

          @media @sm {
            &::before {
              width: 23px;
              height: 23px;
              font-size: 16px;
            }
          }

          &:hover {
            span {
              text-decoration: underline;
            }

            &::before {
              color: @white;
              background-color: @color-1;
            }
          }
        }
      }

      h3 {
        margin-bottom: 16px;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__application-metadata {
    display: flex;
    flex-direction: column;
    font-size: 15px;

    &-item {
      display: flex;
      margin-bottom: 8px;

      @media @xs {
        flex-direction: column;
      }

      > div {
        margin-right: 8px;
      }
    }
  }

  &__show-more {
    display: flex;
    justify-content: center;

    button {
      padding: 9px 16px;
      font-weight: 300;
      font-size: 16px;
      font-family: @main-font;
      background-color: @white;
      border: 1px solid @black;
      cursor: pointer;

      &:hover {
        color: @white;
        background-color: @color-1;
        border-color: @color-1;
      }
    }
  }
}

.sol-vuejobs__assignment {
  &-sharing {
    margin-bottom: 16px;
  }

  &-sharing-links {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      color: @color-1;
      font-size: 22px;
      text-decoration: none;
      background-color: fadeout(@color-1, 90%);
      border-radius: 50%;
      transition-timing-function: ease;
      transition-duration: 400ms;
      transition-property: background-color, color;

      &:hover {
        color: @white;
        background-color: @color-1;
      }
    }
  }

  &-details {
    section {
      margin-bottom: 20px;
    }
  }

  &-details-item {
    margin-bottom: 8px;
    font-size: 15px;

    &-name {
      margin-right: 8px;
      font-weight: bold;
    }

    &-value {
      font-weight: 400;
      font-size: 15px;
    }
  }

  &-apply-link {
    .sol-action-link;
  }

  &-details-contact {
    margin-bottom: 8px;
    font-size: 15px;

    &-name {
      font-weight: bold;
    }
  }

  &-description {
    margin-top: 35px;
    border-top: 2px solid @gray-2;

    & h2 {
      font-weight: 300;
      font-size: 48px;
      line-height: 54px;
    }

    & h3 {
      font-weight: 400;
      font-size: 22px;
      line-height: 1.2em;
    }
  }
}
