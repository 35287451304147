@open-sans-path: '@{sol-font-path}/open-sans';
@roboto-path: '@{sol-font-path}/Roboto';

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Light.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Light.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-LightItalic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-LightItalic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Regular.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Regular.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Italic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Italic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Semibold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Semibold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-Bold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-Bold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-BoldItalic.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-BoldItalic.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('@{open-sans-path}/OpenSans-ExtraBold.eot') format('embedded-opentype'),
  url('@{open-sans-path}/OpenSans-ExtraBold.woff') format('woff'),
  url('@{open-sans-path}/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('@{roboto-path}/roboto-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
       url('@{roboto-path}/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('@{roboto-path}/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
       url('@{roboto-path}/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('@{roboto-path}/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('@{roboto-path}/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('@{roboto-path}/roboto-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
       url('@{roboto-path}/roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('@{roboto-path}/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('@{roboto-path}/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('@{roboto-path}/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('@{roboto-path}/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('@{roboto-path}/roboto-v20-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
       url('@{roboto-path}/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('@{roboto-path}/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */
       url('@{roboto-path}/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('@{roboto-path}/roboto-v20-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
