footer {
  .sol-bottom {
    padding: 80px 0 70px;
    background-color: @footer-bg;

    @media @sm {
      padding: 40px 20px 30px;
    }

    & .sv-grid-main .sv-row > .sv-column-4 {
      padding-right: 30px;

      @media @md {
        padding-right: 20px;
      }
    }

    h1,
    p {
      &.font-footer-header {
        margin-top: 0;
        margin-bottom: 28px;
        padding-bottom: 25px;
        color: @footer-text;
        font-weight: 300;
        font-size: 28px;
        font-family: @main-font;
        line-height: 22px;
        border-bottom: 2px solid @white;
      }
    }

    .sol-footer-contact,
    .sol-footer-links {
      &-item {
        position: relative;
        display: block;
        margin-bottom: 10px;
        padding-left: 0;
        color: @footer-text;
        font-size: 18px;
        white-space: nowrap;
        text-decoration: none;

        & i.fa-chevron-right {
          width: 30px;
          height: 31px;
          margin-right: 12px;
          padding-top: 5px;
          padding-left: 2px;
          color: @white;
          font-size: 19px;
          text-align: center;
          vertical-align: middle;
          border: 1px solid @white;
          border-radius: 50%;

          @media @md {
            display: inline-block;
            vertical-align: top;
          }
        }

        & i.fa-external-link {
          position: relative;
          top: -5px;
          right: 0;
        }

        & span {
          padding-top: 5px;
          vertical-align: middle;

          @media @md {
            display: inline-block;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        & a,
        & p {
          display: block;
          margin: 0;
          color: @footer-text;
          font-weight: 300;
          font-size: 18px;
          line-height: 20px;
          text-decoration: none;
        }
        .transition(opacity);

        & i.fal.fa-info-circle {
          top: -3px;
        }

        & i.fal.fa-phone {
          top: -3px;
          transform: rotate(120deg);
        }
      }

      & a:hover {
        text-decoration: underline;
        opacity: 70%;
      }
    }

    .sol-footer-contact {
      & a {
        text-decoration: underline;
      }

      &-item {
        margin-bottom: 25px;

        &:nth-child(3),
        &:nth-child(3) {
          margin: 30px 0;
        }
      }
    }

    .sol-social-media {
      .sol-clearfix;

      .sol-social-media-item > a {
        float: left;
        width: 55px;
        height: 55px;
        margin-right: 12px;
        color: @white;
        font-size: 32px;
        line-height: 55px;
        text-align: center;
        border: 1px solid @white;
        border-radius: 50%;
        .transition(opacity);

        &:hover {
          color: @color-1;
          background-color: @white;
        }
      }
    }
  }

  p.font-footer-text {
    margin-bottom: 11px;
    color: @footer-text;
    font-weight: 700;
    font-size: 14px;
    font-family: @main-font;
    line-height: 22px;

    a:hover {
      color: fadeout(@footer-text, 30%);
      text-decoration: underline;
    }
  }
}
