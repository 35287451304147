.sol-top-nav {
  .sol-clearfix;

  display: flex;
  min-height: 55px;
  vertical-align: top;

  @media @sm {
    display: none;
  }

  &-item {
    .focus-outline;

    display: flex;
    flex: 1 0;
    flex-direction: column;
    justify-content: center;
    float: left;
    height: 74px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 18px 12px;
    color: @black;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    cursor: pointer;
    .transition(background-color, color);

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      height: 90px;
    }

    @media @sm {
      color: @header-menu-text;
    }

    &:hover {
      color: @black;
      text-decoration: underline;
      background-color: @header-menu-hover;
    }

    &.sol-active {
      color: @white;
      background-color: @header-menu-active;
    }
  }
}
