.sol-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 4px 0 20px 290px;
  border-radius: 0;
  background-color: @news-item-bg;
  font-size: 18px;
  line-height: 20px;
  min-height: 180px;
  cursor: pointer;
  text-decoration: none;
  @media @md {
    padding: 310px 0 0 0;
  }
  @media @sm {
    padding: 210px 0 0 0;
  }
  &.sol-no-img {
    padding-left: 60px;
    @media @sm {
      padding-top: 20px;
    }
  }
  & &-img {
    position: absolute;
    left: 0;
    top: 12.5px;
    bottom: 0;
    width: 276px;
    height: 155px;
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none;
    @media @md {
      width: 100%;
      height: 300px;
      background-color: @gray-2;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media @sm {
      width: 100%;
      height: 200px;
      background-color: @gray-2;
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    @media @xs {
      width: 100%;
      height: 200px;
      background-size: cover;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(background-color);
    }
  }
  & &-date {
    position: relative;
    background-color: transparent;
    color: @black;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    top: 6px;
    margin-bottom: 12px;

    @media @sm {
      margin-top: 12px;
      font-size: 14px;
    }
  }
  &.sol-no-img &-date {
    background-color: @news-item-border;
  }
  & &-heading {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: 0.7px;
    .transition(color);

    @media @sm {
      font-size: 21px;
      font-weight: 400;
    }
  }
  & &-cancelled {
    font-style: italic;
  }
  & &-desc {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    font-size: 16px;
    line-height: 21px;
    max-height: 65px;
  }
  & &-event-info {
    .sol-clearfix;
    margin-top: 10px;
    &-item {
      margin-right: 13px;
      font-size: 11px;
      float: left;
      & i {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
  &:hover &-img:before {
    background-color: @news-item-img-hover;

    @media @md {
      background-color: transparent;
    }
  }
  &:hover &-heading {
    color: @news-item-text-hover;
    text-decoration: underline;
  }
}

//// EVENTS

.sol-startpage-events {
  & li.sv-channel-item {
    padding: 20px 0px 20px 20px;

    @media (max-width: 1200px) {
      padding: 20px 0px 10px 0;
    }
    @media @md {
      padding: 60px 0px 10px 0;
    }
    @media @sm {
      padding: 0 0 24px 0;
    }
  }
  .sol-article-item {
    position: relative;
    padding: 12px 0px 0px 320px;
    min-height: 180px;

    @media @md {
      padding: 0;
      min-height: 180px;
    }
    @media @sm {
      padding-right: 20px;
      padding-left: 20px;
    }
    &.sol-no-img {
      padding-right: 60px;
      padding-left: 20px;
    }
    &-img {
      left: 0;

      @media @md {
        overflow: hidden;
        position: relative;
        top: unset;
      }
    }
    &.sol-no-img &-date {
      background-color: @news-item-border;
    }
    &-heading {
      margin-bottom: 10px;
      line-height: normal;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media @sm {
        margin-bottom: 10px;
      }
    }
    &-event-info {
      .sol-clearfix;
      margin-top: 10px;
      max-height: 116px;

      @media @sm {
        position: static;
        left: 0;
        bottom: 0;
        top: unset;
        height: auto;
        width: 100%;
        margin-top: 10px;
      }

      &-item {
        margin-right: 0px;
        font-size: 11px;
        float: left;
        & i {
          margin-right: 6px;
          color: @event-item-icon-bottom;
        }

        &.date {
          position: absolute;
          // margin-left: -90px;
          z-index: 2;
          top: calc(50% - (78px / 2));
          right: -36px;
          padding-top: 17px;
          display: block;
          height: 78px;
          width: 78px;
          border: 2px solid @white;
          border-radius: 50%;
          background-color: @color-4;
          color: @white;

          @media @md {
            position: absolute;
            margin-left: 0;
            left: -15px;
            z-index: 2;
            top: unset;
            bottom: -20px;
          }

          & .sol-article-item-date-day {
            text-align: center;
            font-size: 24px;
            margin-bottom: 2px;
            font-weight: 400;
            display: block;

            @media @sm {
              font-size: 19px;
              margin-bottom: 0;
              display: inline-block;
              position: relative;
              left: 28px;
              top: -4px;
            }
          }
          & .sol-article-item-date-month {
            text-align: center;
            font-size: 17px;
            font-weight: 300;
            display: block;
            text-transform: uppercase;

            @media @sm {
              font-size: 15px;
              display: inline-block;
              position: relative;
              top: 13px;
              left: 0px;
            }
          }
        }
        &.startTime {
          position: relative;
          display: inline-block;
          float: none;
          margin-bottom: 5px;
          font-size: 16px;
          color: @black;

          & i {
            font-size: 20px;
            color: @color-4;
          }
        }
        &.endTime {
          position: relative;
          display: inline-block;
          float: none;
          font-size: 16px;
          color: @black;

          & i {
            font-size: 20px;
            color: @color-4;
          }
        }
        &.location {
          position: relative;
          float: none;
          margin-bottom: 5px;
          font-size: 16px;
          color: @black;

          & i {
            font-size: 20px;
            color: @color-4;
          }
        }
        &.inline-date {
          margin-bottom: 5px;
          position: relative;
          display: block;
          float: none;
          font-size: 16px;
          color: #2c2c2c;
        }
      }
      & .sol-deviding-dash {
        display: inline-block;
        font-size: 16px;
        margin-left: 0px;
        margin-right: -3px;
      }
    }
    &-content {
      @media @md {
        margin-top: 16px;
      }
      @media @sm {
        margin-bottom: 12px;
      }
    }
  }
}
.sol-startpage-content-bottom {
  & .sol-startpage-events {
    & li {
      border-right: none;

      & .sol-article-item {
        @media @sm {
          padding: 0;
          position: relative;
        }
      }
    }
    & + .sol-font-btn-small {
      margin-left: 20px;

      @media (max-width: 1200px) {
        margin-left: 0;
      }

      & .sv-text-portlet-content {
        & .font-btn {
          & a {
            &:hover {
              background-color: @color-4 !important;
              color: white;
              border: 1px solid @color-4 !important;
            }
          }
        }
      }

      @media @sm {
        margin-left: 0;
      }
    }
  }
  & li {
    max-height: 220px;
    border-top: 1px solid @gray-2;
    border-right: 1px solid @gray-2;
    padding: 20px 20px 20px 0;

    @media (max-width: 1200px) {
      border-right: none;
    }
    @media @md {
      max-height: none;
      padding: 60px 0 20px 0;

      &:first-of-type {
        padding: 0px 0 24px 0;
      }
    }

    @media @sm {
      padding: 20px 0;
      border: none;
      border-bottom: 1px solid @gray-2;
    }

    &:nth-child(1) {
      border-top: none;
    }
  }
  & .sv-grid-main.sv-fluid-grid *[class*='sv-column-'] {
    padding: 0 !important;
  }
  & #h-Evenemang,
  & #h-Nyheter {
    padding-left: 20px;

    @media (max-width: 1200px) {
      padding-left: 0px;
    }
    @media @sm {
      font-size: 27px;
      border-top: 3px solid @gray-2;
      padding-top: 24px;
      margin-top: 22px;
    }
  }
  & #h-Nyheter {
    padding-left: 0;
  }
  & .font-btn {
    margin-left: 0px;

    & a {
      &:hover {
        background-color: @color-1 !important;
        color: @white !important;
        border: 1px solid @color-1;
      }
    }

    @media @sm {
      margin-left: 0px;
    }
  }
  .sol-font-btn-small {
    @media @sm {
      margin-bottom: 0;
      margin-top: 22px;
      max-height: 48px;
    }
  }
  @media @sm {
    margin-bottom: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
  padding: 0 !important;
}

.sol-news-menu {
  & .sol-menu-item[data-is-current='true'] > .sol-menu-item-link {
    color: fadeout(@color-1, 20%);
  }
  & .sol-menu-item {
    & div {
      display: block;
      line-height: 26px;
    }
  }
  & .sol-sub-menu-container {
    & li {
      & a {
        border-top: 0 !important;
        padding-top: 14px;
        padding-bottom: 10px;
        height: 45px;
      }
      & button,
      & .sol-menu-button {
        height: 45px !important;
        width: 45px !important;
        & i {
          top: 6.5px !important;
          margin-top: 0 !important;
          right: 0px;
          height: 30px;
          width: 30px;
          text-align: center;
          font-size: 17px;
          padding-top: 6.5px;
          background-color: transparent;
          border: 1px solid @gray-2;
          color: @black;
          border-radius: 50%;

          &:after {
            position: relative;
            left: 1px;
          }
        }
      }
    }
  }
  .sol-menu-item[data-expanded='true'][data-menu-level='1'] {
    background-color: none !important;
    & > .sol-menu-item-link {
      background-color: fadeout(@gray-2, 80%) !important;
    }
  }
  .sol-menu-item[data-menu-level='2'] {
    background-color: none !important;
    & > .sol-menu-item-link {
      border-color: #fff !important;
      background-color: fadeout(@gray-2, 60%) !important;
    }
  }
}
