.sol-startpage-top-images {
  position: relative;

  &-item {
    height: 450px;
    background-position: 50% 50%;
    background-size: cover;

    @media @sm {
      height: 200px;
    }
  }

  & .slick-dots {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 5px;
    left: 0;
    height: 30px;
    text-align: center;

    & li {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      margin: 0 3px 5px;
      .transition(~'background-color, color', 100ms);

      & button {
        display: none;
      }

      &.slick-active {
        background-color: white;
      }
    }
  }

  &-content {
    position: absolute;
    width: 660px;
    left: 0;
    top: 140px;
    right: 0;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    border: none;

    @media @md-strict {
      top: 180px;
    }

    @media @sm {
      position: static;
      width: 100%;
      border-radius: 0;
      height: 80px;
      background-color: @color-2;
    }

    & .sol-top-search {
      padding: 0;

      @media @sm {
        //display: none;
      }
      & form {

        @media @sm {
          top: 15px;
          width: 88%;
          margin: auto;
        }
        & input {
          height: 50px;
          border-radius: 0px;
          border: none;
          font-size: 18px;
          line-height: 26px;
          color: @black;
          padding: 0 78px 0 16px;
        }
        & button[type=submit] {
          top: -6px;
          right: 10px;
          width: 62px;
          height: 62px;

          & i.far {
            top: 0;
            font-size: 27px;
          }
        }
      }
    }
  }
}
.sol-top-image__container {
  width: 100%;
  height: 540px;
/*   -webkit-box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5); */
  background-size: cover !important;
  background-position: center !important;

  @media @sm {
    height: 220px;
  }
}

//** Display popular pages links only on subweb Bostäder (mobile) **//
.sol-subweb-bostader .sol-startpage-top-images-content {
  @media @sm { 
    height: 160px;
   } 
 }