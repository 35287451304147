.sol-event-info {
  .sol-simple-box-decoration {
    margin-top: 0;
  }

  &-field {
    display: inline-block;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    font-weight: 300;
    font-size: 15px;

    & > span {
      margin-right: 8px;
      font-weight: 600;
      font-size: 16px;
    }

    & .sol-category-devider {
      margin-right: 4px;
      margin-left: -3px;
      font-weight: normal;
      font-size: 15px;
    }

    & a {
      position: relative;
      display: inline-block;
      margin: 5px 0 2px;
      color: @color-1;

      &:hover {
        color: lighten(@color-1, 20%);
      }

      & i {
        position: absolute;
        top: 5px;
        right: -25px;
        color: #0071bb;
      }
    }
  }

  & .only-edit {
    display: none;
  }
}

.sol-event-info-small {
  .sol-clearfix;

  margin-top: 10px;
  margin-bottom: 10px;

  &-item {
    float: left;
    margin-right: 20px;
    font-size: 14px;

    & i {
      margin-right: 6px;
      color: @event-item-icon-bottom;
    }
  }
}

.sv-edit-mode {
  & .sol-event-info {
    & .only-edit {
      display: block;
    }
  }
}
