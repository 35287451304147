.sol-form,
.sol-form-decoration {
  margin-bottom: 20px;
  padding: 20px 20px 40px;
  background-color: @form-bg;
  border-radius: 3px;

  @media @md {
    padding: 20px;
  }

  @media @sm {
    padding-right: 10px;
    padding-left: 10px;
  }

  & h2 {
    margin-top: 0;
  }

  & form {
    & input[type='email'],
    & input[type='number'],
    & input[type='password'],
    & input[type='tel'],
    & input[type='text'],
    & input[type='url'],
    select,
    textarea {
      .input-text;

      letter-spacing: 0.6px;

      &:invalid {
        .input-text-invalid;
      }

      &:required:valid {
        .input-text-valid;
      }

      & ~ span.font-normal {
        margin-top: 10px;
      }
    }

    & textarea {
      height: auto;
    }

    select {
      .input-select;
    }

    & .sol-form-field {
      margin-bottom: 10px;
    }

    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & > label {
        .input-label;
      }

      & > span {
        display: block;
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 14px;
      }
    }

    & .sv-form-datefield {
      & > div {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        float: none !important;
        margin: 0 !important;
        padding-left: 25px;

        &:nth-of-type(1) {
          @media @sm {
            margin-bottom: 20px !important;
          }

          & > select {
            flex: 0 0 30%;
            margin-right: 1%;

            @media @sm {
              flex: 0 0 100%;
            }
          }

          &::before {
            position: absolute;
            top: 9px;
            left: 0;
            font-weight: 100;
            font-size: 21px;
            font-family: 'Font Awesome 5 Pro';
            content: '\f073';
          }
        }

        &:nth-of-type(2) {
          & > select {
            flex: 0 0 30%;
            margin-right: 1%;

            @media @sm {
              flex: 0 0 100%;
            }
          }

          &::before {
            position: absolute;
            top: 9px;
            left: 0;
            font-weight: 100;
            font-size: 21px;
            font-family: 'Font Awesome 5 Pro';
            content: '\f017';
          }
        }

        & select {
          margin-bottom: 10px;
        }
      }
    }

    & br {
      display: none;
    }

    .font-normal {
      display: block;
    }

    & .sv-formField {
      margin-bottom: 0;

      @media @sm {
        width: 100%;
        padding: 0;
      }
    }

    & input[type='checkbox'] {
      position: relative;
      top: 0;
      display: none;
      width: 18px;
      height: 18px;
      border-radius: 0;

      & + label {
        position: relative;
        padding-left: 28px;

        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          width: 18px;
          height: 18px;
          background-color: fadeout(@gray-2, 70%);
          border: 1px solid @gray-1;
          content: '';
        }
      }

      &:checked {
        & + label {
          &::before {
            color: @color-1;
            font-weight: 600;
            font-size: 14px;
            font-family: 'Font Awesome 5 Pro';
            line-height: 19px;
            text-align: center;
            content: '\f00c';
          }
        }
      }
    }

    & input[type='radio'] {
      position: relative;
      top: 0;
      display: none;
      width: 18px;
      height: 18px;
      border-radius: 0;

      & + label {
        position: relative;
        padding-left: 28px;

        &::before {
          position: absolute;
          top: 2px;
          left: 0;
          width: 18px;
          height: 18px;
          background-color: fadeout(@gray-2, 70%);
          border: 1px solid @gray-1;
          border-radius: 50%;
          content: '';
        }
      }

      &:checked {
        & + label {
          &::before {
            color: @color-1;
            font-weight: 600;
            font-size: 19px;
            font-family: 'Font Awesome 5 Pro';
            line-height: 19px;
            text-align: center;
            content: '\f192';
          }
        }
      }
    }

    & button[type='submit'],
    & input[type='submit'] {
      .sol-btn;

      margin-top: 20px;
      padding-right: 25px;
      padding-left: 25px;
      color: @white;
      background-color: @color-1;
      border: 1px solid @color-1;
      border-radius: 0;

      &:hover {
        color: @white;
        text-decoration: underline;
        background-color: @hover;
        border: 1px solid @hover;
      }

      & > i.fa,
      & > i.fal,
      & > i.far,
      & > i.fas,
      & > i.fab {
        margin-right: 11px;
        margin-left: -15px;
      }
    }

    & .sol-form-divider {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed @form-divider;
    }
  }
}

.sv-form-portlet {
  & input[type='checkbox'] {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
  }

  & input[type='radio'] {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
  }

  & button[type='submit'],
  & input[type='submit'] {
    .sol-btn;

    color: @white;
    background-color: @color-1;
    border: 1px solid @color-1;

    &:hover {
      color: @white;
      text-decoration: underline;
      background-color: @hover;
      border: 1px solid @hover;
    }
  }
}

.sol-form form,
.sol-form-decoration form {
  & .sv-form-singleselectionfield,
  & .sv-form-multipleselectionfield {
    & label {
      display: inline-block;
      width: calc(100% - 33px);
      vertical-align: top;
    }
  }
}
