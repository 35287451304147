.sol-subpage-wrapper,
.sol-startpage-wrapper {
  .sol-clearfix;

  > .sol-left {
    float: left;
    width: 65%;
    margin-top: 35px;

    @media @md {
      float: none;
      width: 100%;
      margin-top: 20px;
    }

    @media @sm {
      margin-top: 0;
    }

    & .sv-image-portlet {
      margin-top: 20px;
    }
  }

  > .sol-right {
    float: right;
    width: 30%;
    margin-top: 0;

    @media @md {
      float: none;
      width: 100%;
    }

    @media @sm {
      display: none;
    }
  }

  & .pagecontent {
    .sol-clearfix;
  }

  & .sv-column-12 {
    & > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        justify-content: flex-start;
      }
    }

    & .pagecontent {
      & > div {
        width: 100%;
      }
    }
  }

  & .sol-blurbs-row {
    margin-right: -28px !important;
    margin-left: -28px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

    @media @md {
      padding: 0;
    }

    @media @sm {
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding-top: 55px;
      border-top: 3px solid @gray-2;
    }

    & .sv-column-12 {
      padding-right: 0 !important;
      padding-left: 0 !important;

      @media @md-strict {
        justify-content: left;
      }

      @media @md {
        margin-bottom: 0;
        padding: 0;
      }
    }

    & .pagecontent *.sv-column-4 {
      max-width: 28%;
      height: auto;
      margin: 0 auto;
      padding-right: 0;
      padding-left: 0;

      @media (max-width: 1240px) {
        max-width: 30%;
        padding: 0 10px;
      }

      @media @md-strict {
        flex: 1 0 auto;
        max-width: 32%;
        margin: 30px 0;
        padding: 0 16px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      @media @sm {
        flex: 1 0 100%;
        max-width: none;
        margin: 10px 0;
        padding: 0;
      }
    }
  }
}

.sol-wide-content-wrapper {
  margin: 20px 0 30px;

  @media @sm {
    margin-top: 0;
  }

  &.sol-wide-content {
    margin-top: 65px;

    @media @md {
      margin-top: 0;
    }
  }
}

.sol-subpage-wrapper .sol-blurbs-row {
  @media @sm {
    padding-top: 0;
    border-top: none;
  }
}

.sol-subpage-wrapper .sv-grid-main.sv-fluid-grid {
  padding: 0;
}
