.sol-menu {
  @item-height: 55px;
  @border-color: @gray-1;
  @current-color: @color-1;
  @expanded-color: @black;
  @level-2-bg: fadeout(@gray-2, 60%);
  @level-3-bg: fadeout(@gray-2, 40%);
  @level-4-bg: fadeout(@gray-2, 20%);
  @level-5-bg: fadeout(@gray-2, 10%);

  background-color: @white;

  &__root-name {
    display: block;
    background-color: transparent;
    padding: 15px;
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 400;
    text-decoration: none;
    color: @black;
    border-bottom: 3px solid @color-1;
    margin-top: 0;
    margin-bottom: 1px;
    overflow: hidden;

    & a {
      text-decoration: none;
    }
  }

  ul {
    .no-list-style;
  }

  &-item {
    position: relative;
    font-size: 16px;
    line-height: 1;

    & a {
      .outline-focus;
      line-height: normal;
      text-align: left;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.5px;
      color: @black;
      padding: 0 @item-height 0 20px;
      text-decoration: none;
      border-bottom: 1px solid @border-color;
      height: @item-height;
      .transition(color);

      &:hover {
        text-decoration: underline;
      }
    }

    &[data-expanded='true'] {
      & > a {
        color: @expanded-color;
        font-weight: 300;
        letter-spacing: 0.5px;
      }
    }

    &[data-expanded='true'][data-menu-level="1"] {
      & > a {
        background-color: fadeout(@gray-2, 80%);
        font-weight: 600;
        letter-spacing: 0.5px;
        border-bottom: 1px solid @gray-2;
      }
    }

    &[data-is-current='true'] {
      & > a {
        font-weight: 600;

        &::before {
          content: "";
          background-color: @color-1;
          bottom: -1px;
          left: 0;
          position: absolute;
          top: -1px;
          transition: background-color .15s ease-in-out;
          width: 6px;
        }
      }
    }

    &[data-menu-level='2'] {
      background-color: @level-2-bg;

      & a {
        padding-left: 31px;
        font-size: 16px;
        border-bottom-color: @white;
      }
    }

    &[data-menu-level='3'] {
      background-color: @level-3-bg;

      & a {
        padding-left: 41px;
      }
    }

    &[data-menu-level='4'] {
      background-color: @level-4-bg;

      & a {
        padding-left: 51px;
      }
    }

    &[data-menu-level='5'] {
      background-color: @level-5-bg;

      & a {
        padding-left: 57px;
      }
    }

    &[data-expanded="true"][data-has-children="true"] {
      & > a {
        font-weight: 600;
      }
    }

    &__button {
      .no-appearance;
      .outline-focus;

      position: absolute;
      right: 0;
      top: 0;
      height: @item-height;
      width: @item-height;
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: @level-2-bg;
      }

      & i.fa,
      & i.far,
      & i.fal,
      & i.fab,
      & i.fas {
        width: 30px;
        height: 30px;
        border: 1px solid @gray-2;
        border-radius: 50%;
        padding-top: 6.5px;
        padding-left: 1px;
        text-align: center;
        font-size: 17px;
        position: relative;
        top: -1px;
        transition: all 200ms ease 20ms;
      }
    }
  }
}

.sol-mobile-header {
  & .sol-menu {
    position: absolute;
    top: 161px;
    width: 100%;

    @media @sm {
      top: 55px;
    }
  }
}
