.sol-collapsible {
  margin: 20px 0;

  h2 {
    margin-bottom: 0;
  }
  &__header {
    .no-appearance;
    .focus-outline;
    letter-spacing: 0.4px;
    position: relative;
    display: block;
    width: 100%;
    min-height: 42px;
    line-height: 25px;
    padding: 10px 50px 10px 10px;
    background-color: @box-decoration-header-bg;
    border: 1px solid @gray-1;
    border-radius: 0;
    color: @box-decoration-header-text;
    text-align: left;
    text-decoration: none;
    font-weight: 400;
    font-size: 19px;

    @media @sm {
      font-size: 17px;
    }

    margin: 0;
    cursor: pointer;
    &:hover {
      background-color: @color-2;
    }
    &__icon {
      display: block;
      background-color: @color-1;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -17px;

      &::after,
      &::before {
        content: "";
        display: block;
        background-color: @white;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 1.5px;
        margin-left: -10px;
        margin-top: -1px;
        .transition(all);
      }
      &::after {
        .rotate(90deg);
      }
    }
    &--active {
      background-color: @color-1;
      color: @white;
      border: 1px solid @color-1;
      & .sol-collapsible__header__icon {
        background-color: @white;
        &::after {
          background-color: @color-1;
          .rotate(0deg);
        }
      }
      &:hover {
        background-color: @color-1;
      }
    }
  }
  & .sol-collapsible__content,
  & .sv-decoration-content {
    display: none;
    background-color: @box-decoration-bg;
    border: 1px solid @gray-2;
    border-bottom: 3px solid @color-1;
    //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
    padding: 20px;
    & > *:last-child {
      margin-bottom: 0;
    }
    & p:last-of-type {
      margin: 0;
    }
    .sv-editing-mode & {
      display: block;
    }
  }
}
