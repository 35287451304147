.top-icon() {
  display: block;
  width: 100%;
  height: 28px;
  margin-bottom: 6px;
  font-size: 28px;

  @media @md {
    margin-bottom: 0;
  }
}

.top-button() {
  .outline-focus;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 5px;
  color: @white;
  font-weight: 900;
  font-size: 11px !important;
  font-family: @main-font;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &--active {
    color: @color-1;
  }

  @media @md {
    width: 50px;
    min-width: 0;
    height: 50px;
    min-height: 0;
    font-size: 0 !important;
  }

  @media (max-width: 380px) {
    width: 40px;
  }
}

.sol-top-action-button {
  .no-appearance;
  .top-button;

  & i.fa,
  & i.fal,
  & i.far,
  & i.fas,
  & i.fab {
    .top-icon();
  }
}
