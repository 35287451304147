.sol-error-page {
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  background-color: @error-bg;

  &-text-big {
    font-weight: 700;
    font-size: 255px;
    line-height: 220px;

    @media @sm {
      font-size: 150px;
    }
  }

  &-text-small {
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 34px;
    line-height: 40px;
  }

  & .sol-btn {
    margin-top: 20px;

    &:first-child {
      margin-right: 10px;
    }
  }
}
