.sol-action-link {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;

  a {
    position: relative;
    display: block;
    height: auto;
    min-height: 42px;
    padding: 5px 50px 5px 10px;
    color: @box-decoration-header-text;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    letter-spacing: 0.4px;
    text-decoration: none;
    background-color: @box-decoration-header-bg;
    border: 1px solid @gray-1;
    border-radius: 0;

    @media @sm {
      font-size: 17px;
    }

    &::after {
      position: absolute;
      top: 50%;
      right: 10px;
      width: 30px;
      height: 27px;
      padding-top: 7px;
      padding-left: 4px;
      color: @white;
      font-weight: 100;
      font-size: 19px;
      font-family: 'Font Awesome 5 Pro';
      line-height: normal;
      text-align: center;
      background-color: @color-5;
      border-radius: 50%;
      transform: translateY(-50%);
      content: '\f054';
    }

    &:hover {
      color: @white;
      text-decoration: underline;
      background-color: @color-5;
      border: 1px solid @color-5;

      &::after {
        color: @color-5;
        background-color: @white;
      }
    }

    i.fa-external-link {
      display: inline;

      &::before {
        position: relative;
        top: -3px;
        margin-left: 8px;
        font-size: 14px;
      }
    }
  }
}
