.clearfix() {
  &::after,
  &::before {
    display: table;
    content: '';
  }

  &::after {
    clear: both;
  }
}

.no-list-style() {
  margin: 0;
  padding: 0;
  list-style: none;
}

.placeholder(@rules) {
  &::input-placeholder {
    @rules();
  }

  &:placeholder {
    @rules();
  }

  &::placeholder {
    @rules();
  }

  &:input-placeholder {
    @rules();
  }
}

.no-outline-focus() {
  &:focus {
    outline: none;
    box-shadow: none;
    box-shadow: none;
    appearance: none;
  }
}

.outline-focus() {
  &:focus,
  [data-whatinput='keyboard'] &:focus {
    z-index: 10;
    outline: 2px solid @focus-border;
    outline-offset: -2px;
  }

  [data-whatinput='initial'] &:focus,
  [data-whatinput='mouse'] &:focus,
  [data-whatinput='touch'] &:focus {
    z-index: inherit;
    outline: none;
    box-shadow: none;
    box-shadow: none;
    appearance: none;
  }
}

.no-appearance() {
  background: none;
  border: none;
  appearance: none;
  appearance: none;
  appearance: none;
}

.icon(@icon; @size:inherit; @display:inline-block; @position: none) {
  .fa;

  display: @display;
  font-size: @size;
  .position(@position);
  .position(@position) when (@position =before) {
    &::before {
      content: @icon;
    }
  }
  .position(@position) when (@position =after) {
    &::after {
      content: @icon;
    }
  }
  .position(@position) when (@position =none) {
    content: @icon;
  }
}

.transition( @property; @duration:200ms; @style: ease) {
  transition-timing-function: @style;
  transition-timing-function: @style;
  transition-duration: @duration;
  transition-duration: @duration;
  transition-property: @property;
  transition-property: @property;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}
.focus-outline() {
  &:focus,
  [data-whatinput='keyboard'] &:focus {
    z-index: 10;
    outline: 2px solid @focus-border;
    outline-offset: 0;
    box-shadow: 0 0 0 4px @white;
  }

  [data-whatinput='initial'] &:focus,
  [data-whatinput='mouse'] &:focus,
  [data-whatinput='touch'] &:focus {
    outline: none;
    box-shadow: none;
    box-shadow: none;
    appearance: none;
  }
}
.rotate(@deg) {
  transform: rotate(@deg);
  transform: rotate(@deg);
  transform: rotate(@deg);
}
