/**
 * Soleil AB
 * Author: Henrik Nordlund
 * Date: Thu Apr 03 2025 13:31:14 GMT+0200 (centraleuropeisk sommartid)
 * Build script version: 2.2.4
 */
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.eot');
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.woff') format('woff'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-light-300.svg#fontawesome') format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.eot');
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.eot');
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.woff') format('woff'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-abacus:before {
  content: "\f640";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acorn:before {
  content: "\f6ae";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-conditioner:before {
  content: "\f8f4";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-airbnb:before {
  content: "\f834";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-alarm-exclamation:before {
  content: "\f843";
}
.fa-alarm-plus:before {
  content: "\f844";
}
.fa-alarm-snooze:before {
  content: "\f845";
}
.fa-album:before {
  content: "\f89f";
}
.fa-album-collection:before {
  content: "\f8a0";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-alicorn:before {
  content: "\f6b0";
}
.fa-alien:before {
  content: "\f8f5";
}
.fa-alien-monster:before {
  content: "\f8f6";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-align-slash:before {
  content: "\f846";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-amp-guitar:before {
  content: "\f8a1";
}
.fa-analytics:before {
  content: "\f643";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angel:before {
  content: "\f779";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-crate:before {
  content: "\f6b1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-atom-alt:before {
  content: "\f5d3";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-axe:before {
  content: "\f6b2";
}
.fa-axe-battle:before {
  content: "\f6b3";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-backpack:before {
  content: "\f5d4";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-bacteria:before {
  content: "\e059";
}
.fa-bacterium:before {
  content: "\e05a";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-badge-dollar:before {
  content: "\f645";
}
.fa-badge-percent:before {
  content: "\f646";
}
.fa-badge-sheriff:before {
  content: "\f8a2";
}
.fa-badger-honey:before {
  content: "\f6b4";
}
.fa-bags-shopping:before {
  content: "\f847";
}
.fa-bahai:before {
  content: "\f666";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ball-pile:before {
  content: "\f77e";
}
.fa-ballot:before {
  content: "\f732";
}
.fa-ballot-check:before {
  content: "\f733";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-banjo:before {
  content: "\f8a3";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bat:before {
  content: "\f6b5";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battle-net:before {
  content: "\f835";
}
.fa-bed:before {
  content: "\f236";
}
.fa-bed-alt:before {
  content: "\f8f7";
}
.fa-bed-bunk:before {
  content: "\f8f8";
}
.fa-bed-empty:before {
  content: "\f8f9";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-exclamation:before {
  content: "\f848";
}
.fa-bell-on:before {
  content: "\f8fa";
}
.fa-bell-plus:before {
  content: "\f849";
}
.fa-bell-school:before {
  content: "\f5d5";
}
.fa-bell-school-slash:before {
  content: "\f5d6";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bells:before {
  content: "\f77f";
}
.fa-betamax:before {
  content: "\f8a4";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-biking:before {
  content: "\f84a";
}
.fa-biking-mountain:before {
  content: "\f84b";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blinds:before {
  content: "\f8fb";
}
.fa-blinds-open:before {
  content: "\f8fc";
}
.fa-blinds-raised:before {
  content: "\f8fd";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bone-break:before {
  content: "\f5d8";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-alt:before {
  content: "\f5d9";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-book-spells:before {
  content: "\f6b8";
}
.fa-book-user:before {
  content: "\f7e7";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-books:before {
  content: "\f5db";
}
.fa-books-medical:before {
  content: "\f7e8";
}
.fa-boombox:before {
  content: "\f8a5";
}
.fa-boot:before {
  content: "\f782";
}
.fa-booth-curtain:before {
  content: "\f734";
}
.fa-bootstrap:before {
  content: "\f836";
}
.fa-border-all:before {
  content: "\f84c";
}
.fa-border-bottom:before {
  content: "\f84d";
}
.fa-border-center-h:before {
  content: "\f89c";
}
.fa-border-center-v:before {
  content: "\f89d";
}
.fa-border-inner:before {
  content: "\f84e";
}
.fa-border-left:before {
  content: "\f84f";
}
.fa-border-none:before {
  content: "\f850";
}
.fa-border-outer:before {
  content: "\f851";
}
.fa-border-right:before {
  content: "\f852";
}
.fa-border-style:before {
  content: "\f853";
}
.fa-border-style-alt:before {
  content: "\f854";
}
.fa-border-top:before {
  content: "\f855";
}
.fa-bow-arrow:before {
  content: "\f6b9";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-ballot:before {
  content: "\f735";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-tissue:before {
  content: "\e05b";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f4a1";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-brackets:before {
  content: "\f7e9";
}
.fa-brackets-curly:before {
  content: "\f7ea";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-bread-loaf:before {
  content: "\f7eb";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-bring-forward:before {
  content: "\f856";
}
.fa-bring-front:before {
  content: "\f857";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-buffer:before {
  content: "\f837";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-bullseye-arrow:before {
  content: "\f648";
}
.fa-bullseye-pointer:before {
  content: "\f649";
}
.fa-burger-soda:before {
  content: "\f858";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-burrito:before {
  content: "\f7ed";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-bus-school:before {
  content: "\f5dd";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buy-n-large:before {
  content: "\f8a6";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-cabinet-filing:before {
  content: "\f64b";
}
.fa-cactus:before {
  content: "\f8a7";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calculator-alt:before {
  content: "\f64c";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-star:before {
  content: "\f736";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-camcorder:before {
  content: "\f8a8";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f332";
}
.fa-camera-home:before {
  content: "\f8fe";
}
.fa-camera-movie:before {
  content: "\f8a9";
}
.fa-camera-polaroid:before {
  content: "\f8aa";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-campfire:before {
  content: "\f6ba";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candle-holder:before {
  content: "\f6bc";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-candy-corn:before {
  content: "\f6bd";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-building:before {
  content: "\f859";
}
.fa-car-bump:before {
  content: "\f5e0";
}
.fa-car-bus:before {
  content: "\f85a";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-garage:before {
  content: "\f5e2";
}
.fa-car-mechanic:before {
  content: "\f5e3";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-car-tilt:before {
  content: "\f5e5";
}
.fa-car-wash:before {
  content: "\f5e6";
}
.fa-caravan:before {
  content: "\f8ff";
}
.fa-caravan-alt:before {
  content: "\e000";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cars:before {
  content: "\f85b";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cassette-tape:before {
  content: "\f8ab";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cat-space:before {
  content: "\e001";
}
.fa-cauldron:before {
  content: "\f6bf";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-cctv:before {
  content: "\f8ac";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chair-office:before {
  content: "\f6c1";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-line-down:before {
  content: "\f64d";
}
.fa-chart-network:before {
  content: "\f78a";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-chart-pie-alt:before {
  content: "\f64e";
}
.fa-chart-scatter:before {
  content: "\f7ee";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-cheese-swiss:before {
  content: "\f7f0";
}
.fa-cheeseburger:before {
  content: "\f7f1";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chimney:before {
  content: "\f78b";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-chromecast:before {
  content: "\f838";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-clarinet:before {
  content: "\f8ad";
}
.fa-claw-marks:before {
  content: "\f6c2";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clipboard-list-check:before {
  content: "\f737";
}
.fa-clipboard-prescription:before {
  content: "\f5e8";
}
.fa-clipboard-user:before {
  content: "\f7f3";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-drizzle:before {
  content: "\f738";
}
.fa-cloud-hail:before {
  content: "\f739";
}
.fa-cloud-hail-mixed:before {
  content: "\f73a";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-music:before {
  content: "\f8ae";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-rainbow:before {
  content: "\f73e";
}
.fa-cloud-showers:before {
  content: "\f73f";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sleet:before {
  content: "\f741";
}
.fa-cloud-snow:before {
  content: "\f742";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-cloudflare:before {
  content: "\e07d";
}
.fa-clouds:before {
  content: "\f744";
}
.fa-clouds-moon:before {
  content: "\f745";
}
.fa-clouds-sun:before {
  content: "\f746";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-coffee-pot:before {
  content: "\e002";
}
.fa-coffee-togo:before {
  content: "\f6c5";
}
.fa-coffin:before {
  content: "\f6c6";
}
.fa-coffin-cross:before {
  content: "\e051";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coin:before {
  content: "\f85c";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comet:before {
  content: "\e003";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-comment-alt-dollar:before {
  content: "\f650";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-medical:before {
  content: "\f7f4";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-music:before {
  content: "\f8af";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-music:before {
  content: "\f8b0";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-comments-alt-dollar:before {
  content: "\f652";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compass-slash:before {
  content: "\f5e9";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-computer-classic:before {
  content: "\f8b1";
}
.fa-computer-speaker:before {
  content: "\f8b2";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-construction:before {
  content: "\f85d";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-corn:before {
  content: "\f6c7";
}
.fa-cotton-bureau:before {
  content: "\f89e";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cow:before {
  content: "\f6c8";
}
.fa-cowbell:before {
  content: "\f8b3";
}
.fa-cowbell-more:before {
  content: "\f8b4";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-croissant:before {
  content: "\f7f6";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-crutches:before {
  content: "\f7f8";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dagger:before {
  content: "\f6cb";
}
.fa-dailymotion:before {
  content: "\e052";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-debug:before {
  content: "\f7f9";
}
.fa-deer:before {
  content: "\f78e";
}
.fa-deer-rudolph:before {
  content: "\f78f";
}
.fa-deezer:before {
  content: "\e077";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f390";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dewpoint:before {
  content: "\f748";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d10:before {
  content: "\f6cd";
}
.fa-dice-d12:before {
  content: "\f6ce";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d4:before {
  content: "\f6d0";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-d8:before {
  content: "\f6d2";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digging:before {
  content: "\f85e";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-diploma:before {
  content: "\f5ea";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-disc-drive:before {
  content: "\f8b5";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-disease:before {
  content: "\f7fa";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-do-not-enter:before {
  content: "\f5ec";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dog-leashed:before {
  content: "\f6d4";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-circle:before {
  content: "\f5ed";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-draw-square:before {
  content: "\f5ef";
}
.fa-dreidel:before {
  content: "\f792";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-drone:before {
  content: "\f85f";
}
.fa-drone-alt:before {
  content: "\f860";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick:before {
  content: "\f6d6";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-dryer:before {
  content: "\f861";
}
.fa-dryer-alt:before {
  content: "\f862";
}
.fa-duck:before {
  content: "\f6d8";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-ear:before {
  content: "\f5f0";
}
.fa-ear-muffs:before {
  content: "\f795";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-eclipse:before {
  content: "\f749";
}
.fa-eclipse-alt:before {
  content: "\f74a";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edge-legacy:before {
  content: "\e078";
}
.fa-edit:before {
  content: "\f044";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-egg-fried:before {
  content: "\f7fc";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-elephant:before {
  content: "\f6da";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-empty-set:before {
  content: "\f656";
}
.fa-engine-warning:before {
  content: "\f5f2";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-dollar:before {
  content: "\f657";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-evernote:before {
  content: "\f839";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-evil:before {
  content: "\f6db";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fan:before {
  content: "\f863";
}
.fa-fan-table:before {
  content: "\e004";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-farm:before {
  content: "\f864";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-faucet:before {
  content: "\e005";
}
.fa-faucet-drip:before {
  content: "\e006";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-female:before {
  content: "\f182";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-certificate:before {
  content: "\f5f3";
}
.fa-file-chart-line:before {
  content: "\f659";
}
.fa-file-chart-pie:before {
  content: "\f65a";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-music:before {
  content: "\f8b6";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-search:before {
  content: "\f865";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-spreadsheet:before {
  content: "\f65b";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-user:before {
  content: "\f65c";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-files-medical:before {
  content: "\f7fd";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-film-canister:before {
  content: "\f8b7";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-fire-smoke:before {
  content: "\f74b";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-firefox-browser:before {
  content: "\e007";
}
.fa-fireplace:before {
  content: "\f79a";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fish-cooked:before {
  content: "\f7fe";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-alt:before {
  content: "\f74c";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flame:before {
  content: "\f6df";
}
.fa-flashlight:before {
  content: "\f8b8";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flask-poison:before {
  content: "\f6e0";
}
.fa-flask-potion:before {
  content: "\f6e1";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flower:before {
  content: "\f7ff";
}
.fa-flower-daffodil:before {
  content: "\f800";
}
.fa-flower-tulip:before {
  content: "\f801";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-flute:before {
  content: "\f8b9";
}
.fa-flux-capacitor:before {
  content: "\f8ba";
}
.fa-fly:before {
  content: "\f417";
}
.fa-fog:before {
  content: "\f74e";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-download:before {
  content: "\e053";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-folder-times:before {
  content: "\f65f";
}
.fa-folder-tree:before {
  content: "\f802";
}
.fa-folder-upload:before {
  content: "\e054";
}
.fa-folders:before {
  content: "\f660";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-font-case:before {
  content: "\f866";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-french-fries:before {
  content: "\f803";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frosty-head:before {
  content: "\f79b";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-function:before {
  content: "\f661";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-galaxy:before {
  content: "\e008";
}
.fa-game-board:before {
  content: "\f867";
}
.fa-game-board-alt:before {
  content: "\f868";
}
.fa-game-console-handheld:before {
  content: "\f8bb";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gamepad-alt:before {
  content: "\f8bc";
}
.fa-garage:before {
  content: "\e009";
}
.fa-garage-car:before {
  content: "\e00a";
}
.fa-garage-open:before {
  content: "\e00b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gas-pump-slash:before {
  content: "\f5f4";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gift-card:before {
  content: "\f663";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-gingerbread-man:before {
  content: "\f79d";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-alt:before {
  content: "\f841";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass:before {
  content: "\f804";
}
.fa-glass-champagne:before {
  content: "\f79e";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-glass-citrus:before {
  content: "\f869";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glasses-alt:before {
  content: "\f5f5";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-globe-snow:before {
  content: "\f7a3";
}
.fa-globe-stand:before {
  content: "\f5f6";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-pay:before {
  content: "\e079";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gramophone:before {
  content: "\f8bd";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guilded:before {
  content: "\e07e";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-guitar-electric:before {
  content: "\f8be";
}
.fa-guitars:before {
  content: "\f8bf";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-h4:before {
  content: "\f86a";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hammer-war:before {
  content: "\f6e4";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-magic:before {
  content: "\f6e5";
}
.fa-hand-holding-medical:before {
  content: "\e05c";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-sparkles:before {
  content: "\e05d";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-hands-wash:before {
  content: "\e05e";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-handshake-alt-slash:before {
  content: "\e05f";
}
.fa-handshake-slash:before {
  content: "\e060";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-chef:before {
  content: "\f86b";
}
.fa-hat-cowboy:before {
  content: "\f8c0";
}
.fa-hat-cowboy-side:before {
  content: "\f8c1";
}
.fa-hat-santa:before {
  content: "\f7a7";
}
.fa-hat-winter:before {
  content: "\f7a8";
}
.fa-hat-witch:before {
  content: "\f6e7";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-head-side:before {
  content: "\f6e9";
}
.fa-head-side-brain:before {
  content: "\f808";
}
.fa-head-side-cough:before {
  content: "\e061";
}
.fa-head-side-cough-slash:before {
  content: "\e062";
}
.fa-head-side-headphones:before {
  content: "\f8c2";
}
.fa-head-side-mask:before {
  content: "\e063";
}
.fa-head-side-medical:before {
  content: "\f809";
}
.fa-head-side-virus:before {
  content: "\e064";
}
.fa-head-vr:before {
  content: "\f6ea";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-heart-rate:before {
  content: "\f5f8";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-heat:before {
  content: "\e00c";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-helmet-battle:before {
  content: "\f6eb";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hive:before {
  content: "\e07f";
}
.fa-hockey-mask:before {
  content: "\f6ee";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-alt:before {
  content: "\f80a";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-home-lg:before {
  content: "\f80b";
}
.fa-home-lg-alt:before {
  content: "\f80c";
}
.fa-hood-cloak:before {
  content: "\f6ef";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-horizontal-rule:before {
  content: "\f86c";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-horse-saddle:before {
  content: "\f8c3";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hospital-user:before {
  content: "\f80d";
}
.fa-hospitals:before {
  content: "\f80e";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-house:before {
  content: "\e00d";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-house-day:before {
  content: "\e00e";
}
.fa-house-flood:before {
  content: "\f74f";
}
.fa-house-leave:before {
  content: "\e00f";
}
.fa-house-night:before {
  content: "\e010";
}
.fa-house-return:before {
  content: "\e011";
}
.fa-house-signal:before {
  content: "\e012";
}
.fa-house-user:before {
  content: "\e065";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-humidity:before {
  content: "\f750";
}
.fa-hurricane:before {
  content: "\f751";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-ice-skate:before {
  content: "\f7ac";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-icons:before {
  content: "\f86d";
}
.fa-icons-alt:before {
  content: "\f86e";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-ideal:before {
  content: "\e013";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-image-polaroid:before {
  content: "\f8c4";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-inhaler:before {
  content: "\f5f9";
}
.fa-innosoft:before {
  content: "\e080";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-instagram-square:before {
  content: "\e055";
}
.fa-instalod:before {
  content: "\e081";
}
.fa-integral:before {
  content: "\f667";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-intersection:before {
  content: "\f668";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-island-tropical:before {
  content: "\f811";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itch-io:before {
  content: "\f83a";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-joystick:before {
  content: "\f8c5";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-jug:before {
  content: "\f8c6";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-kazoo:before {
  content: "\f8c7";
}
.fa-kerning:before {
  content: "\f86f";
}
.fa-key:before {
  content: "\f084";
}
.fa-key-skeleton:before {
  content: "\f6f3";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-keynote:before {
  content: "\f66c";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kidneys:before {
  content: "\f5fb";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kite:before {
  content: "\f6f4";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-knife-kitchen:before {
  content: "\f6f5";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-lambda:before {
  content: "\f66e";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-lamp-desk:before {
  content: "\e014";
}
.fa-lamp-floor:before {
  content: "\e015";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-landmark-alt:before {
  content: "\f752";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-house:before {
  content: "\e066";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lasso:before {
  content: "\f8c8";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-layer-minus:before {
  content: "\f5fe";
}
.fa-layer-plus:before {
  content: "\f5ff";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leaf-maple:before {
  content: "\f6f6";
}
.fa-leaf-oak:before {
  content: "\f6f7";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-light-ceiling:before {
  content: "\e016";
}
.fa-light-switch:before {
  content: "\e017";
}
.fa-light-switch-off:before {
  content: "\e018";
}
.fa-light-switch-on:before {
  content: "\e019";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-lightbulb-dollar:before {
  content: "\f670";
}
.fa-lightbulb-exclamation:before {
  content: "\f671";
}
.fa-lightbulb-on:before {
  content: "\f672";
}
.fa-lightbulb-slash:before {
  content: "\f673";
}
.fa-lights-holiday:before {
  content: "\f7b2";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-line-columns:before {
  content: "\f870";
}
.fa-line-height:before {
  content: "\f871";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lips:before {
  content: "\f600";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-music:before {
  content: "\f8c9";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location:before {
  content: "\f601";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-location-circle:before {
  content: "\f602";
}
.fa-location-slash:before {
  content: "\f603";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lungs-virus:before {
  content: "\e067";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-mace:before {
  content: "\f6f8";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailbox:before {
  content: "\f813";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-mandolin:before {
  content: "\f6f9";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-marker-alt-slash:before {
  content: "\f605";
}
.fa-map-marker-check:before {
  content: "\f606";
}
.fa-map-marker-edit:before {
  content: "\f607";
}
.fa-map-marker-exclamation:before {
  content: "\f608";
}
.fa-map-marker-minus:before {
  content: "\f609";
}
.fa-map-marker-plus:before {
  content: "\f60a";
}
.fa-map-marker-question:before {
  content: "\f60b";
}
.fa-map-marker-slash:before {
  content: "\f60c";
}
.fa-map-marker-smile:before {
  content: "\f60d";
}
.fa-map-marker-times:before {
  content: "\f60e";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-mdb:before {
  content: "\f8ca";
}
.fa-meat:before {
  content: "\f814";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaphone:before {
  content: "\f675";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microblog:before {
  content: "\e01a";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microphone-stand:before {
  content: "\f8cb";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-microwave:before {
  content: "\e01b";
}
.fa-mind-share:before {
  content: "\f677";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mistletoe:before {
  content: "\f7b4";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mixer:before {
  content: "\e056";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-money-check-edit:before {
  content: "\f872";
}
.fa-money-check-edit-alt:before {
  content: "\f873";
}
.fa-monitor-heart-rate:before {
  content: "\f611";
}
.fa-monkey:before {
  content: "\f6fb";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-moon-cloud:before {
  content: "\f754";
}
.fa-moon-stars:before {
  content: "\f755";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mountains:before {
  content: "\f6fd";
}
.fa-mouse:before {
  content: "\f8cc";
}
.fa-mouse-alt:before {
  content: "\f8cd";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-mp3-player:before {
  content: "\f8ce";
}
.fa-mug:before {
  content: "\f874";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-mug-marshmallows:before {
  content: "\f7b7";
}
.fa-mug-tea:before {
  content: "\f875";
}
.fa-music:before {
  content: "\f001";
}
.fa-music-alt:before {
  content: "\f8cf";
}
.fa-music-alt-slash:before {
  content: "\f8d0";
}
.fa-music-slash:before {
  content: "\f8d1";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-narwhal:before {
  content: "\f6fe";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-octopus-deploy:before {
  content: "\e082";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-oil-temp:before {
  content: "\f614";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-omega:before {
  content: "\f67a";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-orcid:before {
  content: "\f8d2";
}
.fa-ornament:before {
  content: "\f7b8";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-outlet:before {
  content: "\e01c";
}
.fa-oven:before {
  content: "\e01d";
}
.fa-overline:before {
  content: "\f876";
}
.fa-page-break:before {
  content: "\f877";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-brush-alt:before {
  content: "\f5a9";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-paragraph-rtl:before {
  content: "\f878";
}
.fa-parking:before {
  content: "\f540";
}
.fa-parking-circle:before {
  content: "\f615";
}
.fa-parking-circle-slash:before {
  content: "\f616";
}
.fa-parking-slash:before {
  content: "\f617";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paw-alt:before {
  content: "\f701";
}
.fa-paw-claws:before {
  content: "\f702";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pegasus:before {
  content: "\f703";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-paintbrush:before {
  content: "\f618";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-arrows:before {
  content: "\e068";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-perbyte:before {
  content: "\e083";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-person-sign:before {
  content: "\f757";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-alt:before {
  content: "\f879";
}
.fa-phone-laptop:before {
  content: "\f87a";
}
.fa-phone-office:before {
  content: "\f67d";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-rotary:before {
  content: "\f8d3";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-square-alt:before {
  content: "\f87b";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-photo-video:before {
  content: "\f87c";
}
.fa-php:before {
  content: "\f457";
}
.fa-pi:before {
  content: "\f67e";
}
.fa-piano:before {
  content: "\f8d4";
}
.fa-piano-keyboard:before {
  content: "\f8d5";
}
.fa-pie:before {
  content: "\f705";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pied-piper-square:before {
  content: "\e01e";
}
.fa-pig:before {
  content: "\f706";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza:before {
  content: "\f817";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-alt:before {
  content: "\f3de";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-plane-slash:before {
  content: "\e069";
}
.fa-planet-moon:before {
  content: "\e01f";
}
.fa-planet-ringed:before {
  content: "\e020";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-podium:before {
  content: "\f680";
}
.fa-podium-star:before {
  content: "\f758";
}
.fa-police-box:before {
  content: "\e021";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poll-people:before {
  content: "\f759";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-popcorn:before {
  content: "\f819";
}
.fa-portal-enter:before {
  content: "\e022";
}
.fa-portal-exit:before {
  content: "\e023";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-presentation:before {
  content: "\f685";
}
.fa-print:before {
  content: "\f02f";
}
.fa-print-search:before {
  content: "\f81a";
}
.fa-print-slash:before {
  content: "\f686";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-projector:before {
  content: "\f8d6";
}
.fa-pump-medical:before {
  content: "\e06a";
}
.fa-pump-soap:before {
  content: "\e06b";
}
.fa-pumpkin:before {
  content: "\f707";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-rabbit:before {
  content: "\f708";
}
.fa-rabbit-fast:before {
  content: "\f709";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-radar:before {
  content: "\e024";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-radio:before {
  content: "\f8d7";
}
.fa-radio-alt:before {
  content: "\f8d8";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-raindrops:before {
  content: "\f75c";
}
.fa-ram:before {
  content: "\f70a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-random:before {
  content: "\f074";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-raygun:before {
  content: "\e025";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-record-vinyl:before {
  content: "\f8d9";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-refrigerator:before {
  content: "\e026";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-remove-format:before {
  content: "\f87d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-rings-wedding:before {
  content: "\f81b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocket-launch:before {
  content: "\e027";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-route-highway:before {
  content: "\f61a";
}
.fa-route-interstate:before {
  content: "\f61b";
}
.fa-router:before {
  content: "\f8da";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-triangle:before {
  content: "\f61c";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-running:before {
  content: "\f70c";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-rust:before {
  content: "\e07a";
}
.fa-rv:before {
  content: "\f7be";
}
.fa-sack:before {
  content: "\f81c";
}
.fa-sack-dollar:before {
  content: "\f81d";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-salad:before {
  content: "\f81e";
}
.fa-salesforce:before {
  content: "\f83b";
}
.fa-sandwich:before {
  content: "\f81f";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-sausage:before {
  content: "\f820";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-sax-hot:before {
  content: "\f8db";
}
.fa-saxophone:before {
  content: "\f8dc";
}
.fa-scalpel:before {
  content: "\f61d";
}
.fa-scalpel-path:before {
  content: "\f61e";
}
.fa-scanner:before {
  content: "\f488";
}
.fa-scanner-image:before {
  content: "\f8f3";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-scarecrow:before {
  content: "\f70d";
}
.fa-scarf:before {
  content: "\f7c1";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-scroll-old:before {
  content: "\f70f";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-scythe:before {
  content: "\f710";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-send-back:before {
  content: "\f87e";
}
.fa-send-backward:before {
  content: "\f87f";
}
.fa-sensor:before {
  content: "\e028";
}
.fa-sensor-alert:before {
  content: "\e029";
}
.fa-sensor-fire:before {
  content: "\e02a";
}
.fa-sensor-on:before {
  content: "\e02b";
}
.fa-sensor-smoke:before {
  content: "\e02c";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-sheep:before {
  content: "\f711";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-shield-cross:before {
  content: "\f712";
}
.fa-shield-virus:before {
  content: "\e06c";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shish-kebab:before {
  content: "\f821";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopify:before {
  content: "\e057";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shovel:before {
  content: "\f713";
}
.fa-shovel-snow:before {
  content: "\f7c3";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shredder:before {
  content: "\f68a";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sickle:before {
  content: "\f822";
}
.fa-sigma:before {
  content: "\f68b";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signal-1:before {
  content: "\f68c";
}
.fa-signal-2:before {
  content: "\f68d";
}
.fa-signal-3:before {
  content: "\f68e";
}
.fa-signal-4:before {
  content: "\f68f";
}
.fa-signal-alt:before {
  content: "\f690";
}
.fa-signal-alt-1:before {
  content: "\f691";
}
.fa-signal-alt-2:before {
  content: "\f692";
}
.fa-signal-alt-3:before {
  content: "\f693";
}
.fa-signal-alt-slash:before {
  content: "\f694";
}
.fa-signal-slash:before {
  content: "\f695";
}
.fa-signal-stream:before {
  content: "\f8dd";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sink:before {
  content: "\e06d";
}
.fa-siren:before {
  content: "\e02d";
}
.fa-siren-on:before {
  content: "\e02e";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-skeleton:before {
  content: "\f620";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-ski-jump:before {
  content: "\f7c7";
}
.fa-ski-lift:before {
  content: "\f7c8";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-cow:before {
  content: "\f8de";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sledding:before {
  content: "\f7cb";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-plus:before {
  content: "\f5b9";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoke:before {
  content: "\f760";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-snake:before {
  content: "\f716";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snooze:before {
  content: "\f880";
}
.fa-snow-blowing:before {
  content: "\f761";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowflakes:before {
  content: "\f7cf";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowmobile:before {
  content: "\f7d1";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-soap:before {
  content: "\e06e";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-solar-system:before {
  content: "\e02f";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-down-alt:before {
  content: "\f881";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-alpha-up-alt:before {
  content: "\f882";
}
.fa-sort-alt:before {
  content: "\f883";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-down-alt:before {
  content: "\f884";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-amount-up-alt:before {
  content: "\f885";
}
.fa-sort-circle:before {
  content: "\e030";
}
.fa-sort-circle-down:before {
  content: "\e031";
}
.fa-sort-circle-up:before {
  content: "\e032";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-down-alt:before {
  content: "\f886";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-numeric-up-alt:before {
  content: "\f887";
}
.fa-sort-shapes-down:before {
  content: "\f888";
}
.fa-sort-shapes-down-alt:before {
  content: "\f889";
}
.fa-sort-shapes-up:before {
  content: "\f88a";
}
.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}
.fa-sort-size-down:before {
  content: "\f88c";
}
.fa-sort-size-down-alt:before {
  content: "\f88d";
}
.fa-sort-size-up:before {
  content: "\f88e";
}
.fa-sort-size-up-alt:before {
  content: "\f88f";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-soup:before {
  content: "\f823";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-space-station-moon:before {
  content: "\e033";
}
.fa-space-station-moon-alt:before {
  content: "\e034";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-sparkles:before {
  content: "\f890";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-speaker:before {
  content: "\f8df";
}
.fa-speaker-deck:before {
  content: "\f83c";
}
.fa-speakers:before {
  content: "\f8e0";
}
.fa-spell-check:before {
  content: "\f891";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spider-black-widow:before {
  content: "\f718";
}
.fa-spider-web:before {
  content: "\f719";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-sprinkler:before {
  content: "\e035";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root:before {
  content: "\f697";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-squirrel:before {
  content: "\f71a";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-stackpath:before {
  content: "\f842";
}
.fa-staff:before {
  content: "\f71b";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-christmas:before {
  content: "\f7d4";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-star-shooting:before {
  content: "\e036";
}
.fa-starfighter:before {
  content: "\e037";
}
.fa-starfighter-alt:before {
  content: "\e038";
}
.fa-stars:before {
  content: "\f762";
}
.fa-starship:before {
  content: "\e039";
}
.fa-starship-freighter:before {
  content: "\e03a";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steak:before {
  content: "\f824";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-steering-wheel:before {
  content: "\f622";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stocking:before {
  content: "\f7d5";
}
.fa-stomach:before {
  content: "\f623";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-stopwatch-20:before {
  content: "\e06f";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-store-alt-slash:before {
  content: "\e070";
}
.fa-store-slash:before {
  content: "\e071";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-stretcher:before {
  content: "\f825";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-sun-cloud:before {
  content: "\f763";
}
.fa-sun-dust:before {
  content: "\f764";
}
.fa-sun-haze:before {
  content: "\f765";
}
.fa-sunglasses:before {
  content: "\f892";
}
.fa-sunrise:before {
  content: "\f766";
}
.fa-sunset:before {
  content: "\f767";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swift:before {
  content: "\f8e1";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-sword:before {
  content: "\f71c";
}
.fa-sword-laser:before {
  content: "\e03b";
}
.fa-sword-laser-alt:before {
  content: "\e03c";
}
.fa-swords:before {
  content: "\f71d";
}
.fa-swords-laser:before {
  content: "\e03d";
}
.fa-symfony:before {
  content: "\f83d";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tachometer-alt-average:before {
  content: "\f624";
}
.fa-tachometer-alt-fast:before {
  content: "\f625";
}
.fa-tachometer-alt-fastest:before {
  content: "\f626";
}
.fa-tachometer-alt-slow:before {
  content: "\f627";
}
.fa-tachometer-alt-slowest:before {
  content: "\f628";
}
.fa-tachometer-average:before {
  content: "\f629";
}
.fa-tachometer-fast:before {
  content: "\f62a";
}
.fa-tachometer-fastest:before {
  content: "\f62b";
}
.fa-tachometer-slow:before {
  content: "\f62c";
}
.fa-tachometer-slowest:before {
  content: "\f62d";
}
.fa-taco:before {
  content: "\f826";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tally:before {
  content: "\f69c";
}
.fa-tanakh:before {
  content: "\f827";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-tasks-alt:before {
  content: "\f828";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-telescope:before {
  content: "\e03e";
}
.fa-temperature-down:before {
  content: "\e03f";
}
.fa-temperature-frigid:before {
  content: "\f768";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-hot:before {
  content: "\f76a";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-temperature-up:before {
  content: "\e040";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text:before {
  content: "\f893";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-size:before {
  content: "\f894";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-theta:before {
  content: "\f69e";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-thunderstorm:before {
  content: "\f76c";
}
.fa-thunderstorm-moon:before {
  content: "\f76d";
}
.fa-thunderstorm-sun:before {
  content: "\f76e";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tiktok:before {
  content: "\e07b";
}
.fa-tilde:before {
  content: "\f69f";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tire:before {
  content: "\f631";
}
.fa-tire-flat:before {
  content: "\f632";
}
.fa-tire-pressure-warning:before {
  content: "\f633";
}
.fa-tire-rugged:before {
  content: "\f634";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toilet-paper-alt:before {
  content: "\f71f";
}
.fa-toilet-paper-slash:before {
  content: "\e072";
}
.fa-tombstone:before {
  content: "\f720";
}
.fa-tombstone-alt:before {
  content: "\f721";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-toothbrush:before {
  content: "\f635";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tornado:before {
  content: "\f76f";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-cone:before {
  content: "\f636";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-traffic-light-go:before {
  content: "\f638";
}
.fa-traffic-light-slow:before {
  content: "\f639";
}
.fa-traffic-light-stop:before {
  content: "\f63a";
}
.fa-trailer:before {
  content: "\e041";
}
.fa-train:before {
  content: "\f238";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-transporter:before {
  content: "\e042";
}
.fa-transporter-1:before {
  content: "\e043";
}
.fa-transporter-2:before {
  content: "\e044";
}
.fa-transporter-3:before {
  content: "\e045";
}
.fa-transporter-empty:before {
  content: "\e046";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-trash-undo:before {
  content: "\f895";
}
.fa-trash-undo-alt:before {
  content: "\f896";
}
.fa-treasure-chest:before {
  content: "\f723";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-tree-christmas:before {
  content: "\f7db";
}
.fa-tree-decorated:before {
  content: "\f7dc";
}
.fa-tree-large:before {
  content: "\f7dd";
}
.fa-tree-palm:before {
  content: "\f82b";
}
.fa-trees:before {
  content: "\f724";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-triangle-music:before {
  content: "\f8e2";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-truck-plow:before {
  content: "\f7de";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-trumpet:before {
  content: "\f8e3";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-turkey:before {
  content: "\f725";
}
.fa-turntable:before {
  content: "\f8e4";
}
.fa-turtle:before {
  content: "\f726";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-alt:before {
  content: "\f8e5";
}
.fa-tv-music:before {
  content: "\f8e6";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typewriter:before {
  content: "\f8e7";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-ufo:before {
  content: "\e047";
}
.fa-ufo-beam:before {
  content: "\e048";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbraco:before {
  content: "\f8e8";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-uncharted:before {
  content: "\e084";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-unicorn:before {
  content: "\f727";
}
.fa-union:before {
  content: "\f6a2";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-unity:before {
  content: "\e049";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-unsplash:before {
  content: "\e07c";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usb-drive:before {
  content: "\f8e9";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alien:before {
  content: "\e04a";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-chart:before {
  content: "\f6a3";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-cowboy:before {
  content: "\f8ea";
}
.fa-user-crown:before {
  content: "\f6a4";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-hard-hat:before {
  content: "\f82c";
}
.fa-user-headset:before {
  content: "\f82d";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-md-chat:before {
  content: "\f82e";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-music:before {
  content: "\f8eb";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-robot:before {
  content: "\e04b";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-user-unlock:before {
  content: "\e058";
}
.fa-user-visor:before {
  content: "\e04c";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-class:before {
  content: "\f63d";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-crown:before {
  content: "\f6a5";
}
.fa-users-medical:before {
  content: "\f830";
}
.fa-users-slash:before {
  content: "\e073";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-vacuum:before {
  content: "\e04d";
}
.fa-vacuum-robot:before {
  content: "\e04e";
}
.fa-value-absolute:before {
  content: "\f6a6";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-vest:before {
  content: "\e085";
}
.fa-vest-patches:before {
  content: "\e086";
}
.fa-vhs:before {
  content: "\f8ec";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-violin:before {
  content: "\f8ed";
}
.fa-virus:before {
  content: "\e074";
}
.fa-virus-slash:before {
  content: "\e075";
}
.fa-viruses:before {
  content: "\e076";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-voicemail:before {
  content: "\f897";
}
.fa-volcano:before {
  content: "\f770";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume:before {
  content: "\f6a8";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-slash:before {
  content: "\f2e2";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vote-nay:before {
  content: "\f771";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-wagon-covered:before {
  content: "\f8ee";
}
.fa-walker:before {
  content: "\f831";
}
.fa-walkie-talkie:before {
  content: "\f8ef";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-wand:before {
  content: "\f72a";
}
.fa-wand-magic:before {
  content: "\f72b";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-washer:before {
  content: "\f898";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-watch-calculator:before {
  content: "\f8f0";
}
.fa-watch-fitness:before {
  content: "\f63e";
}
.fa-watchman-monitoring:before {
  content: "\e087";
}
.fa-water:before {
  content: "\f773";
}
.fa-water-lower:before {
  content: "\f774";
}
.fa-water-rise:before {
  content: "\f775";
}
.fa-wave-sine:before {
  content: "\f899";
}
.fa-wave-square:before {
  content: "\f83e";
}
.fa-wave-triangle:before {
  content: "\f89a";
}
.fa-waveform:before {
  content: "\f8f1";
}
.fa-waveform-path:before {
  content: "\f8f2";
}
.fa-waze:before {
  content: "\f83f";
}
.fa-webcam:before {
  content: "\f832";
}
.fa-webcam-slash:before {
  content: "\f833";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whale:before {
  content: "\f72c";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheat:before {
  content: "\f72d";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wifi-1:before {
  content: "\f6aa";
}
.fa-wifi-2:before {
  content: "\f6ab";
}
.fa-wifi-slash:before {
  content: "\f6ac";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-wind-turbine:before {
  content: "\f89b";
}
.fa-wind-warning:before {
  content: "\f776";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-frame:before {
  content: "\e04f";
}
.fa-window-frame-open:before {
  content: "\e050";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-windsock:before {
  content: "\f777";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wodu:before {
  content: "\e088";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wreath:before {
  content: "\f7e2";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yammer:before {
  content: "\f840";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.eot');
  src: url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.woff2') format('woff2'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.woff') format('woff'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.ttf') format('truetype'), url('/webdav/files/System/resources/fonts/fontawesome-pro/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.eot') format('embedded-opentype'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.woff') format('woff'), url('/webdav/files/System/resources/fonts/open-sans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
/* roboto-100 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-100.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-300.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-italic.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-500.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-700.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-900 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'), url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.woff2') format('woff2'), /* Super Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.woff') format('woff'), /* Modern Browsers */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */ url('/webdav/files/System/resources/fonts/Roboto/roboto-v20-latin-900.svg#Roboto') format('svg');
  /* Legacy iOS */
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
}
.sv-grid-main.sv-fluid-grid {
  padding-left: 45px;
  padding-right: 45px;
}
@media (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.sv-grid-main.sv-fluid-grid .sol-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.sv-grid-main.sv-fluid-grid .sol-row.sol-row-reverse {
  flex-direction: row-reverse;
}
.sv-grid-main.sv-fluid-grid .sol-row.sol-row-center {
  justify-content: center;
}
@media (min-width: 1200px) {
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xl-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-lg-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-md-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-sm-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (max-width: 479px) {
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sol-row *.sol-col-xs-push-12 {
    position: relative;
    left: 100%;
  }
}
@media (min-width: 1200px), (min-width: 992px) and (max-width: 1199px) {
  .sv-grid-main.sv-fluid-grid .sv-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-reverse {
    flex-direction: row-reverse;
  }
  .sv-grid-main.sv-fluid-grid .sv-row.sol-row-center {
    justify-content: center;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-1 {
    width: auto !important;
    flex: 1 0 8%;
    max-width: 8%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-1 {
    position: relative;
    left: 8.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-2 {
    width: auto !important;
    flex: 1 0 16%;
    max-width: 16%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-2 {
    position: relative;
    left: 16.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-3 {
    width: auto !important;
    flex: 1 0 25%;
    max-width: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-3 {
    position: relative;
    left: 25%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-4 {
    width: auto !important;
    flex: 1 0 33%;
    max-width: 33%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-4 {
    position: relative;
    left: 33.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-5 {
    width: auto !important;
    flex: 1 0 41%;
    max-width: 41%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-5 {
    position: relative;
    left: 41.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-6 {
    width: auto !important;
    flex: 1 0 50%;
    max-width: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-6 {
    position: relative;
    left: 50%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-7 {
    width: auto !important;
    flex: 1 0 58%;
    max-width: 58%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-7 {
    position: relative;
    left: 58.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-8 {
    width: auto !important;
    flex: 1 0 66%;
    max-width: 66%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-8 {
    position: relative;
    left: 66.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-9 {
    width: auto !important;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-9 {
    position: relative;
    left: 75%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-10 {
    width: auto !important;
    flex: 1 0 83%;
    max-width: 83%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-10 {
    position: relative;
    left: 83.33333333%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-11 {
    width: auto !important;
    flex: 1 0 91%;
    max-width: 91%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-11 {
    position: relative;
    left: 91.66666667%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-12 {
    width: auto !important;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row *.sv-column-push-12 {
    position: relative;
    left: 100%;
  }
  .sv-grid-main.sv-fluid-grid .sv-row::before,
  .sv-grid-main.sv-fluid-grid .sv-row::after {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 767px), (min-width: 992px) and (max-width: 1199px) and (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sv-row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.sv-grid-main.sv-fluid-grid *[class*=sol-col-],
.sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
  flex: 1 0 100%;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid *[class*=sol-col-],
  .sv-grid-main.sv-fluid-grid *[class*=sv-column-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.sol-show-lg,
.sol-show-md,
.sol-show-sm,
.sol-show-xl,
.sol-show-xs {
  display: none;
}
.sol-hide-sm {
  display: inherit;
}
@media (min-width: 1200px) {
  .sol-hide-xl {
    display: none;
  }
  .sol-show-xl {
    display: inherit;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sol-hide-lg {
    display: none;
  }
  .sol-show-lg {
    display: inherit;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-hide-md {
    display: none;
  }
  .sol-show-md {
    display: inherit;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sol-hide-sm {
    display: none;
  }
  .sol-show-sm {
    display: inherit;
  }
}
@media (max-width: 479px) {
  .sol-hide-xs {
    display: none;
  }
  .sol-show-xs {
    display: inherit;
  }
}
.sol-action-link {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sol-action-link a {
  position: relative;
  display: block;
  height: auto;
  min-height: 42px;
  padding: 5px 50px 5px 10px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 0;
}
@media (max-width: 767px) {
  .sol-action-link a {
    font-size: 17px;
  }
}
.sol-action-link a::after {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 30px;
  height: 27px;
  padding-top: 7px;
  padding-left: 4px;
  color: #fff;
  font-weight: 100;
  font-size: 19px;
  font-family: 'Font Awesome 5 Pro';
  line-height: normal;
  text-align: center;
  background-color: #508128;
  border-radius: 50%;
  transform: translateY(-50%);
  content: '\f054';
}
.sol-action-link a:hover {
  color: #fff;
  text-decoration: underline;
  background-color: #508128;
  border: 1px solid #508128;
}
.sol-action-link a:hover::after {
  color: #508128;
  background-color: #fff;
}
.sol-action-link a i.fa-external-link {
  display: inline;
}
.sol-action-link a i.fa-external-link::before {
  position: relative;
  top: -3px;
  margin-left: 8px;
  font-size: 14px;
}
.input-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}
.input-required-asterisk::after {
  margin-left: 2px;
  color: #d53636;
  font-size: 14px;
  content: '*';
}
.input-text {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
}
.input-text::input-placeholder {
  color: #b4b4b4;
}
.input-text:placeholder {
  color: #b4b4b4;
}
.input-text::placeholder {
  color: #b4b4b4;
}
.input-text:input-placeholder {
  color: #b4b4b4;
}
.input-text:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-text:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.input-text:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.input-text-small {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  height: 35px;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-text-small::input-placeholder {
  color: #b4b4b4;
}
.input-text-small:placeholder {
  color: #b4b4b4;
}
.input-text-small::placeholder {
  color: #b4b4b4;
}
.input-text-small:input-placeholder {
  color: #b4b4b4;
}
.input-text-small:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-text-small:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.input-text-small:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.input-select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-position: right 15px top 12px;
  background-size: 13px;
  cursor: pointer;
}
.input-select::input-placeholder {
  color: #b4b4b4;
}
.input-select:placeholder {
  color: #b4b4b4;
}
.input-select::placeholder {
  color: #b4b4b4;
}
.input-select:input-placeholder {
  color: #b4b4b4;
}
.input-select:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-select:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.input-select:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.sv-defaultFormTheme select[disabled] {
  background-color: #ddd;
}
.sv-defaultFormTheme select[disabled]:focus,
.sv-defaultFormTheme select[disabled]:hover {
  background-color: #ddd;
}
.input-select-small {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-position: right 15px top 12px;
  background-size: 13px;
  cursor: pointer;
  padding: 9px 16px 8px;
  font-size: 12px;
}
.input-select-small::input-placeholder {
  color: #b4b4b4;
}
.input-select-small:placeholder {
  color: #b4b4b4;
}
.input-select-small::placeholder {
  color: #b4b4b4;
}
.input-select-small:input-placeholder {
  color: #b4b4b4;
}
.input-select-small:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-select-small:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.input-select-small:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.input-text-invalid {
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/times_invalid.svg');
  border-color: #eec3c3;
}
.input-text-valid {
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/check_valid.svg');
  border-color: #c7e6b3;
}
.input-textarea {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  max-width: 100%;
  height: auto;
  min-height: 120px;
}
.input-textarea::input-placeholder {
  color: #b4b4b4;
}
.input-textarea:placeholder {
  color: #b4b4b4;
}
.input-textarea::placeholder {
  color: #b4b4b4;
}
.input-textarea:input-placeholder {
  color: #b4b4b4;
}
.input-textarea:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-textarea:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.input-textarea:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.input-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.input-checkbox,
.input-radio {
  display: block;
  height: 0;
  opacity: 0%;
}
.input-checkbox + label::before,
.input-radio + label::before {
  position: relative;
  top: -1px;
  display: inline-block;
  float: left;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
  border: 2px solid #b4b4b4;
  border-radius: 2px;
  content: '';
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color, background-color, border-color, border-width;
}
.input-checkbox + label,
.input-radio + label {
  display: block;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.3em;
  cursor: pointer;
}
.input-checkbox:focus + label::before,
.input-radio:focus + label::before,
.input-checkbox:focus:checked + label::before,
.input-radio:focus:checked + label::before {
  border: 2px solid #2A5A45;
}
.input-checkbox {
  border-radius: 0;
}
.input-checkbox:checked + label::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  display: inline-block;
  font-size: 12px;
  content: "\f00c";
  padding-top: 2px;
  color: #fff;
  background-color: #0071bb;
  border-color: #0071bb;
}
.input-checkbox:checked + label::before.fa-pull-left {
  margin-right: 0.3em;
}
.input-checkbox:checked + label::before.fa-pull-right {
  margin-left: 0.3em;
}
.input-radio + label::before {
  top: 1px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.input-radio:checked + label::before {
  border-color: #0071bb;
  border-width: 3px;
}
.input-file {
  display: block;
  height: 0;
  opacity: 0%;
}
.input-file + label {
  margin-bottom: 20px;
}
.input-file + label::after,
.input-file + label::before {
  display: table;
  content: '';
}
.input-file + label::after {
  clear: both;
}
.input-file + label .sol-form-file-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 8px;
}
.input-file + label .sol-form-file-btn {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
  margin-top: 2px;
  margin-left: 10px;
  padding: 5px 20px;
  font-size: 13px;
}
.input-file + label .sol-form-file-btn:focus,
[data-whatinput='keyboard'] .input-file + label .sol-form-file-btn:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .input-file + label .sol-form-file-btn:focus,
[data-whatinput='mouse'] .input-file + label .sol-form-file-btn:focus,
[data-whatinput='touch'] .input-file + label .sol-form-file-btn:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-file + label .sol-form-file-btn:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.input-file + label .sol-form-file-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.input-file + label .sol-form-file-btn i.fa,
.input-file + label .sol-form-file-btn i.fal,
.input-file + label .sol-form-file-btn i.far,
.input-file + label .sol-form-file-btn i.fas,
.input-file + label .sol-form-file-btn i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.input-file + label .sol-form-file-text {
  float: left;
  margin-left: 15px;
  font-weight: 300;
  font-size: 15px;
  line-height: 34px;
}
.input-file + label .sol-form-file-remove i {
  margin-top: 7px;
  margin-left: 13px;
  color: #0071BB;
  font-size: 18px;
}
.input-btn {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
}
.input-btn:focus,
[data-whatinput='keyboard'] .input-btn:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .input-btn:focus,
[data-whatinput='mouse'] .input-btn:focus,
[data-whatinput='touch'] .input-btn:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-btn:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.input-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.input-btn i.fa,
.input-btn i.fal,
.input-btn i.far,
.input-btn i.fas,
.input-btn i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.input-btn-small {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
  padding: 5px 15px 6px;
  font-size: 14px;
}
.input-btn-small:focus,
[data-whatinput='keyboard'] .input-btn-small:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .input-btn-small:focus,
[data-whatinput='mouse'] .input-btn-small:focus,
[data-whatinput='touch'] .input-btn-small:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.input-btn-small:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.input-btn-small:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.input-btn-small i.fa,
.input-btn-small i.fal,
.input-btn-small i.far,
.input-btn-small i.fas,
.input-btn-small i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
body {
  overflow-x: hidden;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  letter-spacing: 0.4px;
  background-color: white;
}
p,
p.font-normal,
.font-normal,
.sol-plusbox-anchor,
p.font-description,
.font-description {
  margin-top: 0;
  margin-bottom: 16px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 26px;
}
@media (max-width: 991px) {
  p,
  p.font-normal,
  .font-normal,
  .sol-plusbox-anchor,
  p.font-description,
  .font-description {
    font-size: 19px;
  }
}
@media (max-width: 767px) {
  p,
  p.font-normal,
  .font-normal,
  .sol-plusbox-anchor,
  p.font-description,
  .font-description {
    font-size: 17px;
  }
}
ap,
ap.font-normal,
a.font-normal,
a.sol-plusbox-anchor,
ap.font-description,
a.font-description,
p a,
p.font-normal a,
.font-normal a,
.sol-plusbox-anchor a,
p.font-description a,
.font-description a {
  color: #0071bb;
  text-decoration: underline;
}
ap:hover,
ap.font-normal:hover,
a.font-normal:hover,
a.sol-plusbox-anchor:hover,
ap.font-description:hover,
a.font-description:hover,
p a:hover,
p.font-normal a:hover,
.font-normal a:hover,
.sol-plusbox-anchor a:hover,
p.font-description a:hover,
.font-description a:hover {
  color: #0090ee;
}
p.font-description {
  color: #2c2c2c;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.5px;
}
ap.font-description,
p.font-description a {
  color: #0071bb;
}
ap.font-description:hover,
p.font-description a:hover {
  color: #0090ee;
}
@media (max-width: 991px) {
  p.font-description {
    font-size: 23px;
  }
}
@media (max-width: 479px) {
  p.font-description {
    font-size: 21px;
    line-height: 30px;
  }
}
h1,
.font-heading-1,
h1.font-heading-1 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 48px;
  font-family: 'Roboto', sans-serif;
  line-height: 54px;
  letter-spacing: 0.6px;
}
@media (max-width: 991px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 31px;
    line-height: 25px;
  }
}
@media (max-width: 767px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    font-size: 26px;
  }
}
@media (max-width: 479px) {
  h1,
  .font-heading-1,
  h1.font-heading-1 {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 29px;
    line-height: 31px;
  }
}
h2,
h2.font-heading-2,
.font-heading-2 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}
@media (max-width: 479px) {
  h2,
  h2.font-heading-2,
  .font-heading-2 {
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
  }
}
h3,
h3.font-heading-3,
.font-heading-3 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}
@media (max-width: 479px) {
  h3,
  h3.font-heading-3,
  .font-heading-3 {
    font-size: 19px;
  }
}
h4,
h4.font-heading-4,
.font-heading-4 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}
@media (max-width: 479px) {
  h4,
  h4.font-heading-4,
  .font-heading-4 {
    font-size: 15px;
  }
}
h5,
h5.font-heading-5,
.font-heading-5 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}
@media (max-width: 479px) {
  h5,
  h5.font-heading-5,
  .font-heading-5 {
    font-size: 14px;
  }
}
p.font-quote,
.font-quote,
blockquote.sv-blockquote p,
blockquote.sv-blockquote-edit p,
p q {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 55px;
  color: #0071BB;
  font-weight: 300;
  font-size: 23px;
  font-family: 'Roboto', sans-serif;
  line-height: 29px;
}
@media (max-width: 991px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p,
  p q {
    padding-left: 35px;
    font-size: 20px;
    line-height: 27px;
  }
}
@media (max-width: 767px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p,
  p q {
    font-size: 18px;
    line-height: 25px;
  }
}
@media (max-width: 479px) {
  p.font-quote,
  .font-quote,
  blockquote.sv-blockquote p,
  blockquote.sv-blockquote-edit p,
  p q {
    padding-left: 30px;
  }
}
p.font-quote::before,
.font-quote::before,
blockquote.sv-blockquote p::before,
blockquote.sv-blockquote-edit p::before,
p q::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  display: inline-block;
  font-size: 20px;
  content: "\f10e";
  position: absolute;
  top: 0;
  left: 20px;
  line-height: 35px;
  opacity: 30%;
}
p.font-quote::before.fa-pull-left,
.font-quote::before.fa-pull-left,
blockquote.sv-blockquote p::before.fa-pull-left,
blockquote.sv-blockquote-edit p::before.fa-pull-left,
p q::before.fa-pull-left {
  margin-right: 0.3em;
}
p.font-quote::before.fa-pull-right,
.font-quote::before.fa-pull-right,
blockquote.sv-blockquote p::before.fa-pull-right,
blockquote.sv-blockquote-edit p::before.fa-pull-right,
p q::before.fa-pull-right {
  margin-left: 0.3em;
}
@media (max-width: 991px) {
  p.font-quote::before,
  .font-quote::before,
  blockquote.sv-blockquote p::before,
  blockquote.sv-blockquote-edit p::before,
  p q::before {
    left: 5px;
    line-height: 33px;
  }
}
@media (max-width: 479px) {
  p.font-quote::before,
  .font-quote::before,
  blockquote.sv-blockquote p::before,
  blockquote.sv-blockquote-edit p::before,
  p q::before {
    left: 5px;
    line-height: 31px;
  }
}
blockquote.sv-blockquote::before,
blockquote.sv-blockquote-edit::before {
  display: none;
}
ol.sol-bullet-list,
ul.sol-bullet-list,
ol.sol-numbered-list,
ul.sol-numbered-list {
  margin-top: 5px;
  margin-bottom: 20px;
  padding-left: 30px;
  list-style: none !important;
}
ol.sol-bullet-list li,
ul.sol-bullet-list li,
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  position: relative;
  margin-bottom: 8px;
  padding-left: 19px;
}
ol.sol-bullet-list li::before,
ul.sol-bullet-list li::before,
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  font-weight: 700;
}
ol.sol-bullet-list li a,
ul.sol-bullet-list li a,
ol.sol-numbered-list li a,
ul.sol-numbered-list li a {
  color: #0071bb;
  text-decoration: underline;
}
.sol-bullet-list li::before {
  color: #0071bb;
  font-size: 11px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f111';
}
ol.sol-numbered-list,
ul.sol-numbered-list {
  padding-left: 21px;
}
ol.sol-numbered-list li,
ul.sol-numbered-list li {
  padding-left: 36px;
  counter-increment: step-counter;
}
ol.sol-numbered-list li::before,
ul.sol-numbered-list li::before {
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6em;
  height: 1.6em;
  color: #fff;
  font-weight: 300;
  font-size: 23px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background-color: #0071BB;
  border-radius: 50%;
  transform: scale(0.6);
  content: counter(step-counter);
}
.env-form-radio__fake::before {
  top: 2.5px;
  left: 0;
}
* {
  box-sizing: border-box;
}
a:focus,
button:focus,
[data-whatinput='keyboard'] a:focus,
[data-whatinput='keyboard'] button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] a:focus,
[data-whatinput='initial'] button:focus,
[data-whatinput='mouse'] a:focus,
[data-whatinput='mouse'] button:focus,
[data-whatinput='touch'] a:focus,
[data-whatinput='touch'] button:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}
.sol-ul,
.sol-ul-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sol-float-right {
  float: right;
}
.sol-float-left {
  float: left;
}
.sol-hidden {
  display: none;
}
.sol-visibility-hidden {
  visibility: hidden;
}
.sol-clearfix::after,
.sol-clearfix::before {
  display: table;
  content: '';
}
.sol-clearfix::after {
  clear: both;
}
.sol-relative {
  position: relative;
}
.sol-no-anim * {
  transition: none !important;
}
.sol-external-link-icon {
  margin-left: 6px;
  font-size: 10px;
}
.sol-box-decoration {
  background-color: #fff;
  margin-bottom: 20px;
}
.sol-box-decoration::after,
.sol-box-decoration::before {
  display: table;
  content: '';
}
.sol-box-decoration::after {
  clear: both;
}
.sol-box-decoration-header {
  display: block;
  padding: 12px 15px;
  border-bottom: 3px solid #0071BB;
  color: #2c2c2c;
  text-align: left;
  text-decoration: none;
  line-height: 13px;
  font-weight: 300;
  font-size: 23px;
  margin: 0;
}
a .sol-box-decoration-header:focus,
[data-whatinput='keyboard'] a .sol-box-decoration-header:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] a .sol-box-decoration-header:focus,
[data-whatinput='mouse'] a .sol-box-decoration-header:focus,
[data-whatinput='touch'] a .sol-box-decoration-header:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-box-decoration .sv-text-portlet.sol-btn-small .font-btn {
  margin-bottom: 5px;
}
.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #e0e0e0;
}
.sol-simple-box-decoration {
  margin-top: 25px;
  padding: 10px 20px 5px 15px;
  border-top: 3px solid #0071bb;
  border-radius: 0px;
  background-color: #fff;
}
.sol-simple-box-decoration.sol-simple-box-decoration-alt {
  background-color: rgba(229, 240, 248, 0.6);
}
.sol-simple-box-decoration > div {
  display: block;
  margin-bottom: 5px;
}
.sv-editing-mode .sol-error-box,
.sv-editing-mode .sol-warning-box {
  display: block;
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.sol-collapsible {
  margin: 20px 0;
}
.sol-collapsible h2 {
  margin-bottom: 0;
}
.sol-collapsible__header {
  background: none;
  border: none;
  appearance: none;
  letter-spacing: 0.4px;
  position: relative;
  display: block;
  width: 100%;
  min-height: 42px;
  line-height: 25px;
  padding: 10px 50px 10px 10px;
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 0;
  color: #2c2c2c;
  text-align: left;
  text-decoration: none;
  font-weight: 400;
  font-size: 19px;
  margin: 0;
  cursor: pointer;
}
.sol-collapsible__header:focus,
[data-whatinput='keyboard'] .sol-collapsible__header:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-collapsible__header:focus,
[data-whatinput='mouse'] .sol-collapsible__header:focus,
[data-whatinput='touch'] .sol-collapsible__header:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media (max-width: 767px) {
  .sol-collapsible__header {
    font-size: 17px;
  }
}
.sol-collapsible__header:hover {
  background-color: #e5f0f8;
}
.sol-collapsible__header__icon {
  display: block;
  background-color: #0071BB;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -17px;
}
.sol-collapsible__header__icon::after,
.sol-collapsible__header__icon::before {
  content: "";
  display: block;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1.5px;
  margin-left: -10px;
  margin-top: -1px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: all;
}
.sol-collapsible__header__icon::after {
  transform: rotate(90deg);
}
.sol-collapsible__header--active {
  background-color: #0071BB;
  color: #fff;
  border: 1px solid #0071BB;
}
.sol-collapsible__header--active .sol-collapsible__header__icon {
  background-color: #fff;
}
.sol-collapsible__header--active .sol-collapsible__header__icon::after {
  background-color: #0071BB;
  transform: rotate(0deg);
}
.sol-collapsible__header--active:hover {
  background-color: #0071BB;
}
.sol-collapsible .sol-collapsible__content,
.sol-collapsible .sv-decoration-content {
  display: none;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-bottom: 3px solid #0071BB;
  padding: 20px;
}
.sol-collapsible .sol-collapsible__content > *:last-child,
.sol-collapsible .sv-decoration-content > *:last-child {
  margin-bottom: 0;
}
.sol-collapsible .sol-collapsible__content p:last-of-type,
.sol-collapsible .sv-decoration-content p:last-of-type {
  margin: 0;
}
.sv-editing-mode .sol-collapsible .sol-collapsible__content,
.sv-editing-mode .sol-collapsible .sv-decoration-content {
  display: block;
}
.sol-btn {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
}
.sol-btn:focus,
[data-whatinput='keyboard'] .sol-btn:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-btn:focus,
[data-whatinput='mouse'] .sol-btn:focus,
[data-whatinput='touch'] .sol-btn:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-btn:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.sol-btn:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.sol-btn i.fa,
.sol-btn i.fal,
.sol-btn i.far,
.sol-btn i.fas,
.sol-btn i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.font-btn {
  padding-top: 10px;
  padding-bottom: 10px;
}
.font-btn > a {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-top: 9px;
  padding-bottom: 7px;
}
.font-btn > a:focus,
[data-whatinput='keyboard'] .font-btn > a:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .font-btn > a:focus,
[data-whatinput='mouse'] .font-btn > a:focus,
[data-whatinput='touch'] .font-btn > a:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.font-btn > a:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.font-btn > a:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.font-btn > a i.fa,
.font-btn > a i.fal,
.font-btn > a i.far,
.font-btn > a i.fas,
.font-btn > a i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.font-btn .sol-external-link-icon {
  display: none;
}
.sol-btn-small {
  padding: 14px 20px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
  line-height: 10px;
  text-transform: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: none;
}
.sol-btn-small:hover {
  color: #fff;
  background-color: #0071BB;
}
.sol-btn-small:active {
  top: 0;
  box-shadow: none;
}
.sv-text-portlet.sol-font-btn-small {
  margin: 20px 0;
}
.sv-text-portlet.sol-font-btn-small .font-btn {
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 5px;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a {
  padding: 14px 20px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
  line-height: 10px;
  text-transform: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: none;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a:hover {
  color: #fff;
  background-color: #0071BB;
}
.sv-text-portlet.sol-font-btn-small .font-btn > a:active {
  top: 0;
  box-shadow: none;
}
.sv-text-portlet table.sol-table-decoration {
  overflow: hidden;
  border-style: hidden;
  border-radius: 3px;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px #e5f0f8;
}
.sv-text-portlet table.sol-table-decoration caption {
  margin-top: 25px;
  margin-bottom: 5px;
  font-weight: 800;
  font-size: 17px;
  line-height: 1.2em;
}
.sv-text-portlet table.sol-table-decoration thead {
  background-color: #0071bb;
}
.sv-text-portlet table.sol-table-decoration thead th {
  padding: 11px 18px;
  border-right: 1px solid rgba(229, 240, 248, 0.3);
}
.sv-text-portlet table.sol-table-decoration thead th p {
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
}
.sv-text-portlet table.sol-table-decoration thead th:last-child {
  border: none;
  border-top-right-radius: 3px;
}
.sv-text-portlet table.sol-table-decoration thead th:first-child {
  border-top-left-radius: 3px;
}
.sv-text-portlet table.sol-table-decoration tbody {
  background-color: #fff;
}
.sv-text-portlet table.sol-table-decoration tbody tr td {
  padding: 11px 18px;
  border-right: 1px solid rgba(0, 113, 187, 0.15);
}
.sv-text-portlet table.sol-table-decoration tbody tr td p {
  font-weight: 300;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}
.sv-text-portlet table.sol-table-decoration tbody tr td:last-child {
  border: none;
}
.sv-text-portlet table.sol-table-decoration tbody tr:nth-child(odd) {
  background-color: rgba(0, 113, 187, 0.15);
}
.sol-form,
.sol-form-decoration {
  margin-bottom: 20px;
  padding: 20px 20px 40px;
  background-color: #fff;
  border-radius: 3px;
}
@media (max-width: 991px) {
  .sol-form,
  .sol-form-decoration {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .sol-form,
  .sol-form-decoration {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.sol-form h2,
.sol-form-decoration h2 {
  margin-top: 0;
}
.sol-form form input[type='email'],
.sol-form-decoration form input[type='email'],
.sol-form form input[type='number'],
.sol-form-decoration form input[type='number'],
.sol-form form input[type='password'],
.sol-form-decoration form input[type='password'],
.sol-form form input[type='tel'],
.sol-form-decoration form input[type='tel'],
.sol-form form input[type='text'],
.sol-form-decoration form input[type='text'],
.sol-form form input[type='url'],
.sol-form-decoration form input[type='url'],
.sol-form form select,
.sol-form-decoration form select,
.sol-form form textarea,
.sol-form-decoration form textarea,
.sol-vuejobs__list select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  letter-spacing: 0.6px;
}
.sol-form form input[type='email']::input-placeholder,
.sol-form-decoration form input[type='email']::input-placeholder,
.sol-form form input[type='number']::input-placeholder,
.sol-form-decoration form input[type='number']::input-placeholder,
.sol-form form input[type='password']::input-placeholder,
.sol-form-decoration form input[type='password']::input-placeholder,
.sol-form form input[type='tel']::input-placeholder,
.sol-form-decoration form input[type='tel']::input-placeholder,
.sol-form form input[type='text']::input-placeholder,
.sol-form-decoration form input[type='text']::input-placeholder,
.sol-form form input[type='url']::input-placeholder,
.sol-form-decoration form input[type='url']::input-placeholder,
.sol-form form select::input-placeholder,
.sol-form-decoration form select::input-placeholder,
.sol-form form textarea::input-placeholder,
.sol-form-decoration form textarea::input-placeholder {
  color: #b4b4b4;
}
.sol-form form input[type='email']:placeholder,
.sol-form-decoration form input[type='email']:placeholder,
.sol-form form input[type='number']:placeholder,
.sol-form-decoration form input[type='number']:placeholder,
.sol-form form input[type='password']:placeholder,
.sol-form-decoration form input[type='password']:placeholder,
.sol-form form input[type='tel']:placeholder,
.sol-form-decoration form input[type='tel']:placeholder,
.sol-form form input[type='text']:placeholder,
.sol-form-decoration form input[type='text']:placeholder,
.sol-form form input[type='url']:placeholder,
.sol-form-decoration form input[type='url']:placeholder,
.sol-form form select:placeholder,
.sol-form-decoration form select:placeholder,
.sol-form form textarea:placeholder,
.sol-form-decoration form textarea:placeholder {
  color: #b4b4b4;
}
.sol-form form input[type='email']::placeholder,
.sol-form-decoration form input[type='email']::placeholder,
.sol-form form input[type='number']::placeholder,
.sol-form-decoration form input[type='number']::placeholder,
.sol-form form input[type='password']::placeholder,
.sol-form-decoration form input[type='password']::placeholder,
.sol-form form input[type='tel']::placeholder,
.sol-form-decoration form input[type='tel']::placeholder,
.sol-form form input[type='text']::placeholder,
.sol-form-decoration form input[type='text']::placeholder,
.sol-form form input[type='url']::placeholder,
.sol-form-decoration form input[type='url']::placeholder,
.sol-form form select::placeholder,
.sol-form-decoration form select::placeholder,
.sol-form form textarea::placeholder,
.sol-form-decoration form textarea::placeholder {
  color: #b4b4b4;
}
.sol-form form input[type='email']:input-placeholder,
.sol-form-decoration form input[type='email']:input-placeholder,
.sol-form form input[type='number']:input-placeholder,
.sol-form-decoration form input[type='number']:input-placeholder,
.sol-form form input[type='password']:input-placeholder,
.sol-form-decoration form input[type='password']:input-placeholder,
.sol-form form input[type='tel']:input-placeholder,
.sol-form-decoration form input[type='tel']:input-placeholder,
.sol-form form input[type='text']:input-placeholder,
.sol-form-decoration form input[type='text']:input-placeholder,
.sol-form form input[type='url']:input-placeholder,
.sol-form-decoration form input[type='url']:input-placeholder,
.sol-form form select:input-placeholder,
.sol-form-decoration form select:input-placeholder,
.sol-form form textarea:input-placeholder,
.sol-form-decoration form textarea:input-placeholder {
  color: #b4b4b4;
}
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-form form input[type='email']:hover,
.sol-form-decoration form input[type='email']:hover,
.sol-form form input[type='number']:hover,
.sol-form-decoration form input[type='number']:hover,
.sol-form form input[type='password']:hover,
.sol-form-decoration form input[type='password']:hover,
.sol-form form input[type='tel']:hover,
.sol-form-decoration form input[type='tel']:hover,
.sol-form form input[type='text']:hover,
.sol-form-decoration form input[type='text']:hover,
.sol-form form input[type='url']:hover,
.sol-form-decoration form input[type='url']:hover,
.sol-form form select:hover,
.sol-form-decoration form select:hover,
.sol-form form textarea:hover,
.sol-form-decoration form textarea:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.sol-form form input[type='email']:focus,
.sol-form-decoration form input[type='email']:focus,
.sol-form form input[type='number']:focus,
.sol-form-decoration form input[type='number']:focus,
.sol-form form input[type='password']:focus,
.sol-form-decoration form input[type='password']:focus,
.sol-form form input[type='tel']:focus,
.sol-form-decoration form input[type='tel']:focus,
.sol-form form input[type='text']:focus,
.sol-form-decoration form input[type='text']:focus,
.sol-form form input[type='url']:focus,
.sol-form-decoration form input[type='url']:focus,
.sol-form form select:focus,
.sol-form-decoration form select:focus,
.sol-form form textarea:focus,
.sol-form-decoration form textarea:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.sol-form form input[type='email']:invalid,
.sol-form-decoration form input[type='email']:invalid,
.sol-form form input[type='number']:invalid,
.sol-form-decoration form input[type='number']:invalid,
.sol-form form input[type='password']:invalid,
.sol-form-decoration form input[type='password']:invalid,
.sol-form form input[type='tel']:invalid,
.sol-form-decoration form input[type='tel']:invalid,
.sol-form form input[type='text']:invalid,
.sol-form-decoration form input[type='text']:invalid,
.sol-form form input[type='url']:invalid,
.sol-form-decoration form input[type='url']:invalid,
.sol-form form select:invalid,
.sol-form-decoration form select:invalid,
.sol-form form textarea:invalid,
.sol-form-decoration form textarea:invalid {
  background-color: #f7e4e4;
  background-image: url('/webdav/files/System/resources/graphics/icons/times_invalid.svg');
  border-color: #eec3c3;
}
.sol-form form input[type='email']:required:valid,
.sol-form-decoration form input[type='email']:required:valid,
.sol-form form input[type='number']:required:valid,
.sol-form-decoration form input[type='number']:required:valid,
.sol-form form input[type='password']:required:valid,
.sol-form-decoration form input[type='password']:required:valid,
.sol-form form input[type='tel']:required:valid,
.sol-form-decoration form input[type='tel']:required:valid,
.sol-form form input[type='text']:required:valid,
.sol-form-decoration form input[type='text']:required:valid,
.sol-form form input[type='url']:required:valid,
.sol-form-decoration form input[type='url']:required:valid,
.sol-form form select:required:valid,
.sol-form-decoration form select:required:valid,
.sol-form form textarea:required:valid,
.sol-form-decoration form textarea:required:valid {
  background-color: #eef7e8;
  background-image: url('/webdav/files/System/resources/graphics/icons/check_valid.svg');
  border-color: #c7e6b3;
}
.sol-form form input[type='email'] ~ span.font-normal,
.sol-form-decoration form input[type='email'] ~ span.font-normal,
.sol-form form input[type='number'] ~ span.font-normal,
.sol-form-decoration form input[type='number'] ~ span.font-normal,
.sol-form form input[type='password'] ~ span.font-normal,
.sol-form-decoration form input[type='password'] ~ span.font-normal,
.sol-form form input[type='tel'] ~ span.font-normal,
.sol-form-decoration form input[type='tel'] ~ span.font-normal,
.sol-form form input[type='text'] ~ span.font-normal,
.sol-form-decoration form input[type='text'] ~ span.font-normal,
.sol-form form input[type='url'] ~ span.font-normal,
.sol-form-decoration form input[type='url'] ~ span.font-normal,
.sol-form form select ~ span.font-normal,
.sol-form-decoration form select ~ span.font-normal,
.sol-form form textarea ~ span.font-normal,
.sol-form-decoration form textarea ~ span.font-normal {
  margin-top: 10px;
}
.sol-form form textarea,
.sol-form-decoration form textarea {
  height: auto;
}
.sol-form form select,
.sol-form-decoration form select,
.sol-vuejobs__list select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  background-color: #f8f8f8;
  background-repeat: no-repeat;
  background-position: right 10px top 8px;
  background-size: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  box-shadow: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border, background-color, box-shadow;
  background-image: url('/webdav/files/System/resources/graphics/icons/chevron-down.svg');
  background-position: right 15px top 12px;
  background-size: 13px;
  cursor: pointer;
}
.sol-form form select::input-placeholder,
.sol-form-decoration form select::input-placeholder {
  color: #b4b4b4;
}
.sol-form form select:placeholder,
.sol-form-decoration form select:placeholder {
  color: #b4b4b4;
}
.sol-form form select::placeholder,
.sol-form-decoration form select::placeholder {
  color: #b4b4b4;
}
.sol-form form select:input-placeholder,
.sol-form-decoration form select:input-placeholder {
  color: #b4b4b4;
}
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-form form select:hover,
.sol-form-decoration form select:hover {
  background-color: #fff;
  border-color: #d6d6d6;
}
.sol-form form select:focus,
.sol-form-decoration form select:focus {
  background-color: #fff;
  border-color: #2A5A45;
}
.sol-form form .sol-form-field,
.sol-form-decoration form .sol-form-field {
  margin-bottom: 10px;
}
.sol-form form .sol-label-container > label,
.sol-form-decoration form .sol-label-container > label,
.sol-form form .sv-field-title-container > label,
.sol-form-decoration form .sv-field-title-container > label,
.sol-form form .sv-login-input-group > label,
.sol-form-decoration form .sv-login-input-group > label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}
.sol-form form .sol-label-container > span,
.sol-form-decoration form .sol-label-container > span,
.sol-form form .sv-field-title-container > span,
.sol-form-decoration form .sv-field-title-container > span,
.sol-form form .sv-login-input-group > span,
.sol-form-decoration form .sv-login-input-group > span {
  display: block;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
}
.sol-form form .sv-form-datefield > div,
.sol-form-decoration form .sv-form-datefield > div {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  float: none !important;
  margin: 0 !important;
  padding-left: 25px;
}
@media (max-width: 767px) {
  .sol-form form .sv-form-datefield > div:nth-of-type(1),
  .sol-form-decoration form .sv-form-datefield > div:nth-of-type(1) {
    margin-bottom: 20px !important;
  }
}
.sol-form form .sv-form-datefield > div:nth-of-type(1) > select,
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(1) > select {
  flex: 0 0 30%;
  margin-right: 1%;
}
@media (max-width: 767px) {
  .sol-form form .sv-form-datefield > div:nth-of-type(1) > select,
  .sol-form-decoration form .sv-form-datefield > div:nth-of-type(1) > select {
    flex: 0 0 100%;
  }
}
.sol-form form .sv-form-datefield > div:nth-of-type(1)::before,
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(1)::before {
  position: absolute;
  top: 9px;
  left: 0;
  font-weight: 100;
  font-size: 21px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f073';
}
.sol-form form .sv-form-datefield > div:nth-of-type(2) > select,
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(2) > select {
  flex: 0 0 30%;
  margin-right: 1%;
}
@media (max-width: 767px) {
  .sol-form form .sv-form-datefield > div:nth-of-type(2) > select,
  .sol-form-decoration form .sv-form-datefield > div:nth-of-type(2) > select {
    flex: 0 0 100%;
  }
}
.sol-form form .sv-form-datefield > div:nth-of-type(2)::before,
.sol-form-decoration form .sv-form-datefield > div:nth-of-type(2)::before {
  position: absolute;
  top: 9px;
  left: 0;
  font-weight: 100;
  font-size: 21px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f017';
}
.sol-form form .sv-form-datefield > div select,
.sol-form-decoration form .sv-form-datefield > div select {
  margin-bottom: 10px;
}
.sol-form form br,
.sol-form-decoration form br {
  display: none;
}
.sol-form form .font-normal,
.sol-form-decoration form .font-normal {
  display: block;
}
.sol-form form .sv-formField,
.sol-form-decoration form .sv-formField {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sol-form form .sv-formField,
  .sol-form-decoration form .sv-formField {
    width: 100%;
    padding: 0;
  }
}
.sol-form form input[type='checkbox'],
.sol-form-decoration form input[type='checkbox'] {
  position: relative;
  top: 0;
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 0;
}
.sol-form form input[type='checkbox'] + label,
.sol-form-decoration form input[type='checkbox'] + label {
  position: relative;
  padding-left: 28px;
}
.sol-form form input[type='checkbox'] + label::before,
.sol-form-decoration form input[type='checkbox'] + label::before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: rgba(224, 224, 224, 0.3);
  border: 1px solid #9e9e9e;
  content: '';
}
.sol-form form input[type='checkbox']:checked + label::before,
.sol-form-decoration form input[type='checkbox']:checked + label::before {
  color: #0071BB;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Font Awesome 5 Pro';
  line-height: 19px;
  text-align: center;
  content: '\f00c';
}
.sol-form form input[type='radio'],
.sol-form-decoration form input[type='radio'] {
  position: relative;
  top: 0;
  display: none;
  width: 18px;
  height: 18px;
  border-radius: 0;
}
.sol-form form input[type='radio'] + label,
.sol-form-decoration form input[type='radio'] + label {
  position: relative;
  padding-left: 28px;
}
.sol-form form input[type='radio'] + label::before,
.sol-form-decoration form input[type='radio'] + label::before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: rgba(224, 224, 224, 0.3);
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  content: '';
}
.sol-form form input[type='radio']:checked + label::before,
.sol-form-decoration form input[type='radio']:checked + label::before {
  color: #0071BB;
  font-weight: 600;
  font-size: 19px;
  font-family: 'Font Awesome 5 Pro';
  line-height: 19px;
  text-align: center;
  content: '\f192';
}
.sol-form form button[type='submit'],
.sol-form-decoration form button[type='submit'],
.sol-form form input[type='submit'],
.sol-form-decoration form input[type='submit'] {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
  margin-top: 20px;
  padding-right: 25px;
  padding-left: 25px;
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  border-radius: 0;
}
.sol-form form button[type='submit']:focus,
.sol-form-decoration form button[type='submit']:focus,
.sol-form form input[type='submit']:focus,
.sol-form-decoration form input[type='submit']:focus,
[data-whatinput='keyboard'] .sol-form form button[type='submit']:focus,
[data-whatinput='keyboard'] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput='keyboard'] .sol-form form input[type='submit']:focus,
[data-whatinput='keyboard'] .sol-form-decoration form input[type='submit']:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-form form button[type='submit']:focus,
[data-whatinput='initial'] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput='initial'] .sol-form form input[type='submit']:focus,
[data-whatinput='initial'] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput='mouse'] .sol-form form button[type='submit']:focus,
[data-whatinput='mouse'] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput='mouse'] .sol-form form input[type='submit']:focus,
[data-whatinput='mouse'] .sol-form-decoration form input[type='submit']:focus,
[data-whatinput='touch'] .sol-form form button[type='submit']:focus,
[data-whatinput='touch'] .sol-form-decoration form button[type='submit']:focus,
[data-whatinput='touch'] .sol-form form input[type='submit']:focus,
[data-whatinput='touch'] .sol-form-decoration form input[type='submit']:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-form form button[type='submit']:hover,
.sol-form-decoration form button[type='submit']:hover,
.sol-form form input[type='submit']:hover,
.sol-form-decoration form input[type='submit']:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.sol-form form button[type='submit']:active,
.sol-form-decoration form button[type='submit']:active,
.sol-form form input[type='submit']:active,
.sol-form-decoration form input[type='submit']:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.sol-form form button[type='submit'] i.fa,
.sol-form-decoration form button[type='submit'] i.fa,
.sol-form form input[type='submit'] i.fa,
.sol-form-decoration form input[type='submit'] i.fa,
.sol-form form button[type='submit'] i.fal,
.sol-form-decoration form button[type='submit'] i.fal,
.sol-form form input[type='submit'] i.fal,
.sol-form-decoration form input[type='submit'] i.fal,
.sol-form form button[type='submit'] i.far,
.sol-form-decoration form button[type='submit'] i.far,
.sol-form form input[type='submit'] i.far,
.sol-form-decoration form input[type='submit'] i.far,
.sol-form form button[type='submit'] i.fas,
.sol-form-decoration form button[type='submit'] i.fas,
.sol-form form input[type='submit'] i.fas,
.sol-form-decoration form input[type='submit'] i.fas,
.sol-form form button[type='submit'] i.fab,
.sol-form-decoration form button[type='submit'] i.fab,
.sol-form form input[type='submit'] i.fab,
.sol-form-decoration form input[type='submit'] i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.sol-form form button[type='submit']:hover,
.sol-form-decoration form button[type='submit']:hover,
.sol-form form input[type='submit']:hover,
.sol-form-decoration form input[type='submit']:hover {
  color: #fff;
  text-decoration: underline;
  background-color: #0090ee;
  border: 1px solid #0090ee;
}
.sol-form form button[type='submit'] > i.fa,
.sol-form-decoration form button[type='submit'] > i.fa,
.sol-form form input[type='submit'] > i.fa,
.sol-form-decoration form input[type='submit'] > i.fa,
.sol-form form button[type='submit'] > i.fal,
.sol-form-decoration form button[type='submit'] > i.fal,
.sol-form form input[type='submit'] > i.fal,
.sol-form-decoration form input[type='submit'] > i.fal,
.sol-form form button[type='submit'] > i.far,
.sol-form-decoration form button[type='submit'] > i.far,
.sol-form form input[type='submit'] > i.far,
.sol-form-decoration form input[type='submit'] > i.far,
.sol-form form button[type='submit'] > i.fas,
.sol-form-decoration form button[type='submit'] > i.fas,
.sol-form form input[type='submit'] > i.fas,
.sol-form-decoration form input[type='submit'] > i.fas,
.sol-form form button[type='submit'] > i.fab,
.sol-form-decoration form button[type='submit'] > i.fab,
.sol-form form input[type='submit'] > i.fab,
.sol-form-decoration form input[type='submit'] > i.fab {
  margin-right: 11px;
  margin-left: -15px;
}
.sol-form form .sol-form-divider,
.sol-form-decoration form .sol-form-divider {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #d8d8d8;
}
.sv-form-portlet input[type='checkbox'] {
  position: relative;
  top: 4px;
  width: 20px;
  height: 20px;
}
.sv-form-portlet input[type='radio'] {
  position: relative;
  top: 4px;
  width: 20px;
  height: 20px;
}
.sv-form-portlet button[type='submit'],
.sv-form-portlet input[type='submit'] {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, color, border;
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.sv-form-portlet button[type='submit']:focus,
.sv-form-portlet input[type='submit']:focus,
[data-whatinput='keyboard'] .sv-form-portlet button[type='submit']:focus,
[data-whatinput='keyboard'] .sv-form-portlet input[type='submit']:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sv-form-portlet button[type='submit']:focus,
[data-whatinput='initial'] .sv-form-portlet input[type='submit']:focus,
[data-whatinput='mouse'] .sv-form-portlet button[type='submit']:focus,
[data-whatinput='mouse'] .sv-form-portlet input[type='submit']:focus,
[data-whatinput='touch'] .sv-form-portlet button[type='submit']:focus,
[data-whatinput='touch'] .sv-form-portlet input[type='submit']:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sv-form-portlet button[type='submit']:hover,
.sv-form-portlet input[type='submit']:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
}
.sv-form-portlet button[type='submit']:active,
.sv-form-portlet input[type='submit']:active {
  top: 1px;
  box-shadow: inset 0 -1px 0 0 #fff;
}
.sv-form-portlet button[type='submit'] i.fa,
.sv-form-portlet input[type='submit'] i.fa,
.sv-form-portlet button[type='submit'] i.fal,
.sv-form-portlet input[type='submit'] i.fal,
.sv-form-portlet button[type='submit'] i.far,
.sv-form-portlet input[type='submit'] i.far,
.sv-form-portlet button[type='submit'] i.fas,
.sv-form-portlet input[type='submit'] i.fas,
.sv-form-portlet button[type='submit'] i.fab,
.sv-form-portlet input[type='submit'] i.fab {
  margin-right: 10px;
  margin-left: -5px;
  font-weight: 400;
}
.sv-form-portlet button[type='submit']:hover,
.sv-form-portlet input[type='submit']:hover {
  color: #fff;
  text-decoration: underline;
  background-color: #0090ee;
  border: 1px solid #0090ee;
}
.sol-form form .sv-form-singleselectionfield label,
.sol-form-decoration form .sv-form-singleselectionfield label,
.sol-form form .sv-form-multipleselectionfield label,
.sol-form-decoration form .sv-form-multipleselectionfield label {
  display: inline-block;
  width: calc(100% - 33px);
  vertical-align: top;
}
.sol-widget-decoration-wrapper a {
  text-decoration: none;
}
.sol-widget-decoration {
  overflow: hidden;
  text-decoration: none;
  background-color: #0071BB;
}
.sol-widget-decoration .sol-widget-decoration-img {
  position: relative;
  height: 235px;
  background-position: 50% 50%;
  background-size: cover;
  border-bottom: 2px solid #fff;
}
@media (max-width: 991px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    height: 175px;
  }
}
@media (max-width: 767px) {
  .sol-widget-decoration .sol-widget-decoration-img {
    display: none;
    height: 98px;
  }
  .sol-widget-decoration .sol-widget-decoration-img.keep-img {
    display: block;
  }
}
.sol-widget-decoration .sol-widget-decoration-img::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0071BB;
  opacity: 0%;
  content: '';
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: opacity;
}
.sol-widget-decoration .sv-text-portlet {
  position: relative;
  max-height: 175px;
  padding: 5px 25px 5px 12px;
  font-size: 13px;
  line-height: 20px;
  background-color: #0071BB;
}
.sol-widget-decoration .font-widget-heading {
  display: block;
  margin-top: 12px;
  margin-bottom: 14px;
  font-weight: 300;
  font-size: 22px;
  font-family: 'Roboto', sans-serif;
  line-height: 30px;
  letter-spacing: 0.5px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
.sol-widget-decoration .font-widget-heading a {
  position: relative;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
}
.sol-widget-decoration .font-widget-heading a[rel='external'] img {
  display: none;
}
.sol-widget-decoration .font-widget-heading a[rel='external']::after {
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  font-size: 16px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f35d';
}
@media (max-width: 767px) {
  .sol-widget-decoration .font-widget-heading a[rel='external']::after {
    top: -4px;
  }
}
@media (max-width: 991px) {
  .sol-widget-decoration .font-widget-heading {
    font-size: 19px;
  }
}
@media (max-width: 767px) {
  .sol-widget-decoration .font-widget-heading {
    font-size: 18px;
  }
}
.sol-widget-decoration .font-normal {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.4em;
}
.sol-widget-decoration:hover .sol-widget-decoration-img::before {
  opacity: 30%;
}
.sol-widget-decoration:hover .font-widget-heading a {
  color: #fff;
  text-decoration: underline;
}
.sol-widget-decoration:hover .sv-text-portlet {
  background-color: #0071BB;
}
.sol-cookie-message {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 290px;
  padding: 8px 10px 8px 14px;
  color: #fff;
  font-size: 13px;
  background-color: #3d3d3d;
  border-top-left-radius: 3px;
}
.sol-cookie-message::after,
.sol-cookie-message::before {
  display: table;
  content: '';
}
.sol-cookie-message::after {
  clear: both;
}
.sol-cookie-message a {
  color: #fff;
  text-decoration: underline;
}
.sol-cookie-message .sol-cookie-ok {
  background: none;
  border: none;
  appearance: none;
  display: inline-block;
  float: right;
  margin-top: 3px;
  padding: 0 7px;
  color: #0071BB;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  background-color: #fff;
  border-radius: 2px;
  cursor: pointer;
}
.sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput='keyboard'] .sol-cookie-message .sol-cookie-ok:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput='mouse'] .sol-cookie-message .sol-cookie-ok:focus,
[data-whatinput='touch'] .sol-cookie-message .sol-cookie-ok:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-editor-bottom {
  bottom: 60px !important;
}
footer .sol-bottom {
  padding: 80px 0 70px;
  background-color: #0071BB;
}
@media (max-width: 767px) {
  footer .sol-bottom {
    padding: 40px 20px 30px;
  }
}
footer .sol-bottom .sv-grid-main .sv-row > .sv-column-4 {
  padding-right: 30px;
}
@media (max-width: 991px) {
  footer .sol-bottom .sv-grid-main .sv-row > .sv-column-4 {
    padding-right: 20px;
  }
}
footer .sol-bottom h1.font-footer-header,
footer .sol-bottom p.font-footer-header {
  margin-top: 0;
  margin-bottom: 28px;
  padding-bottom: 25px;
  color: #fff;
  font-weight: 300;
  font-size: 28px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  border-bottom: 2px solid #fff;
}
footer .sol-bottom .sol-footer-contact-item,
footer .sol-bottom .sol-footer-links-item {
  position: relative;
  display: block;
  margin-bottom: 10px;
  padding-left: 0;
  color: #fff;
  font-size: 18px;
  white-space: nowrap;
  text-decoration: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: opacity;
}
footer .sol-bottom .sol-footer-contact-item i.fa-chevron-right,
footer .sol-bottom .sol-footer-links-item i.fa-chevron-right {
  width: 30px;
  height: 31px;
  margin-right: 12px;
  padding-top: 5px;
  padding-left: 2px;
  color: #fff;
  font-size: 19px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #fff;
  border-radius: 50%;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-footer-contact-item i.fa-chevron-right,
  footer .sol-bottom .sol-footer-links-item i.fa-chevron-right {
    display: inline-block;
    vertical-align: top;
  }
}
footer .sol-bottom .sol-footer-contact-item i.fa-external-link,
footer .sol-bottom .sol-footer-links-item i.fa-external-link {
  position: relative;
  top: -5px;
  right: 0;
}
footer .sol-bottom .sol-footer-contact-item span,
footer .sol-bottom .sol-footer-links-item span {
  padding-top: 5px;
  vertical-align: middle;
}
@media (max-width: 991px) {
  footer .sol-bottom .sol-footer-contact-item span,
  footer .sol-bottom .sol-footer-links-item span {
    display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
footer .sol-bottom .sol-footer-contact-item a,
footer .sol-bottom .sol-footer-links-item a,
footer .sol-bottom .sol-footer-contact-item p,
footer .sol-bottom .sol-footer-links-item p {
  display: block;
  margin: 0;
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
}
footer .sol-bottom .sol-footer-contact-item i.fal.fa-info-circle,
footer .sol-bottom .sol-footer-links-item i.fal.fa-info-circle {
  top: -3px;
}
footer .sol-bottom .sol-footer-contact-item i.fal.fa-phone,
footer .sol-bottom .sol-footer-links-item i.fal.fa-phone {
  top: -3px;
  transform: rotate(120deg);
}
footer .sol-bottom .sol-footer-contact a:hover,
footer .sol-bottom .sol-footer-links a:hover {
  text-decoration: underline;
  opacity: 70%;
}
footer .sol-bottom .sol-footer-contact a {
  text-decoration: underline;
}
footer .sol-bottom .sol-footer-contact-item {
  margin-bottom: 25px;
}
footer .sol-bottom .sol-footer-contact-item:nth-child(3),
footer .sol-bottom .sol-footer-contact-item:nth-child(3) {
  margin: 30px 0;
}
footer .sol-bottom .sol-social-media::after,
footer .sol-bottom .sol-social-media::before {
  display: table;
  content: '';
}
footer .sol-bottom .sol-social-media::after {
  clear: both;
}
footer .sol-bottom .sol-social-media .sol-social-media-item > a {
  float: left;
  width: 55px;
  height: 55px;
  margin-right: 12px;
  color: #fff;
  font-size: 32px;
  line-height: 55px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: opacity;
}
footer .sol-bottom .sol-social-media .sol-social-media-item > a:hover {
  color: #0071BB;
  background-color: #fff;
}
footer p.font-footer-text {
  margin-bottom: 11px;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
}
footer p.font-footer-text a:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: underline;
}
.sol-shortcut-container {
  padding: 20px 0;
  background-color: rgba(0, 113, 187, 0.1);
}
@media (max-width: 767px) {
  .sol-shortcut-container {
    padding: 0;
  }
}
.sol-shortcut-container .sv-grid-main {
  padding-right: 0;
  padding-left: 0;
}
.sol-shortcut {
  margin: 10px 40px;
}
@media (max-width: 991px) {
  .sol-shortcut {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 30px 0;
  }
}
@media (max-width: 1024px) {
  .sol-shortcut {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px 0 0 0;
  }
}
.sol-shortcut a {
  text-decoration: none;
  max-width: 150px;
  display: inline-block;
}
.sol-shortcut-container::after,
.sol-shortcut-container::before {
  display: table;
  content: '';
}
.sol-shortcut-container::after {
  clear: both;
}
.sol-shortcut-item {
  display: inline-block;
  float: left;
  height: 110px;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid #cfcfcf;
}
.sol-shortcut-item:last-of-type {
  border-right: none;
}
@media (max-width: 1024px) {
  .sol-shortcut-item {
    width: 33% !important;
    margin-bottom: 0px;
    height: 170px;
    padding-bottom: 20px;
    padding-top: 25px;
    border-bottom: 1px solid #cfcfcf;
  }
  .sol-shortcut-item a {
    height: 100%;
  }
  .sol-shortcut-item:nth-child(3) {
    border-right: none;
  }
  .sol-shortcut-item:nth-child(4),
  .sol-shortcut-item:nth-child(5),
  .sol-shortcut-item:nth-child(6) {
    border-bottom: none;
  }
}
@media (max-width: 479px) {
  .sol-shortcut-item {
    width: calc(50% - 1px) !important;
    margin: auto;
    height: 154px;
    padding-top: 15px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @supports (overflow:-webkit-marquee) and (justify-content:inherit) {
    .sol-shortcut-item:first-of-type {
      margin-left: -0.5px;
    }
  }
  .sol-shortcut-item:nth-child(odd):last-child {
    border: none;
  }
  .sol-shortcut-item:nth-child(3) {
    border-right: 1px solid #cfcfcf;
  }
  .sol-shortcut-item:nth-child(4) {
    border-bottom: 1px solid #cfcfcf;
  }
  .sol-shortcut-item:nth-child(2),
  .sol-shortcut-item:nth-child(4) {
    border-right: none;
  }
  .sol-shortcut-item:nth-child(5),
  .sol-shortcut-item:nth-child(6) {
    border-bottom: none;
  }
  .sol-shortcut-item:nth-child(2),
  .sol-shortcut-item:nth-child(4),
  .sol-shortcut-item:nth-child(6) {
    width: 50% !important;
  }
}
.sol-shortcut-item a {
  display: block;
  width: 140px;
  height: 100%;
  margin: 0 auto 10px;
  letter-spacing: 0.5px;
  background-color: transparent;
  padding-top: 20px;
  color: #2c2c2c;
  font-size: 21px;
  text-align: center;
  line-height: 54px;
  transition-property: color, text-decoration;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.sol-shortcut-item a:focus,
.sol-shortcut-item a:hover {
  color: #0071BB;
  cursor: pointer;
}
.sol-shortcut-item a:focus i,
.sol-shortcut-item a:hover i {
  color: #0071BB;
}
.sol-shortcut-item a:focus span,
.sol-shortcut-item a:hover span {
  color: #0071BB;
  text-decoration: underline;
}
.sol-shortcut-item a i.fa,
.sol-shortcut-item a i.fal,
.sol-shortcut-item a i.far,
.sol-shortcut-item a i.fas,
.sol-shortcut-item a i.fab {
  font-size: 40px;
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.sol-shortcut-item span {
  display: block;
  color: #2c2c2c;
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  text-transform: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
header {
  position: relative;
  z-index: 50;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 991px) {
  header.sol-original-header {
    min-height: 105px;
  }
}
@media (max-width: 767px) {
  header.sol-original-header {
    min-height: 0;
  }
}
header.sol-original-header .sol-bottom {
  border-bottom: 1px solid #e5f0f8;
}
@media (max-width: 767px) {
  header {
    position: relative;
    margin-bottom: 0;
  }
}
header .sol-top-menu > .sv-grid-main {
  max-width: 1255px;
  padding: 0;
}
header .sol-top-search {
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color, height;
  position: relative;
  display: none;
  height: 0;
  overflow: hidden;
  background-color: #0071BB;
}
header .sol-top-search.sol-active {
  height: 115px;
  background-color: #e5f0f8;
}
@media (max-width: 991px) {
  header .sol-top-search.sol-active {
    height: 100px;
  }
}
@media (max-width: 767px) {
  header .sol-top-search.sol-active {
    position: absolute;
    top: 64px;
    z-index: 99999;
    width: 100%;
    max-height: 120px;
    box-shadow: 0 0 8px 0 rgb(0 0 0%);
    transition: none;
  }
}
header .sol-top-search form {
  position: relative;
  display: block;
  width: 80%;
  max-width: 700px;
  margin: 35px auto;
}
@media (max-width: 991px) {
  header .sol-top-search form {
    width: 70%;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  header .sol-top-search form {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
@media (max-width: 479px) {
  header .sol-top-search form {
    width: 90%;
  }
}
header .sol-top-search form button[type='submit'] {
  background-color: #0071BB;
}
header .sol-top-search form div {
  border: 1px solid transparent;
}
header .sol-top-search form div:hover {
  border: 1px solid #0071BB;
}
header .sol-top-search form div:hover button[type='submit'] {
  background-color: #e5f0f8;
  border: 1px solid #0071BB;
}
header .sol-top-search form div:hover button[type='submit'] i {
  color: #0071BB;
}
@media (max-width: 767px) {
  header .sol-top-search form div:hover button[type='submit'] i {
    color: #fff !important;
  }
}
@media (max-width: 767px) {
  header .sol-top-search form div:hover button[type='submit'] {
    background-color: #0071BB;
  }
}
header .sol-top-search-close {
  background: none;
  border: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
  position: absolute;
  top: 12px;
  left: 102%;
  padding: 10px 20px;
  color: #fff;
  text-decoration: underline;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  cursor: pointer;
}
header .sol-top-search-close:focus,
[data-whatinput='keyboard'] header .sol-top-search-close:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] header .sol-top-search-close:focus,
[data-whatinput='mouse'] header .sol-top-search-close:focus,
[data-whatinput='touch'] header .sol-top-search-close:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  header .sol-top-search-close {
    top: 15px;
    left: 102%;
    display: block;
  }
}
@media (max-width: 767px) {
  header .sol-top-search-close {
    top: 45px;
    right: calc(50% - 33px);
    left: unset;
    display: block;
    padding: 9px 20px;
  }
}
header .sol-top-search-close:hover {
  color: #0071BB;
  background-color: #e5f0f8;
}
header .sol-top {
  position: relative;
  background: #0071BB;
}
@media (max-width: 767px) {
  header .sol-top {
    top: 0;
    height: 64px;
    padding-top: 13px;
    padding-right: 0;
    padding-bottom: 8px;
    padding-left: 0;
    background-color: #0071BB;
    border-bottom: 2px solid #e5f0f8;
  }
}
@media (max-width: 479px) {
  header .sol-top > .sv-grid-main {
    padding-right: 0;
  }
}
header .sol-top > .sv-grid-main {
  display: flex;
  justify-content: flex-end;
}
header .sol-top > .sv-grid-main > div:first-child {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-between;
}
@media (max-width: 767px) {
  header .sol-top > .sv-grid-main > div:first-child {
    justify-content: flex-end;
  }
}
header .sol-top > .sv-grid-main > div:first-child #ToolmenuSkript {
  display: none;
}
header .sol-top > .sv-grid-main > * {
  flex: 0 0 auto;
}
header .sol-top .sol-top-logo {
  height: 50px;
}
header .sol-top .sol-top-logo > img {
  width: auto;
  height: 50px;
  color: #fff;
}
@media (max-width: 767px) {
  header .sol-top .sol-top-logo {
    display: none;
  }
  header .sol-top .sol-top-logo > img {
    height: 32px;
  }
}
header .sol-bottom {
  position: relative;
  top: 50px;
  text-align: center;
  background-color: #fff;
}
@media (max-width: 991px) {
  header .sol-bottom {
    display: none;
  }
}
@media (max-width: 767px) {
  header .sol-bottom {
    background-color: #0071BB;
  }
}
.sol-to-content {
  position: absolute;
  top: -3px;
  right: 100%;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-left: 100%;
  overflow: hidden;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  text-align: center;
  background-color: #0071BB;
}
.sol-to-content:focus {
  position: relative;
  width: auto;
  height: auto;
}
@media (max-width: 767px) {
  .sol-to-content:focus {
    position: static;
    display: none;
    width: 100%;
    margin-bottom: 10px;
  }
}
.sol-header {
  min-height: 106px;
}
@media (max-width: 767px) {
  .sol-header {
    min-height: 64px;
  }
}
.sol-header .sol-top {
  min-height: 106px;
  padding-top: 28px;
}
.sol-header--transparent {
  background-color: #0071BB;
  border-bottom: 3px solid #fff;
}
@media (max-width: 767px) {
  .sol-header--transparent {
    border-bottom: none;
  }
}
.sol-header--transparent .sol-top {
  top: 0;
  height: 55px;
  min-height: 55px;
  background-color: transparent;
}
@media (max-width: 767px) {
  .sol-header {
    background-color: #0071BB;
  }
  .sol-header .sol-top {
    top: 0;
    min-height: 64px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.sol-info-container {
  position: relative;
  padding: 10px 0;
  color: #2c2c2c;
  background-color: #e9f1f8;
  transition-timing-function: linear;
  transition-duration: 150ms;
  transition-property: margin-top;
}
.sol-info-container .sv-row {
  position: relative;
  display: inline-block;
  margin-bottom: 2px !important;
  padding: 25px 25px 20px 155px;
}
@media (max-width: 991px) {
  .sol-info-container .sv-row {
    padding: 20px 20px 20px 115px;
  }
}
@media (max-width: 767px) {
  .sol-info-container .sv-row {
    padding: 20px 20px 20px 60px;
  }
}
.sol-info-container .sv-row > a {
  position: relative;
  width: 100%;
  text-decoration: none;
}
.sol-info-container .sv-row > a:not(:first-child) {
  margin-top: 20px;
}
.sol-info-container .sv-row > a:hover {
  text-decoration: underline;
  opacity: 70%;
  transition: opacity 300ms ease;
}
@media (max-width: 767px) {
  .sol-info-container .sv-row > a {
    display: block;
  }
}
.sol-info-container .sv-row > a > i.fa-chevron-right {
  position: absolute;
  top: 50%;
  left: -140px;
  width: 100px;
  height: 100px;
  margin-top: -55px;
  color: #fff;
  font-size: 70px;
  line-height: 100px;
  text-align: center;
  vertical-align: middle;
  background-color: #0071BB;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .sol-info-container .sv-row > a > i.fa-chevron-right {
    top: 20px;
    left: -85px;
    width: 50px;
    height: 50px;
    margin-top: 0;
    font-size: 30px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .sol-info-container .sv-row > a > i.fa-chevron-right {
    left: -42px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 30px;
  }
}
.sol-info-container .sol-info-message::after,
.sol-info-container .sol-info-message::before {
  display: table;
  content: '';
}
.sol-info-container .sol-info-message::after {
  clear: both;
}
.sol-info-container .sol-info-message-title {
  display: block;
  margin-bottom: 10px;
  color: #0071BB;
  font-weight: 400;
  font-size: 25px;
  line-height: 28px;
  text-align: left;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-info-container .sol-info-message-title {
    margin-bottom: 6px;
    font-size: 21px;
    line-height: 24px;
  }
}
.sol-info-container .sol-info-message-desc {
  color: #2c2c2c;
  font-weight: 400;
  line-height: 1.4em;
}
@media (max-width: 991px) {
  .sol-info-container .sol-info-message-desc {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .sol-info-container .sol-info-message-desc {
    font-size: 14px;
    line-height: 20px;
  }
}
.sol-info-container .sol-info-message-more {
  display: block;
  text-align: left;
}
.sol-info-container .sol-info-message-more span {
  margin-right: 10px;
  color: #0071BB;
  font-weight: 600;
  text-decoration: none;
  background-color: transparent;
  transition-timing-function: ease;
  transition-duration: color;
  transition-property: background-color;
}
@media (max-width: 767px) {
  .sol-info-container .sol-info-message-more span {
    font-weight: 400;
    font-size: 14px;
  }
}
.sol-info-container .sol-info-message-more span .fa-chevron-right {
  margin-left: 5px;
}
.sol-info-container .sv-grid-main.sv-fluid-grid .sv-row {
  justify-content: flex-start;
}
.sol-mobile-header {
  display: none;
  text-align: center;
  background-color: transparent;
}
@media (max-width: 991px) {
  .sol-mobile-header {
    position: absolute;
    top: 84px;
    display: block;
    width: 100%;
    height: 0;
  }
  .sol-mobile-header.sol-hide-md {
    display: none;
  }
}
@media (max-width: 767px) {
  .sol-mobile-header {
    top: 65px;
  }
  .sol-mobile-header.sol-show-sm {
    display: block;
  }
}
.sol-mobile-header > div {
  position: relative;
  height: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-mobile-header .sol-mobile-logo {
    display: none;
  }
}
.sol-mobile-header .sol-mobile-logo {
  float: left;
  max-width: 110px;
  max-height: 35px;
  margin-top: -115px;
  margin-left: 12px;
}
.sol-mobile-header .sol-menu-toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 55px;
  height: 55px;
  padding-top: 35px;
  color: #fff;
  font-size: 12px !important;
  text-align: center;
  background-color: #0071BB;
  border: 0;
}
.sol-mobile-header .sol-menu-toggle-button:focus,
[data-whatinput='keyboard'] .sol-mobile-header .sol-menu-toggle-button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-mobile-header .sol-menu-toggle-button:focus,
[data-whatinput='mouse'] .sol-mobile-header .sol-menu-toggle-button:focus,
[data-whatinput='touch'] .sol-mobile-header .sol-menu-toggle-button:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-mobile-header .sol-menu-toggle-button {
    top: 22px;
  }
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-menu-toggle-button {
    top: unset;
    bottom: -54px;
  }
}
.sol-mobile-header .sol-menu-toggle-button--active {
  position: fixed;
  z-index: 99999 !important;
  background-color: #0071BB;
}
@media (max-width: 991px) {
  .sol-mobile-header .sol-menu-toggle-button--active {
    top: 0;
    right: 0;
  }
}
.sol-mobile-header .sol-menu-toggle-button--active .sol-hamburger {
  width: 65px !important;
  height: 65px;
  margin-top: -18px;
  margin-left: -36px !important;
  padding-top: 18px;
  padding-left: 18px;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger {
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 9999;
  width: 35px;
  margin-left: -18px;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger > span {
  display: block;
  width: 35px;
  height: 3px;
  background-color: #fff;
  border-radius: 2px;
  transition: transform 200ms;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger .sol-hamburger__middle {
  margin-top: 6px;
  margin-bottom: 6px;
  transition: width 200ms;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger .sol-hamburger__bottom {
  margin-bottom: 5px;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger.sol-hamburger--active > span {
  margin-left: 2px;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger.sol-hamburger--active .sol-hamburger__top {
  width: 37px;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(7.5px) translateZ(0);
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger.sol-hamburger--active .sol-hamburger__middle {
  width: 0;
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger.sol-hamburger--active .sol-hamburger__bottom {
  width: 37px;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(5.5px) translateY(-7.5px) translateZ(0);
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-menu-toggle-button .sol-hamburger {
    right: 10px;
  }
}
.sol-mobile-header .sol-menu-toggle-button .sol-hamburger-text {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
}
@media (min-width: 767px) and (max-width: 991px) {
  header .sol-mobile-header .sol-menu-toggle-button {
    display: none;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  header.sol-original-header .sol-mobile-header .sol-menu-toggle-button {
    display: block;
  }
}
.rsbtn {
  display: inline-block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rsbtn {
    /* IE10+ CSS styles go here */
    position: relative;
    top: 8px;
  }
}
.rsbtn a.rsbtn_play {
  background: none;
  border: none;
  appearance: none;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  display: inline-block;
  font-size: 15px;
  display: inline-table;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 30px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  cursor: pointer;
}
.rsbtn a.rsbtn_play:focus,
[data-whatinput='keyboard'] .rsbtn a.rsbtn_play:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .rsbtn a.rsbtn_play:focus,
[data-whatinput='mouse'] .rsbtn a.rsbtn_play:focus,
[data-whatinput='touch'] .rsbtn a.rsbtn_play:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.rsbtn a.rsbtn_play.fa-pull-left {
  margin-right: 0.3em;
}
.rsbtn a.rsbtn_play.fa-pull-right {
  margin-left: 0.3em;
}
.rsbtn a.rsbtn_play::before {
  content: "\f028";
}
@media (max-width: 767px) {
  .rsbtn a.rsbtn_play {
    margin: 0 0 9px;
    color: #fff;
    font-size: 15px;
  }
}
@media (max-width: 479px) {
  .rsbtn a.rsbtn_play {
    margin: 0 0 3px;
    font-size: 13px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rsbtn a.rsbtn_play {
    /* IE10+ CSS styles go here */
    position: relative;
    top: -14px;
  }
}
.rsbtn a.rsbtn_play::before {
  position: relative;
  top: -4px;
  display: block;
  margin-right: 5px;
  margin-bottom: 8px;
  font-weight: 100;
  font-size: 24px;
  font-family: 'Font Awesome 5 Pro';
  text-align: center;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rsbtn a.rsbtn_play::before {
    /* IE10+ CSS styles go here */
    top: -10px;
  }
}
@media (max-width: 767px) {
  .rsbtn a.rsbtn_play::before {
    top: -5px;
    font-size: 21px;
  }
}
@media (max-width: 479px) {
  .rsbtn a.rsbtn_play::before {
    top: -5px;
    font-size: 18px;
  }
}
.rsbtn a.rsbtn_play .rsbtn_left {
  position: relative;
  top: -2px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rsbtn a.rsbtn_play .rsbtn_left {
    /* IE10+ CSS styles go here */
    top: -5px;
  }
}
@media (max-width: 767px) {
  .rsbtn a.rsbtn_play .rsbtn_left {
    top: -8px;
  }
}
@media (max-width: 479px) {
  .rsbtn a.rsbtn_play .rsbtn_left {
    top: -9px;
  }
}
.sol-mobile-menu-wrapper {
  position: relative;
  display: none;
}
@media (max-width: 991px) {
  .sol-mobile-menu-wrapper {
    display: block;
  }
}
.sol-mobile-menu-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 49;
  display: none;
  height: 0;
  min-height: 0;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 3px 14px 0 rgb(0 0 0%);
  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: height, min-height;
}
@media (max-width: 991px) {
  .sol-mobile-menu-container.sol-active {
    display: block;
  }
}
.sol-mobile-menu-container > ul {
  border-top: 1px solid #e0e0e0;
}
.sol-mobile-menu-container .sol-menu-list {
  width: 100%;
}
.sol-mobile-menu-container .sol-menu-list-expanded-init {
  display: block;
}
.sol-mobile-menu-container .sol-menu-item {
  position: relative;
}
.sol-mobile-menu-container .sol-menu-item-link {
  position: relative;
  display: block;
  margin-right: 1px;
  margin-left: 1px;
  padding: 10px 49px 10px 19px;
  color: #0071bb;
  text-decoration: none;
  border-bottom: 1px solid #e0e0e0;
}
.sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput='keyboard'] .sol-mobile-menu-container .sol-menu-item-link:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput='mouse'] .sol-mobile-menu-container .sol-menu-item-link:focus,
[data-whatinput='touch'] .sol-mobile-menu-container .sol-menu-item-link:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-mobile-menu-container .sol-menu-item-link .sol-menu-item-display-name {
  font-weight: 400;
  font-size: 14px;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button {
  background: none;
  border: none;
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 43px;
  border-left: 1px solid #e0e0e0;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='keyboard'] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='mouse'] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='touch'] .sol-mobile-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-mobile-menu-container .sol-menu-item .sol-menu-button i {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding-top: 3px;
  padding-left: 3px;
  text-align: center;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: all;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='1'] .sol-menu-item-link {
  padding-left: 30px;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='2'] .sol-menu-item-link {
  padding-left: 40px;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='3'] .sol-menu-item-link {
  padding-left: 50px;
}
.sol-mobile-menu-container .sol-menu-item[data-menu-level='4'] .sol-menu-item-link {
  padding-left: 60px;
}
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.fa,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.fal,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.fas,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.fab,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'] > .sol-menu-button i.far {
  transform: rotate(90deg);
}
.sol-mobile-menu-container .sol-menu-item[data-node-type='sv:folder'] > .sol-menu-button {
  width: 100%;
  border-left: none;
}
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-item-link,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-item-link {
  color: #fff;
  background-color: #0071bb;
}
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-button i.fa,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-button i.fa,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-button i.fal,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-button i.fal,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-button i.fas,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-button i.fas,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-button i.fab,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-button i.fab,
.sol-mobile-menu-container .sol-menu-item[data-is-current='true'] > .sol-menu-button i.far,
.sol-mobile-menu-container .sol-menu-item[data-expanded='true'][data-has-children='false'] > .sol-menu-button i.far {
  color: white;
}
.sol-tool-nav {
  position: relative;
  display: flex;
}
.sol-tool-nav-item > a,
.sol-tool-nav-item > button {
  background: none;
  border: none;
  appearance: none;
  display: block;
  margin: 0;
  padding: 2px 16px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.sol-tool-nav-item > a:focus,
.sol-tool-nav-item > button:focus,
[data-whatinput='keyboard'] .sol-tool-nav-item > a:focus,
[data-whatinput='keyboard'] .sol-tool-nav-item > button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-tool-nav-item > a:focus,
[data-whatinput='initial'] .sol-tool-nav-item > button:focus,
[data-whatinput='mouse'] .sol-tool-nav-item > a:focus,
[data-whatinput='mouse'] .sol-tool-nav-item > button:focus,
[data-whatinput='touch'] .sol-tool-nav-item > a:focus,
[data-whatinput='touch'] .sol-tool-nav-item > button:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-tool-nav-item > a:hover span,
.sol-tool-nav-item > button:hover span {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    color: #fff;
    font-size: 13px;
  }
}
@media (max-width: 479px) {
  .sol-tool-nav-item > a,
  .sol-tool-nav-item > button {
    padding: 2px 6px;
  }
}
.sol-tool-nav-item > a i.fa,
.sol-tool-nav-item > button i.fa,
.sol-tool-nav-item > a i.fal,
.sol-tool-nav-item > button i.fal,
.sol-tool-nav-item > a i.fas,
.sol-tool-nav-item > button i.fas,
.sol-tool-nav-item > a i.fab,
.sol-tool-nav-item > button i.fab,
.sol-tool-nav-item > a i.far,
.sol-tool-nav-item > button i.far {
  display: block;
  margin-bottom: 8px;
  font-weight: 100;
  font-size: 24px;
}
@media (max-width: 767px) {
  .sol-tool-nav-item > a i.fa,
  .sol-tool-nav-item > button i.fa,
  .sol-tool-nav-item > a i.fal,
  .sol-tool-nav-item > button i.fal,
  .sol-tool-nav-item > a i.fas,
  .sol-tool-nav-item > button i.fas,
  .sol-tool-nav-item > a i.fab,
  .sol-tool-nav-item > button i.fab,
  .sol-tool-nav-item > a i.far,
  .sol-tool-nav-item > button i.far {
    margin-bottom: 3px;
    font-size: 21px;
  }
}
@media (max-width: 479px) {
  .sol-tool-nav-item > a i.fa,
  .sol-tool-nav-item > button i.fa,
  .sol-tool-nav-item > a i.fal,
  .sol-tool-nav-item > button i.fal,
  .sol-tool-nav-item > a i.fas,
  .sol-tool-nav-item > button i.fas,
  .sol-tool-nav-item > a i.fab,
  .sol-tool-nav-item > button i.fab,
  .sol-tool-nav-item > a i.far,
  .sol-tool-nav-item > button i.far {
    font-size: 18px;
  }
}
.sol-tool-nav-item .sol-close {
  background: none;
  border: none;
  appearance: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  color: #000;
  line-height: 20px;
  text-align: center;
}
.sol-tool-nav-item .sol-close:focus,
[data-whatinput='keyboard'] .sol-tool-nav-item .sol-close:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-tool-nav-item .sol-close:focus,
[data-whatinput='mouse'] .sol-tool-nav-item .sol-close:focus,
[data-whatinput='touch'] .sol-tool-nav-item .sol-close:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-tool-nav-item .sol-close i.fa,
.sol-tool-nav-item .sol-close i.fal,
.sol-tool-nav-item .sol-close i.fas,
.sol-tool-nav-item .sol-close i.fab,
.sol-tool-nav-item .sol-close i.far {
  font-size: 11px;
}
.sol-tool-nav-box {
  position: absolute;
  z-index: 1;
  width: 230px;
  margin-top: 10px;
  margin-left: -60px;
  padding: 17px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 5px 0 rgb(0 0 0%);
  cursor: default;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-tool-nav-box {
    right: 45px;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sol-tool-nav-box {
    left: 50%;
    width: 215px;
    margin-left: -130px;
    padding: 12px;
  }
}
.sol-tool-nav-box a.sol-btn-round {
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 1px;
  color: #0071bb;
  font-size: 15px;
  text-align: center;
  border: 2px solid #0071bb;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .sol-tool-nav-box a.sol-btn-round {
    width: 40px;
    height: 40px;
    padding-top: 0;
    font-size: 21px;
    line-height: 37px;
  }
}
.sol-tool-nav-box a.sol-btn-round:first-child {
  margin-left: 0;
}
.sol-tool-nav-box a.sol-btn-round:last-child {
  margin-right: 0;
}
.sol-tool-nav-box a.sol-btn-round:hover {
  border-color: #9e9e9e;
}
.sol-tool-nav-box-translate {
  padding: 12px 17px 9px;
}
.sol-tool-nav-box-translate a {
  font-size: 16px;
}
.sv-readspeaker_expandingplayer-portlet {
  position: relative;
}
.sv-readspeaker_expandingplayer-portlet > a {
  display: none;
}
.sv-readspeaker_expandingplayer-portlet div[id^='readspeaker_player'].rsexpanded {
  position: absolute;
  right: 0;
  width: auto !important;
  height: auto;
  padding: 17px 17px 22px;
  text-align: center;
  background-color: rgb(255 255 255);
  border-radius: 4px;
  box-shadow: rgb(0 0 0%) 1px 1px 5px 0;
}
#readspeaker_button1:hover a {
  color: #e5f0f8;
}
.sol-header--transparent .sol-tool-nav .sol-js-toggle-search {
  display: none;
}
.sol-header--transparent .sol-tool-nav-item-contact {
  display: block;
}
.sol-translate-disclaimer {
  padding-bottom: 10px;
  font-size: 13px;
}
.sol-top-nav {
  display: flex;
  min-height: 55px;
  vertical-align: top;
}
.sol-top-nav::after,
.sol-top-nav::before {
  display: table;
  content: '';
}
.sol-top-nav::after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-top-nav {
    display: none;
  }
}
.sol-top-nav-item {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  justify-content: center;
  float: left;
  height: 74px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 18px 12px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: color;
  transition-property: background-color;
}
.sol-top-nav-item:focus,
[data-whatinput='keyboard'] .sol-top-nav-item:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-top-nav-item:focus,
[data-whatinput='mouse'] .sol-top-nav-item:focus,
[data-whatinput='touch'] .sol-top-nav-item:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-top-nav-item {
    /* IE10+ CSS styles go here */
    height: 90px;
  }
}
@media (max-width: 767px) {
  .sol-top-nav-item {
    color: #fff;
  }
}
.sol-top-nav-item:hover {
  color: #2c2c2c;
  text-decoration: underline;
  background-color: rgba(0, 113, 187, 0.1);
}
.sol-top-nav-item.sol-active {
  color: #fff;
  background-color: #0071BB;
}
.sol-top-action-button {
  background: none;
  border: none;
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 5px;
  color: #fff;
  font-weight: 900;
  font-size: 11px !important;
  font-family: 'Roboto', sans-serif;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.sol-top-action-button:focus,
[data-whatinput='keyboard'] .sol-top-action-button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: -2px;
}
[data-whatinput='initial'] .sol-top-action-button:focus,
[data-whatinput='mouse'] .sol-top-action-button:focus,
[data-whatinput='touch'] .sol-top-action-button:focus {
  z-index: inherit;
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-top-action-button--active {
  color: #0071BB;
}
@media (max-width: 991px) {
  .sol-top-action-button {
    width: 50px;
    min-width: 0;
    height: 50px;
    min-height: 0;
    font-size: 0 !important;
  }
}
@media (max-width: 380px) {
  .sol-top-action-button {
    width: 40px;
  }
}
.sol-top-action-button i.fa,
.sol-top-action-button i.fal,
.sol-top-action-button i.far,
.sol-top-action-button i.fas,
.sol-top-action-button i.fab {
  display: block;
  width: 100%;
  height: 28px;
  margin-bottom: 6px;
  font-size: 28px;
}
@media (max-width: 991px) {
  .sol-top-action-button i.fa,
  .sol-top-action-button i.fal,
  .sol-top-action-button i.far,
  .sol-top-action-button i.fas,
  .sol-top-action-button i.fab {
    margin-bottom: 0;
  }
}
.sol-top-search-field {
  position: relative;
  /* Change Autocomplete styles in Chrome */
}
.sol-top-search-field input:-webkit-autofill,
.sol-top-search-field input:-webkit-autofill:hover,
.sol-top-search-field input:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #fff inset;
}
.sol-top-search-field input[type='search'],
.sol-top-search-field input[type='text'] {
  background: none;
  border: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border-color;
  width: 100%;
  height: 50px;
  padding: 0 11px;
  padding-right: 85px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 22px;
  background-color: #fff;
}
.sol-top-search-field input[type='search']:focus,
.sol-top-search-field input[type='text']:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media (max-width: 767px) {
  .sol-top-search-field input[type='search'],
  .sol-top-search-field input[type='text'] {
    height: 40px;
    padding-right: 75px;
  }
}
.sol-top-search-field input[type='search']::-ms-clear,
.sol-top-search-field input[type='text']::-ms-clear,
.sol-top-search-field input[type='search']::-ms-reveal,
.sol-top-search-field input[type='text']::-ms-reveal {
  display: none;
}
.sol-top-search-field input[type='search']:focus,
.sol-top-search-field input[type='text']:focus {
  border-color: #e5f0f8;
  box-shadow: 0 0 0 1000px #fff inset;
}
.sol-top-search-field button[type='submit'] {
  background: none;
  appearance: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color;
  position: absolute;
  top: -5px;
  right: 10px;
  width: 60px;
  height: 60px;
  padding-right: 0;
  padding-left: 0;
  color: #fff;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  background-color: #0071BB;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
}
.sol-top-search-field button[type='submit']:focus,
[data-whatinput='keyboard'] .sol-top-search-field button[type='submit']:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-top-search-field button[type='submit']:focus,
[data-whatinput='mouse'] .sol-top-search-field button[type='submit']:focus,
[data-whatinput='touch'] .sol-top-search-field button[type='submit']:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
@media (max-width: 767px) {
  .sol-top-search-field button[type='submit'] {
    top: -6px;
    width: 50px;
    height: 50px;
  }
}
.sol-top-search-field button[type='submit'] > i.fa,
.sol-top-search-field button[type='submit'] > i.fal,
.sol-top-search-field button[type='submit'] > i.fas,
.sol-top-search-field button[type='submit'] > i.fab,
.sol-top-search-field button[type='submit'] > i.far {
  position: relative;
  top: -2px;
}
.sol-top-search-field > div:hover input[type='search'],
.sol-top-search-field > div:hover input[type='text'] {
  border-color: #e5f0f8;
}
.sol-top-search-field > div:hover button {
  color: #0071BB;
  background-color: #e5f0f8;
}
.sol-top-search-field input:-webkit-autofill,
.sol-top-search-field input:-webkit-autofill:hover,
.sol-top-search-field input:-webkit-autofill:focus {
  background-color: #fff !important;
  border: 2px solid white;
}
.sol-crisis-container {
  position: relative;
  padding: 10px 0;
  color: #2c2c2c;
  background-color: #F7C757;
  transition-timing-function: linear;
  transition-duration: 150ms;
  transition-property: margin-top;
}
.sol-crisis-container .sv-row {
  position: relative;
  display: inline-block;
  margin-bottom: 2px !important;
  padding: 25px 25px 20px 155px;
}
@media (max-width: 991px) {
  .sol-crisis-container .sv-row {
    padding: 20px 20px 20px 115px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sv-row {
    padding: 20px 20px 20px 60px;
  }
}
.sol-crisis-container i.fa-exclamation-triangle {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -55px;
  font-size: 100px;
  line-height: 110px;
}
@media (max-width: 991px) {
  .sol-crisis-container i.fa-exclamation-triangle {
    top: 20px;
    left: 30px;
    margin-top: 0;
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container i.fa-exclamation-triangle {
    left: 20px;
    font-size: 25px;
    line-height: 30px;
  }
}
.sol-crisis-container .sol-crisis-message::after,
.sol-crisis-container .sol-crisis-message::before {
  display: table;
  content: '';
}
.sol-crisis-container .sol-crisis-message::after {
  clear: both;
}
.sol-crisis-container .sol-crisis-message-title {
  display: block;
  margin-bottom: 10px;
  color: #2c2c2c;
  font-weight: 700;
  font-size: 25px;
  line-height: 28px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-title {
    margin-bottom: 6px;
    font-size: 21px;
    line-height: 24px;
  }
}
.sol-crisis-container .sol-crisis-message-desc {
  color: #2c2c2c;
  font-weight: 400;
  line-height: 1.4em;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-crisis-message-desc {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-desc {
    font-size: 14px;
    line-height: 20px;
  }
}
.sol-crisis-container .sol-crisis-message-more {
  display: block;
  text-align: left;
}
.sol-crisis-container .sol-crisis-message-more a {
  margin-right: 10px;
  padding: 10px 12px;
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  background-color: #2c2c2c;
  border: 1px solid #2c2c2c;
  border-radius: 0;
  transition-timing-function: ease;
  transition-duration: color;
  transition-property: background-color;
}
@media (max-width: 767px) {
  .sol-crisis-container .sol-crisis-message-more a {
    padding: 6px 10px;
    font-weight: 400;
    font-size: 12px;
  }
}
.sol-crisis-container .sol-crisis-message-more a:hover {
  color: #2c2c2c;
  text-decoration: underline;
  background-color: #fff;
}
.sol-crisis-container .sol-close-crisis {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 10;
  color: #2c2c2c;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-close-crisis {
    font-size: 0;
  }
}
.sol-crisis-container .sol-close-crisis i.fa,
.sol-crisis-container .sol-close-crisis i.fal,
.sol-crisis-container .sol-close-crisis i.fas,
.sol-crisis-container .sol-close-crisis i.fab,
.sol-crisis-container .sol-close-crisis i.far {
  margin-left: 10px;
  font-weight: 700;
  opacity: 100%;
}
@media (max-width: 991px) {
  .sol-crisis-container .sol-close-crisis i.fa,
  .sol-crisis-container .sol-close-crisis i.fal,
  .sol-crisis-container .sol-close-crisis i.fas,
  .sol-crisis-container .sol-close-crisis i.fab,
  .sol-crisis-container .sol-close-crisis i.far {
    font-size: 20px;
  }
}
.sol-crisis-container .sv-grid-main.sv-fluid-grid .sv-row {
  justify-content: flex-start;
}
.sol-no-script i.fa-exclamation-triangle {
  margin-top: -57px;
  font-size: 50px;
}
.sol-no-script .sv-row {
  padding: 15px 20px 0 90px;
}
.sol-no-script .sol-crisis-message-title {
  margin-bottom: 5px;
}
.sol-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 4px 0 20px 290px;
  border-radius: 0;
  background-color: transparent;
  font-size: 18px;
  line-height: 20px;
  min-height: 180px;
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-article-item {
    padding: 310px 0 0 0;
  }
}
@media (max-width: 767px) {
  .sol-article-item {
    padding: 210px 0 0 0;
  }
}
.sol-article-item.sol-no-img {
  padding-left: 60px;
}
@media (max-width: 767px) {
  .sol-article-item.sol-no-img {
    padding-top: 20px;
  }
}
.sol-article-item .sol-article-item-img {
  position: absolute;
  left: 0;
  top: 12.5px;
  bottom: 0;
  width: 276px;
  height: 155px;
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-article-item .sol-article-item-img {
    width: 100%;
    height: 300px;
    background-color: #e0e0e0;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-img {
    width: 100%;
    height: 200px;
    background-color: #e0e0e0;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (max-width: 479px) {
  .sol-article-item .sol-article-item-img {
    width: 100%;
    height: 200px;
    background-size: cover;
  }
}
.sol-article-item .sol-article-item-img:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color;
}
.sol-article-item .sol-article-item-date {
  position: relative;
  background-color: transparent;
  color: #2c2c2c;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  top: 6px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-date {
    margin-top: 12px;
    font-size: 14px;
  }
}
.sol-article-item.sol-no-img .sol-article-item-date {
  background-color: #0071BB;
}
.sol-article-item .sol-article-item-heading {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.7px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
@media (max-width: 767px) {
  .sol-article-item .sol-article-item-heading {
    font-size: 21px;
    font-weight: 400;
  }
}
.sol-article-item .sol-article-item-cancelled {
  font-style: italic;
}
.sol-article-item .sol-article-item-desc {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  font-size: 16px;
  line-height: 21px;
  max-height: 65px;
}
.sol-article-item .sol-article-item-event-info {
  margin-top: 10px;
}
.sol-article-item .sol-article-item-event-info::after,
.sol-article-item .sol-article-item-event-info::before {
  display: table;
  content: '';
}
.sol-article-item .sol-article-item-event-info::after {
  clear: both;
}
.sol-article-item .sol-article-item-event-info-item {
  margin-right: 13px;
  font-size: 11px;
  float: left;
}
.sol-article-item .sol-article-item-event-info-item i {
  margin-right: 6px;
  color: #0071BB;
}
.sol-article-item:hover .sol-article-item-img:before {
  background-color: rgba(0, 113, 187, 0.3);
}
@media (max-width: 991px) {
  .sol-article-item:hover .sol-article-item-img:before {
    background-color: transparent;
  }
}
.sol-article-item:hover .sol-article-item-heading {
  color: #0071BB;
  text-decoration: underline;
}
.sol-startpage-events li.sv-channel-item {
  padding: 20px 0px 20px 20px;
}
@media (max-width: 1200px) {
  .sol-startpage-events li.sv-channel-item {
    padding: 20px 0px 10px 0;
  }
}
@media (max-width: 991px) {
  .sol-startpage-events li.sv-channel-item {
    padding: 60px 0px 10px 0;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events li.sv-channel-item {
    padding: 0 0 24px 0;
  }
}
.sol-startpage-events .sol-article-item {
  position: relative;
  padding: 12px 0px 0px 320px;
  min-height: 180px;
}
@media (max-width: 991px) {
  .sol-startpage-events .sol-article-item {
    padding: 0;
    min-height: 180px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.sol-startpage-events .sol-article-item.sol-no-img {
  padding-right: 60px;
  padding-left: 20px;
}
.sol-startpage-events .sol-article-item-img {
  left: 0;
}
@media (max-width: 991px) {
  .sol-startpage-events .sol-article-item-img {
    overflow: hidden;
    position: relative;
    top: unset;
  }
}
.sol-startpage-events .sol-article-item.sol-no-img .sol-startpage-events .sol-article-item-date {
  background-color: #0071BB;
}
.sol-startpage-events .sol-article-item-heading {
  margin-bottom: 10px;
  line-height: normal;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item-heading {
    margin-bottom: 10px;
  }
}
.sol-startpage-events .sol-article-item-event-info {
  margin-top: 10px;
  max-height: 116px;
}
.sol-startpage-events .sol-article-item-event-info::after,
.sol-startpage-events .sol-article-item-event-info::before {
  display: table;
  content: '';
}
.sol-startpage-events .sol-article-item-event-info::after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item-event-info {
    position: static;
    left: 0;
    bottom: 0;
    top: unset;
    height: auto;
    width: 100%;
    margin-top: 10px;
  }
}
.sol-startpage-events .sol-article-item-event-info-item {
  margin-right: 0px;
  font-size: 11px;
  float: left;
}
.sol-startpage-events .sol-article-item-event-info-item i {
  margin-right: 6px;
  color: #0071BB;
}
.sol-startpage-events .sol-article-item-event-info-item.date {
  position: absolute;
  z-index: 2;
  top: calc(50% - (78px / 2));
  right: -36px;
  padding-top: 17px;
  display: block;
  height: 78px;
  width: 78px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #D12E65;
  color: #fff;
}
@media (max-width: 991px) {
  .sol-startpage-events .sol-article-item-event-info-item.date {
    position: absolute;
    margin-left: 0;
    left: -15px;
    z-index: 2;
    top: unset;
    bottom: -20px;
  }
}
.sol-startpage-events .sol-article-item-event-info-item.date .sol-article-item-date-day {
  text-align: center;
  font-size: 24px;
  margin-bottom: 2px;
  font-weight: 400;
  display: block;
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item-event-info-item.date .sol-article-item-date-day {
    font-size: 19px;
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    left: 28px;
    top: -4px;
  }
}
.sol-startpage-events .sol-article-item-event-info-item.date .sol-article-item-date-month {
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  display: block;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item-event-info-item.date .sol-article-item-date-month {
    font-size: 15px;
    display: inline-block;
    position: relative;
    top: 13px;
    left: 0px;
  }
}
.sol-startpage-events .sol-article-item-event-info-item.startTime {
  position: relative;
  display: inline-block;
  float: none;
  margin-bottom: 5px;
  font-size: 16px;
  color: #2c2c2c;
}
.sol-startpage-events .sol-article-item-event-info-item.startTime i {
  font-size: 20px;
  color: #D12E65;
}
.sol-startpage-events .sol-article-item-event-info-item.endTime {
  position: relative;
  display: inline-block;
  float: none;
  font-size: 16px;
  color: #2c2c2c;
}
.sol-startpage-events .sol-article-item-event-info-item.endTime i {
  font-size: 20px;
  color: #D12E65;
}
.sol-startpage-events .sol-article-item-event-info-item.location {
  position: relative;
  float: none;
  margin-bottom: 5px;
  font-size: 16px;
  color: #2c2c2c;
}
.sol-startpage-events .sol-article-item-event-info-item.location i {
  font-size: 20px;
  color: #D12E65;
}
.sol-startpage-events .sol-article-item-event-info-item.inline-date {
  margin-bottom: 5px;
  position: relative;
  display: block;
  float: none;
  font-size: 16px;
  color: #2c2c2c;
}
.sol-startpage-events .sol-article-item-event-info .sol-deviding-dash {
  display: inline-block;
  font-size: 16px;
  margin-left: 0px;
  margin-right: -3px;
}
@media (max-width: 991px) {
  .sol-startpage-events .sol-article-item-content {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-events .sol-article-item-content {
    margin-bottom: 12px;
  }
}
.sol-startpage-content-bottom .sol-startpage-events li {
  border-right: none;
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom .sol-startpage-events li .sol-article-item {
    padding: 0;
    position: relative;
  }
}
.sol-startpage-content-bottom .sol-startpage-events + .sol-font-btn-small {
  margin-left: 20px;
}
@media (max-width: 1200px) {
  .sol-startpage-content-bottom .sol-startpage-events + .sol-font-btn-small {
    margin-left: 0;
  }
}
.sol-startpage-content-bottom .sol-startpage-events + .sol-font-btn-small .sv-text-portlet-content .font-btn a:hover {
  background-color: #D12E65 !important;
  color: white;
  border: 1px solid #D12E65 !important;
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom .sol-startpage-events + .sol-font-btn-small {
    margin-left: 0;
  }
}
.sol-startpage-content-bottom li {
  max-height: 220px;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  padding: 20px 20px 20px 0;
}
@media (max-width: 1200px) {
  .sol-startpage-content-bottom li {
    border-right: none;
  }
}
@media (max-width: 991px) {
  .sol-startpage-content-bottom li {
    max-height: none;
    padding: 60px 0 20px 0;
  }
  .sol-startpage-content-bottom li:first-of-type {
    padding: 0px 0 24px 0;
  }
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom li {
    padding: 20px 0;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
.sol-startpage-content-bottom li:nth-child(1) {
  border-top: none;
}
.sol-startpage-content-bottom .sv-grid-main.sv-fluid-grid *[class*='sv-column-'] {
  padding: 0 !important;
}
.sol-startpage-content-bottom #h-Evenemang,
.sol-startpage-content-bottom #h-Nyheter {
  padding-left: 20px;
}
@media (max-width: 1200px) {
  .sol-startpage-content-bottom #h-Evenemang,
  .sol-startpage-content-bottom #h-Nyheter {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom #h-Evenemang,
  .sol-startpage-content-bottom #h-Nyheter {
    font-size: 27px;
    border-top: 3px solid #e0e0e0;
    padding-top: 24px;
    margin-top: 22px;
  }
}
.sol-startpage-content-bottom #h-Nyheter {
  padding-left: 0;
}
.sol-startpage-content-bottom .font-btn {
  margin-left: 0px;
}
.sol-startpage-content-bottom .font-btn a:hover {
  background-color: #0071BB !important;
  color: #fff !important;
  border: 1px solid #0071BB;
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom .font-btn {
    margin-left: 0px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom .sol-font-btn-small {
    margin-bottom: 0;
    margin-top: 22px;
    max-height: 48px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-content-bottom {
    margin-bottom: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
  padding: 0 !important;
}
.sol-news-menu .sol-menu-item[data-is-current='true'] > .sol-menu-item-link {
  color: rgba(0, 113, 187, 0.8);
}
.sol-news-menu .sol-menu-item div {
  display: block;
  line-height: 26px;
}
.sol-news-menu .sol-sub-menu-container li a {
  border-top: 0 !important;
  padding-top: 14px;
  padding-bottom: 10px;
  height: 45px;
}
.sol-news-menu .sol-sub-menu-container li button,
.sol-news-menu .sol-sub-menu-container li .sol-menu-button {
  height: 45px !important;
  width: 45px !important;
}
.sol-news-menu .sol-sub-menu-container li button i,
.sol-news-menu .sol-sub-menu-container li .sol-menu-button i {
  top: 6.5px !important;
  margin-top: 0 !important;
  right: 0px;
  height: 30px;
  width: 30px;
  text-align: center;
  font-size: 17px;
  padding-top: 6.5px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  color: #2c2c2c;
  border-radius: 50%;
}
.sol-news-menu .sol-sub-menu-container li button i:after,
.sol-news-menu .sol-sub-menu-container li .sol-menu-button i:after {
  position: relative;
  left: 1px;
}
.sol-news-menu .sol-menu-item[data-expanded='true'][data-menu-level='1'] {
  background-color: none !important;
}
.sol-news-menu .sol-menu-item[data-expanded='true'][data-menu-level='1'] > .sol-menu-item-link {
  background-color: rgba(224, 224, 224, 0.2) !important;
}
.sol-news-menu .sol-menu-item[data-menu-level='2'] {
  background-color: none !important;
}
.sol-news-menu .sol-menu-item[data-menu-level='2'] > .sol-menu-item-link {
  border-color: #fff !important;
  background-color: rgba(224, 224, 224, 0.4) !important;
}
.sol-event-info .sol-simple-box-decoration {
  margin-top: 0;
}
.sol-event-info-field {
  display: inline-block;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  font-weight: 300;
  font-size: 15px;
}
.sol-event-info-field > span {
  margin-right: 8px;
  font-weight: 600;
  font-size: 16px;
}
.sol-event-info-field .sol-category-devider {
  margin-right: 4px;
  margin-left: -3px;
  font-weight: normal;
  font-size: 15px;
}
.sol-event-info-field a {
  position: relative;
  display: inline-block;
  margin: 5px 0 2px;
  color: #0071BB;
}
.sol-event-info-field a:hover {
  color: #22a8ff;
}
.sol-event-info-field a i {
  position: absolute;
  top: 5px;
  right: -25px;
  color: #0071bb;
}
.sol-event-info .only-edit {
  display: none;
}
.sol-event-info-small {
  margin-top: 10px;
  margin-bottom: 10px;
}
.sol-event-info-small::after,
.sol-event-info-small::before {
  display: table;
  content: '';
}
.sol-event-info-small::after {
  clear: both;
}
.sol-event-info-small-item {
  float: left;
  margin-right: 20px;
  font-size: 14px;
}
.sol-event-info-small-item i {
  margin-right: 6px;
  color: #0071BB;
}
.sv-edit-mode .sol-event-info .only-edit {
  display: block;
}
.sol-event-status {
  display: block;
  margin-bottom: 10px;
  padding: 15px;
  text-align: center;
  background-color: rgb(235 63 0);
}
.sol-event-status h2 {
  display: inline-block;
  margin: 0;
  color: white;
}
.sol-article-wrapper {
  max-width: 828px;
  margin-right: auto;
  margin-left: auto;
}
.sol-article-wrapper .sol-article-date {
  display: inline-block;
  width: auto;
  margin-bottom: 10px;
  padding: 0;
  background-color: transparent;
}
.sol-article-wrapper .sol-article-date-day {
  display: inline-block;
  color: #2c2c2c;
  font-size: 14px;
}
.sol-article-wrapper .sol-article-date-month {
  display: inline-block;
  color: #2c2c2c;
  font-size: 14px;
}
.sol-article-wrapper .sol-article-date-year {
  display: inline-block;
  color: #2c2c2c;
  font-size: 14px;
}
.sol-article-wrapper .sv-image-portlet {
  position: relative;
  overflow: hidden;
}
.sol-article-wrapper .pagecontent::after,
.sol-article-wrapper .pagecontent::before {
  display: table;
  content: '';
}
.sol-article-wrapper .pagecontent::after {
  clear: both;
}
.sol-news-menu .sol-menu-item[data-expanded='false'] .sol-menu-list {
  display: none;
}
.sol-event-item {
  display: block;
  margin: 12px;
  overflow: hidden;
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: opacity;
}
.sol-event-item:hover {
  opacity: 90%;
}
.sol-event-item-content {
  padding: 4px 10px;
  color: #fff;
  text-align: left;
  background-color: #0071BB;
}
.sol-event-item-heading {
  display: block;
  font-weight: 700;
  font-size: 13px;
}
.sol-event-item-desc {
  display: block;
  margin-bottom: 4px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4em;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sol-event-item-info {
  padding: 4px 10px;
  color: #2c2c2c;
  font-size: 11px;
  line-height: 19px;
  background-color: rgba(0, 113, 187, 0.15);
}
.sol-event-item-info::after,
.sol-event-item-info::before {
  display: table;
  content: '';
}
.sol-event-item-info::after {
  clear: both;
}
.sol-event-item-info-item {
  display: inline-block;
  float: left;
  margin-right: 13px;
}
.sol-event-item-info-item i.fa,
.sol-event-item-info-item i.fas,
.sol-event-item-info-item i.fal,
.sol-event-item-info-item i.fab,
.sol-event-item-info-item i.far {
  margin-right: 6px;
  color: #0071BB;
}
.sol-breadcrumbs-wrapper {
  position: relative;
  height: 56px;
  margin-top: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: rgba(0, 113, 187, 0.1);
  display: inherit;
}
@media (max-width: 767px) {
  .sol-breadcrumbs-wrapper {
    position: absolute;
    z-index: 99;
    width: calc(100% - 55px);
    margin-top: -55px;
  }
}
.sol-breadcrumbs-wrapper > .sv-grid-main {
  height: 100%;
}
.sol-breadcrumbs-wrapper > .sv-grid-main > .sv-script-portlet {
  height: 100%;
}
.sol-breadcrumbs-wrapper > .sv-grid-main > .sv-script-portlet > nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
@media (max-width: 767px) {
  .sol-breadcrumbs-wrapper {
    position: relative;
    z-index: 49;
    display: block;
    width: calc(100% - 55px);
    height: 55px;
    margin-top: 0;
    padding-top: 4px;
    padding-bottom: 6px;
    background-color: #e8f3f8;
  }
}
.sol-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}
@media (max-width: 767px) {
  .sol-breadcrumbs {
    padding: 0;
  }
}
@media (max-width: 479px) {
  .sol-breadcrumbs {
    max-width: 300px;
    max-height: 48px;
    overflow: hidden;
  }
}
.sol-breadcrumbs li:first-of-type .sol-breadcrumbs__link {
  font-weight: 700;
}
.sol-breadcrumbs__link {
  display: inline-block;
  color: #2c2c2c;
  font-size: 13px;
  letter-spacing: 0.4px;
  white-space: nowrap;
  text-decoration: none;
}
.sol-breadcrumbs__link:hover {
  color: #2c2c2c;
  text-decoration: underline;
}
.sol-breadcrumbs__link--active {
  color: #2c2c2c;
}
.sol-breadcrumbs__link:not(.sol-breadcrumbs__link--active)::after {
  display: inline-block;
  height: 0.8em;
  margin: 0 8px;
  border-right: 1px solid #2c2c2c;
  transform: rotate(15deg);
  content: '';
}
/* subpage/page-listing.less */
.sol-page-listing {
  border-radius: 3px;
  background-color: #fff;
  padding: 20px 0 15px 10px;
  margin-bottom: -28px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
}
.sol-page-listing ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sol-page-listing-item {
  flex: 1 0 25%;
  margin: 10px 40px 10px 0;
}
.sol-page-listing-item:nth-child(3n) {
  margin-right: 0;
}
@media (max-width: 991px) {
  .sol-page-listing-item {
    flex: 1 0 100%;
    margin: 0;
  }
}
.sol-page-listing-item__link {
  text-decoration: none;
}
.sol-page-listing-item__link:hover {
  text-decoration: underline;
}
.sol-page-listing-item__name {
  color: inherit;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 300;
}
.sol-page-listing-item__icon {
  font-size: 30px;
  float: left;
  margin-right: 7px;
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #0071BB;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: -5px;
  padding-left: 2.5px;
  padding-top: 0;
  margin-left: -12px;
}
.sol-page-listing-child__link {
  display: block;
  padding: 16px 0;
  border-top: 1px solid #e0e0e0;
  font-size: 17px;
  color: #2c2c2c;
  text-decoration: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
.sol-page-listing-child__link:hover {
  text-decoration: underline;
}
.sol-page-listing-child__link i.fa-external-link {
  font-size: 11px;
  position: relative;
  top: -1px;
  margin-left: 5px;
}
.sol-page-listing-child__icon {
  font-size: 21px;
  padding-top: 5px;
  background-color: #0071BB;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  float: left;
  margin-right: 8px;
  color: #fff;
  position: relative;
  top: -5px;
  padding-left: 2.5px;
}
.sol-page-listing-child.hide {
  display: none;
}
.sol-page-listing-child:last-of-type a {
  border-bottom: 1px solid #e0e0e0;
}
.sol-page-listing .sol-show-all {
  margin-top: 20px;
  margin-bottom: 20px;
  text-decoration: none;
}
.sol-page-listing .sol-show-all .sol-show-more-btn {
  padding: 6px 12px;
  border: 1px solid #9e9e9e;
  transition-property: color, background-color;
  transition-duration: 0.2s;
}
.sol-page-listing .sol-show-all .sol-show-more-btn:hover {
  background-color: #0071BB;
  color: #fff;
}
.sol-publish-info {
  padding: 10px 0 5px;
  font-weight: 400;
  font-size: 15px;
}
.sol-publish-info dt,
.sol-publish-info dd {
  display: inline;
  margin: 0;
}
.sol-publish-info dl {
  margin: 0;
}
.sol-publish-info dt {
  margin-right: 0;
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 767px) {
  .sol-publish-info dt {
    font-size: 13px;
    line-height: 20px;
  }
}
@media (max-width: 479px) {
  .sol-publish-info dt {
    display: block;
  }
}
.sol-print {
  margin-bottom: 0 !important;
}
.sol-print > button {
  float: right;
  color: #0071bb;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  background: none;
  border: none;
  appearance: none;
}
@media (max-width: 767px) {
  .sol-print > button {
    font-size: 15px;
  }
}
.sol-print > button:hover {
  text-decoration: underline;
}
.sol-print i.fa,
.sol-print i.fal,
.sol-print i.far,
.sol-print i.fas,
.sol-print i.fab {
  margin-right: 5px;
}
.sol-contact,
.sol-related {
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 25px 20px 15px;
  background-color: rgba(229, 240, 248, 0.6);
}
.sol-related-item {
  display: inline-block;
  margin-bottom: 12px;
  line-height: 23px;
  text-decoration: none;
}
div .sol-related-item-item .sol-related-item-item-name {
  color: #2c2c2c;
  text-decoration: none;
}
.sol-related-item-name {
  color: #2c2c2c;
  text-decoration: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
.sol-related-item-size {
  display: inline-block;
  margin-left: 10px;
  color: #2c2c2c;
  font-size: 19px;
}
.sol-related-item i.fal,
.sol-related-item i.fas,
.sol-related-item i.far,
.sol-related-item i.fab {
  position: relative;
  top: 2px;
  width: 27px;
  height: 27px;
  margin-right: 7px;
  padding-top: 5px;
  color: #0071BB;
  font-size: 23px;
  line-height: 15px;
  text-align: center;
  border-radius: 50%;
}
.sol-related-item i.fal.sol-link-icon,
.sol-related-item i.fas.sol-link-icon,
.sol-related-item i.far.sol-link-icon,
.sol-related-item i.fab.sol-link-icon {
  padding-left: 2px;
  border: 1px solid #0071BB;
}
.sol-related-item:hover .sol-related-item-name {
  color: #2c2c2c;
}
.sol-related a:hover .sol-related-item-name {
  text-decoration: underline;
}
.sol-contact-item h3 {
  font-weight: 400;
}
.sol-contact-item h4 {
  display: block;
  font-weight: 400;
}
.sol-contact-item h5 {
  display: block !important;
  font-weight: 400;
}
.sol-contact-item p {
  margin: 5px 0;
}
.sol-contact-item p strong {
  display: inline-block;
  margin-top: 20px;
}
.sol-subpage-bottom-content {
  max-width: 1280px;
  margin: auto;
}
.sol-menu-container {
  margin-top: 48px;
  overflow: visible;
}
.sol-menu-container .sol-menu-list {
  display: none;
  width: 100%;
}
.sol-menu-container .sol-menu-list-expanded-init {
  display: block;
}
.sol-menu-container .sol-menu-item {
  position: relative;
  /*     &[data-expanded="true"] {
      & > .sol-menu-button i.fa,
      & > .sol-menu-button i.fal,
      & > .sol-menu-button i.far,
      & > .sol-menu-button i.fas,
      & > .sol-menu-button i.fab {
        transform: rotate(90deg);
      }
    } */
}
.sol-menu-container .sol-menu-item .sol-menu-item-link {
  padding: 14.5px 34px 8px 20px;
  height: 55px;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link {
  position: relative;
  display: block;
  border-top: 1px solid #9e9e9e;
  color: #2c2c2c;
  text-decoration: none;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color, background-color, border;
}
.sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput='keyboard'] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput='mouse'] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus,
[data-whatinput='touch'] .sol-menu-container .sol-menu-item[data-menu-level="1"] .sol-menu-item-link:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-menu-container .sol-menu-item .sol-menu-button {
  background: none;
  border: none;
  appearance: none;
  position: absolute;
  right: 0;
  width: 34px;
  text-align: right;
  font-size: 17px;
  top: 5px;
  height: 38px;
  padding-right: 20px;
  cursor: pointer;
}
.sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='keyboard'] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: 0;
  box-shadow: 0 0 0 4px #fff;
}
[data-whatinput='initial'] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='mouse'] .sol-menu-container .sol-menu-item .sol-menu-button:focus,
[data-whatinput='touch'] .sol-menu-container .sol-menu-item .sol-menu-button:focus {
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-menu-container .sol-menu-item .sol-menu-button > i.fa,
.sol-menu-container .sol-menu-item .sol-menu-button > i.fal,
.sol-menu-container .sol-menu-item .sol-menu-button > i.far,
.sol-menu-container .sol-menu-item .sol-menu-button > i.fas,
.sol-menu-container .sol-menu-item .sol-menu-button > i.fab {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: all;
}
.sol-menu-container .sol-menu-item[data-menu-level="2"] .sol-menu-item-link {
  padding-left: 25px;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-item-link {
  padding-left: 35px;
  line-height: 14px;
}
.sol-menu-container .sol-menu-item[data-menu-level="3"] .sol-menu-button {
  height: 30px;
}
.sol-menu-container .sol-menu-item[data-menu-level="4"] .sol-menu-item-link {
  padding-left: 45px;
}
.sol-menu-container .sol-menu-item[data-menu-level="5"] .sol-menu-item-link {
  padding-left: 55px;
}
.sol-menu-container .sol-menu-item > .sol-menu-item-link:hover {
  color: #0090ee;
  background-color: transparent;
}
.sol-menu-container .sol-menu-item[data-has-children="false"] > .sol-menu-item-link {
  padding-right: 5px;
}
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-is-current="true"] > .sol-menu-item-link:hover {
  color: #005288;
  background-color: #9e9e9e;
  border-color: #9e9e9e;
  font-weight: 700;
}
.sol-menu-container .sol-menu-item[data-is-current="true"] > a {
  color: #005288;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link {
  font-weight: 300;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fa,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fal,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.far,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fas,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link i.fab {
  color: #2c2c2c;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-item-link:hover {
  color: #2c2c2c;
  background-color: #e0e0e0;
  border-top-color: #0071bb;
}
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.fa,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.fal,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.far,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.fas,
.sol-menu-container .sol-menu-item[data-expanded="true"][data-menu-level="1"] > .sol-menu-button i.fab {
  color: #2c2c2c;
}
.sol-menu-container .sol-menu-item[data-node-type="sv:folder"] > .sol-menu-button {
  width: 100%;
  left: 0;
}
.sol-subpage-top-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.sol-subpage-top-content .sol-subpage-description {
  flex: 1 0 auto;
  max-width: 770px;
  margin-right: 56px;
}
@media (max-width: 991px) {
  .sol-subpage-top-content .sol-subpage-description {
    flex: 1 0 100%;
    margin-right: 0;
  }
}
.sol-subpage-top-content .sol-subpage-links {
  flex: 1 0 auto;
  max-width: 350px;
  padding: 0 2px;
}
@media (max-width: 991px) {
  .sol-subpage-top-content .sol-subpage-links {
    flex: 1 0 100%;
    max-width: none;
  }
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet {
  position: relative;
  margin: 10px 0;
  border: 1px solid #9e9e9e;
  transition: background-color 0.15s ease-out;
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet:hover {
  color: #fff;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  transition: background-color 0.15s ease-out;
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet:hover a {
  color: #fff;
  transition: 0.15s ease-out;
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet:hover a::after {
  color: #0071BB;
  background-color: #fff;
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet p,
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet a {
  display: block;
  width: 100%;
  height: 40px;
  margin: 0;
  color: black;
  font-weight: 400;
  font-size: 21px;
  line-height: 42px;
  text-decoration: none;
}
@media (max-width: 991px) {
  .sol-subpage-top-content .sol-subpage-links .sv-text-portlet p,
  .sol-subpage-top-content .sol-subpage-links .sv-text-portlet a {
    font-size: 18px;
  }
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet a {
  padding: 0 46px 0 10px;
}
.sol-subpage-top-content .sol-subpage-links .sv-text-portlet a::after {
  position: absolute;
  top: 2.5px;
  right: 10px;
  width: 28px;
  height: 26px;
  padding-top: 6px;
  padding-left: 4px;
  color: #0071BB;
  font-weight: 100;
  font-size: 19px;
  font-family: 'Font Awesome 5 Pro';
  line-height: normal;
  text-align: center;
  border: 1px solid #0071BB;
  border-radius: 50%;
  content: '\f054';
}
.sv-file-portlet .sv-file-portlet-fileicon {
  width: 26px;
}
.sv-file-portlet .sv-file-portlet-fileicon img {
  width: 100%;
}
.sv-file-portlet .sv-file-portlet-file {
  width: auto;
}
.sv-linklist-portlet > div > div:nth-child(3) a,
.sv-linklist-portlet > div > div:nth-child(3) span {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-right: 2px;
  margin-left: 2px;
  padding: 4px 0 0;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  box-shadow: none;
}
.sv-linklist-portlet > div > div:nth-child(3) a:hover,
.sv-linklist-portlet > div > div:nth-child(3) span:hover {
  background-color: #e5f0f8;
}
.sv-linklist-portlet > div > div:nth-child(3) span,
.sv-linklist-portlet > div > div:nth-child(3) span {
  color: #fff;
  background-color: #0071BB;
}
.sv-linklist-portlet > div > div:nth-child(3) span strong,
.sv-linklist-portlet > div > div:nth-child(3) span strong {
  font-weight: 300;
}
.sol-subpage-wrapper::after,
.sol-startpage-wrapper::after,
.sol-subpage-wrapper::before,
.sol-startpage-wrapper::before {
  display: table;
  content: '';
}
.sol-subpage-wrapper::after,
.sol-startpage-wrapper::after {
  clear: both;
}
.sol-subpage-wrapper > .sol-left,
.sol-startpage-wrapper > .sol-left {
  float: left;
  width: 65%;
  margin-top: 35px;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-left,
  .sol-startpage-wrapper > .sol-left {
    float: none;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper > .sol-left,
  .sol-startpage-wrapper > .sol-left {
    margin-top: 0;
  }
}
.sol-subpage-wrapper > .sol-left .sv-image-portlet,
.sol-startpage-wrapper > .sol-left .sv-image-portlet {
  margin-top: 20px;
}
.sol-subpage-wrapper > .sol-right,
.sol-startpage-wrapper > .sol-right {
  float: right;
  width: 30%;
  margin-top: 0;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper > .sol-right,
  .sol-startpage-wrapper > .sol-right {
    float: none;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper > .sol-right,
  .sol-startpage-wrapper > .sol-right {
    display: none;
  }
}
.sol-subpage-wrapper .pagecontent::after,
.sol-startpage-wrapper .pagecontent::after,
.sol-subpage-wrapper .pagecontent::before,
.sol-startpage-wrapper .pagecontent::before {
  display: table;
  content: '';
}
.sol-subpage-wrapper .pagecontent::after,
.sol-startpage-wrapper .pagecontent::after {
  clear: both;
}
.sol-subpage-wrapper .sv-column-12 > div,
.sol-startpage-wrapper .sv-column-12 > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-subpage-wrapper .sv-column-12 > div,
  .sol-startpage-wrapper .sv-column-12 > div {
    justify-content: flex-start;
  }
}
.sol-subpage-wrapper .sv-column-12 .pagecontent > div,
.sol-startpage-wrapper .sv-column-12 .pagecontent > div {
  width: 100%;
}
.sol-subpage-wrapper .sol-blurbs-row,
.sol-startpage-wrapper .sol-blurbs-row {
  margin-right: -28px !important;
  margin-left: -28px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media (max-width: 991px) {
  .sol-subpage-wrapper .sol-blurbs-row,
  .sol-startpage-wrapper .sol-blurbs-row {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper .sol-blurbs-row,
  .sol-startpage-wrapper .sol-blurbs-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    padding-top: 55px;
    border-top: 3px solid #e0e0e0;
  }
}
.sol-subpage-wrapper .sol-blurbs-row .sv-column-12,
.sol-startpage-wrapper .sol-blurbs-row .sv-column-12 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-subpage-wrapper .sol-blurbs-row .sv-column-12,
  .sol-startpage-wrapper .sol-blurbs-row .sv-column-12 {
    justify-content: left;
  }
}
@media (max-width: 991px) {
  .sol-subpage-wrapper .sol-blurbs-row .sv-column-12,
  .sol-startpage-wrapper .sol-blurbs-row .sv-column-12 {
    margin-bottom: 0;
    padding: 0;
  }
}
.sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4,
.sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4 {
  max-width: 28%;
  height: auto;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 1240px) {
  .sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4,
  .sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4 {
    max-width: 30%;
    padding: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4,
  .sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4 {
    flex: 1 0 auto;
    max-width: 32%;
    margin: 30px 0;
    padding: 0 16px;
  }
  .sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4:first-of-type,
  .sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4:first-of-type {
    margin-left: 0;
  }
  .sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4:last-of-type,
  .sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4:last-of-type {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4,
  .sol-startpage-wrapper .sol-blurbs-row .pagecontent *.sv-column-4 {
    flex: 1 0 100%;
    max-width: none;
    margin: 10px 0;
    padding: 0;
  }
}
.sol-wide-content-wrapper {
  margin: 20px 0 30px;
}
@media (max-width: 767px) {
  .sol-wide-content-wrapper {
    margin-top: 0;
  }
}
.sol-wide-content-wrapper.sol-wide-content {
  margin-top: 65px;
}
@media (max-width: 991px) {
  .sol-wide-content-wrapper.sol-wide-content {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .sol-subpage-wrapper .sol-blurbs-row {
    padding-top: 0;
    border-top: none;
  }
}
.sol-subpage-wrapper .sv-grid-main.sv-fluid-grid {
  padding: 0;
}
.sol-section {
  padding-top: 35px;
  padding-bottom: 50px;
}
@media (max-width: 991px) {
  .sol-section {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .sol-section {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 479px) {
  .sol-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.sol-wide-content-wrapper {
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto;
}
.sol-article-wrapper .sv-image-portlet,
.sol-subpage-wrapper .sv-image-portlet {
  margin-bottom: 20px;
}
.sol-article-wrapper .sv-image-portlet .sv-portlet-image-caption,
.sol-subpage-wrapper .sv-image-portlet .sv-portlet-image-caption {
  margin-top: 0;
  padding: 8px 5px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 15px;
  text-align: right;
  background-color: #fff;
}
.sol-article-wrapper .sv-linkicon,
.sol-subpage-wrapper .sv-linkicon {
  display: none;
}
.sol-article-wrapper .sv-decoration-content .sv-image-portlet,
.sol-subpage-wrapper .sv-decoration-content .sv-image-portlet {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-section {
    padding-top: 0;
    padding-bottom: 10px;
  }
}
.sol-popular-pages {
  display: flex;
  justify-content: center;
  padding: 10px 15px 5px;
  background-color: transparent;
}
.sol-popular-pages::after,
.sol-popular-pages::before {
  display: table;
  content: '';
}
.sol-popular-pages::after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-popular-pages {
    position: relative;
    display: none !important;
    padding: 16px 12px 8px;
    background-color: #e5f0f8;
  }
}
.sol-popular-pages__header {
  display: none !important;
  margin-bottom: 20px;
  padding-left: 8px;
  text-align: left;
}
.sol-popular-pages > a,
.sol-popular-pages > div {
  float: left;
  margin-bottom: 5px;
  color: #2c2c2c;
}
@media (max-width: 767px) {
  .sol-popular-pages > a,
  .sol-popular-pages > div {
    font-size: 13px;
  }
}
.sol-popular-pages > nav {
  display: block;
  padding: 10px 12px;
  color: #fff;
}
@media (max-width: 767px) {
  .sol-popular-pages > nav {
    padding: 0;
  }
}
.sol-popular-pages > nav > a {
  margin-right: 15px;
  padding: 6px 14px;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  transition-timing-function: border;
  transition-duration: color;
  transition-property: background-color;
}
.sol-popular-pages > nav > a:last-of-type {
  background-color: #D12E65;
  border: 1px solid #D12E65;
}
.sol-popular-pages > nav > a:last-of-type:hover {
  /*             border: 1px solid @color-4;
            background-color: #FBDCE2;
            color: @color-4; */
  text-decoration: underline;
  background-color: #da5884;
  border: 1px solid #da5884;
}
@media (max-width: 767px) {
  .sol-popular-pages > nav > a {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
    padding: 8px 20px;
  }
}
@media (max-width: 479px) {
  .sol-popular-pages > nav > a {
    margin-right: 4px;
    margin-bottom: 12px;
    padding: 6px 16px;
  }
}
.sol-popular-pages > nav > a:hover {
  /*           border: 1px solid @color-1;
          background-color: @color-2;
          color: @color-1; */
  text-decoration: underline;
  background-color: #0090ee;
  border: 1px solid #0090ee;
}
.sol-popular-pages > nav > a i.fa,
.sol-popular-pages > nav > a i.fal,
.sol-popular-pages > nav > a i.far,
.sol-popular-pages > nav > a i.fas,
.sol-popular-pages > nav > a i.fab {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  padding-top: 1px;
  padding-left: 1px;
  text-align: center;
  border: 1px solid #2c2c2c;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .sol-subweb-bostader .sol-popular-pages {
    display: flex !important;
    margin-top: 30px;
  }
}
.sol-popular-services a {
  position: relative;
  display: block;
  padding: 8px 10px 9px 35px;
  color: #2c2c2c;
  font-size: 13px;
  line-height: 20px;
  text-decoration: none;
  border-bottom: 1px solid #f1f1f1;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
.sol-popular-services a:hover {
  color: #0071BB;
  text-decoration: underline;
}
.sol-popular-services a i.fa,
.sol-popular-services a i.fal,
.sol-popular-services a i.far,
.sol-popular-services a i.fas,
.sol-popular-services a i.fab {
  position: absolute;
  top: 50%;
  left: 9px;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  margin-right: 8px;
  padding-top: 1px;
  padding-left: 1px;
  color: #0071BB;
  font-size: 14px;
  text-align: center;
  border: 1px solid #0071BB;
  border-radius: 50%;
}
.sol-startpage-top-images {
  position: relative;
}
.sol-startpage-top-images-item {
  height: 450px;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 767px) {
  .sol-startpage-top-images-item {
    height: 200px;
  }
}
.sol-startpage-top-images .slick-dots {
  list-style: none;
  margin: 0;
  padding: 10px 0 0 0;
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 5px;
  left: 0;
  height: 30px;
  text-align: center;
}
.sol-startpage-top-images .slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  margin: 0 3px 5px;
  transition-timing-function: ease;
  transition-duration: 100ms;
  transition-property: background-color, color;
}
.sol-startpage-top-images .slick-dots li button {
  display: none;
}
.sol-startpage-top-images .slick-dots li.slick-active {
  background-color: white;
}
.sol-startpage-top-images-content {
  position: absolute;
  width: 660px;
  left: 0;
  top: 140px;
  right: 0;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  border: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-startpage-top-images-content {
    top: 180px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-top-images-content {
    position: static;
    width: 100%;
    border-radius: 0;
    height: 80px;
    background-color: #e5f0f8;
  }
}
.sol-startpage-top-images-content .sol-top-search {
  padding: 0;
}
@media (max-width: 767px) {
}
@media (max-width: 767px) {
  .sol-startpage-top-images-content .sol-top-search form {
    top: 15px;
    width: 88%;
    margin: auto;
  }
}
.sol-startpage-top-images-content .sol-top-search form input {
  height: 50px;
  border-radius: 0px;
  border: none;
  font-size: 18px;
  line-height: 26px;
  color: #2c2c2c;
  padding: 0 78px 0 16px;
}
.sol-startpage-top-images-content .sol-top-search form button[type=submit] {
  top: -6px;
  right: 10px;
  width: 62px;
  height: 62px;
}
.sol-startpage-top-images-content .sol-top-search form button[type=submit] i.far {
  top: 0;
  font-size: 27px;
}
.sol-top-image__container {
  width: 100%;
  height: 540px;
  /*   -webkit-box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 145px 30px 0px rgba(0, 0, 0, 0.5); */
  background-size: cover !important;
  background-position: center !important;
}
@media (max-width: 767px) {
  .sol-top-image__container {
    height: 220px;
  }
}
@media (max-width: 767px) {
  .sol-subweb-bostader .sol-startpage-top-images-content {
    height: 160px;
  }
}
.sol-more-links {
  display: flex;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: auto;
  padding: 45px 45px 30px;
}
@media (max-width: 991px) {
  .sol-more-links {
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  .sol-more-links {
    padding: 0 0 20px 5px;
  }
}
.sol-more-links-item {
  flex: 0 0 32%;
  padding: 30px 25px;
  text-decoration: none;
  word-break: break-word;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: none;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-more-links-item {
    flex: 0 0 29.5%;
  }
}
.sol-more-links-item:nth-child(1),
.sol-more-links-item:nth-child(2),
.sol-more-links-item:nth-child(3) {
  border-top: none;
}
.sol-more-links-item:nth-child(3n) {
  padding-right: 0;
  border-right: none;
}
.sol-more-links-item:nth-child(3n+1) {
  padding-left: 0;
}
.sol-more-links-item:nth-child(3n+2) {
  flex: 0 0 36%;
  padding-right: 25px;
  padding-left: 25px;
}
@media (max-width: 767px) {
  .sol-more-links-item:nth-child(3n+2) {
    flex: 0 0 100%;
    padding: 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sol-more-links-item:nth-child(3n+2) {
    flex: 0 0 29.5%;
  }
}
.sol-more-links-item:hover {
  text-decoration: none;
}
.sol-more-links-item:hover > :not(i) {
  text-decoration: underline;
}
.sol-more-links-item:hover i {
  color: #fff;
  background-color: #0071BB;
}
@media (max-width: 767px) {
  .sol-more-links-item:hover i {
    color: #0071BB;
    background-color: rgba(0, 113, 187, 0.1);
  }
}
@media (max-width: 767px) {
  .sol-more-links-item:hover {
    text-decoration: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sol-more-links-item {
    flex: 0 0 50% !important;
    padding: 30px !important;
    border: none !important;
  }
}
@media (max-width: 767px) {
  .sol-more-links-item {
    flex: 0 0 100%;
    flex: 1 0 100%;
    padding: 20px 0 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #e0e0e0 !important;
    border-left: none;
  }
}
.sol-more-links-item--header {
  display: inline-block;
  max-width: calc(100% - 40px);
  padding-top: 2px;
  padding-left: 10px;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.2em;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .sol-more-links-item--header {
    display: inline;
    max-width: none;
    padding-top: 0;
    padding-left: 10px;
    font-size: 18px;
  }
}
.sol-more-links-item--desc {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.6em;
}
@media (max-width: 767px) {
  .sol-more-links-item--desc {
    display: none;
    font-size: 16px;
  }
}
.sol-more-links-item i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding-left: 0.2em;
  color: #0071BB;
  font-weight: 300;
  font-size: 24px;
  line-height: normal;
  text-align: center;
  vertical-align: middle;
  background-color: rgba(0, 113, 187, 0.1);
  border-radius: 50%;
  transition-timing-function: ease;
  transition-duration: 400ms;
  transition-property: background-color, color;
}
@media (max-width: 767px) {
  .sol-more-links-item i {
    width: 23px;
    height: 23px;
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .sol-more-links-item.hide-sm {
    display: none;
  }
}
.sol-wide-content-wrapper .sol-more-links {
  padding: 30px 0;
}
@media (max-width: 767px) {
  .sol-wide-content-wrapper .sol-more-links {
    padding-top: 0;
  }
}
.sol-startpage-wrapper .sol-more-links {
  margin: 40px auto 0;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-more-links {
    margin: 10px auto 0;
  }
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-more-links {
    margin: 5px auto;
  }
}
.sol-startpage-wrapper .sol-more-links-item:nth-child(1),
.sol-startpage-wrapper .sol-more-links-item:nth-child(2),
.sol-startpage-wrapper .sol-more-links-item:nth-child(3) {
  padding-top: 0;
}
.sol-startpage-wrapper .sol-more-links-item:nth-child(4),
.sol-startpage-wrapper .sol-more-links-item:nth-child(5),
.sol-startpage-wrapper .sol-more-links-item:nth-child(6) {
  padding-bottom: 0;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-more-links-item {
    padding-left: 12px !important;
  }
}
@media (max-width: 479px) {
  .sol-startpage-wrapper .sol-more-links-item {
    padding-left: 6px !important;
  }
}
.sol-header-tourism-link {
  position: absolute;
  right: 55px;
  z-index: 2;
  width: 95px;
  height: 55px;
  padding-top: 12px;
  text-align: center;
  background-color: #d12e65;
  border-right: 2px solid #fff;
}
.sol-header-tourism-link p a {
  display: block;
  color: #fff !important;
  font-weight: 900;
  font-size: 13px !important;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none !important;
}
@media (min-width: 767px) {
  .sol-header-tourism-link {
    display: none;
  }
}
.sol-startpage-wrapper {
  margin-top: 0;
}
@media (max-width: 991px) {
}
.sol-startpage-wrapper .sol-top {
  position: relative;
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
  padding-right: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:first-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:first-child {
    padding-right: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
.sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
  padding-left: 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom > div:last-child,
  .sol-startpage-wrapper .sol-startpage-content-top > div:last-child {
    padding-left: 10px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sv-column-12 .sol-row,
.sol-startpage-wrapper .sol-startpage-content-top .sv-column-12 .sol-row {
  margin: 30px 0;
  padding: 0 10px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sv-column-12 .sol-row,
  .sol-startpage-wrapper .sol-startpage-content-top .sv-column-12 .sol-row {
    margin: 20px 0;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sv-column-12 .sol-row,
  .sol-startpage-wrapper .sol-startpage-content-top .sv-column-12 .sol-row {
    margin: 15px 0;
    padding: 0;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-col-md-4,
.sol-startpage-wrapper .sol-startpage-content-top .sol-col-md-4 {
  max-width: 28% !important;
  padding-right: 0;
  padding-left: 0;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sol-col-md-4,
  .sol-startpage-wrapper .sol-startpage-content-top .sol-col-md-4 {
    max-width: 31% !important;
    margin: 0 10px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sol-col-md-4,
  .sol-startpage-wrapper .sol-startpage-content-top .sol-col-md-4 {
    max-width: none !important;
    margin-bottom: 24px;
    padding-right: 0;
    padding-left: 0;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom {
  margin-top: 35px;
  margin-bottom: 65px;
  padding: 0 20px 0 15px;
}
@media (max-width: 991px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom {
    margin-top: 15px;
    padding: 0 25px 0 20px;
  }
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom {
    margin-top: 0;
    padding: 0 6px;
  }
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-left h2.font-heading-2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
  padding-top: 33px;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-bottom .sol-right {
    padding-top: 0;
  }
}
.sol-startpage-wrapper .sol-startpage-content-top {
  padding: 0 5px;
}
@media (max-width: 767px) {
  .sol-startpage-wrapper .sol-startpage-content-top {
    margin-top: 10px;
    padding: 20px 16px 0;
  }
}
@media (max-width: 1200px) {
  .sv-grid-main.sv-fluid-grid .sv-row.sol-startpage-content-bottom *.sv-column-6 {
    flex: 1 0 100%;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .sv-grid-main.sv-fluid-grid .sv-row.sol-startpage-content-bottom *.sv-column-6 {
    margin-bottom: 0;
  }
}
.sol-a-z-icon {
  position: relative;
  top: 2px;
  margin-right: 5px;
  font-size: 15px;
}
.sol-letters {
  max-width: 450px;
}
.sol-letters::after,
.sol-letters::before {
  display: table;
  content: '';
}
.sol-letters::after {
  clear: both;
}
.sol-letters a {
  float: left;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 6px 0;
  color: #2c2c2c;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: none;
}
.sol-letters a.sol-active,
.sol-letters a:hover {
  color: #fff;
  background-color: #0071BB;
}
#__ba_panel {
  display: none;
}
.sol-contact-card {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgb(0 0 0%);
}
.sol-contact-card .sv-text-portlet {
  min-height: 51px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.sol-contact-card .sv-text-portlet .sv-portlet-toolbar {
  font-weight: 600;
}
.sol-contact-card h1.font-heading-1 {
  margin-top: 0;
  margin-bottom: 16px;
  color: #2c2c2c;
  font-weight: 300;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 26px;
  font-weight: normal;
}
@media (max-width: 991px) {
  .sol-contact-card h1.font-heading-1 {
    font-size: 19px;
  }
}
@media (max-width: 767px) {
  .sol-contact-card h1.font-heading-1 {
    font-size: 17px;
  }
}
a.sol-contact-card h1.font-heading-1,
.sol-contact-card h1.font-heading-1 a {
  color: #0071bb;
  text-decoration: underline;
}
a.sol-contact-card h1.font-heading-1:hover,
.sol-contact-card h1.font-heading-1 a:hover {
  color: #0090ee;
}
.sol-contact-card h2.font-heading-2 {
  margin-top: 25px;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.2em;
}
@media (max-width: 479px) {
  .sol-contact-card h2.font-heading-2 {
    font-size: 15px;
  }
}
.sol-contact-card h1.font-heading-1,
.sol-contact-card h2.font-heading-2,
.sol-contact-card h3.font-heading-3,
.sol-contact-card h4.font-heading-4,
.sol-contact-card p.font-normal {
  margin: 0;
}
.sol-contact-card .sv-html-portlet {
  display: none;
}
.sol-contact-card .sv-html-portlet p b {
  font-weight: 700;
}
.sv-editing-mode .sol-contact-card .sv-html-portlet {
  display: block;
}
.sol-error-page {
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
}
.sol-error-page-text-big {
  font-weight: 700;
  font-size: 255px;
  line-height: 220px;
}
@media (max-width: 767px) {
  .sol-error-page-text-big {
    font-size: 150px;
  }
}
.sol-error-page-text-small {
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 34px;
  line-height: 40px;
}
.sol-error-page .sol-btn {
  margin-top: 20px;
}
.sol-error-page .sol-btn:first-child {
  margin-right: 10px;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__title input.sol-form-control::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
  height: 160px;
  margin-bottom: 10px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__description #eventDescription::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
  height: 100px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__preamble #eventPreamble::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__place input.sol-form-control::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
  height: 160px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__info #ticketInfo::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 75%;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-new-event__ticket__link input.sol-form-control::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader {
  margin-bottom: 50px;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader input {
  opacity: 0;
  height: 0;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__upload-btn {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-size: 17px;
  background-color: #0071BB;
  color: #fff;
  padding: 9px 20px;
  border-radius: 0;
  border: none;
}
.sol-create-new-event-page .sol-new-event-form .sol-file-uploader__upload-btn:hover {
  background-color: #e5f0f8;
  color: #0071BB;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset {
  padding: 20px;
  width: 75%;
  border-color: rgba(0, 113, 187, 0.5);
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset {
    padding: 8px;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset legend {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-weight: 700;
  padding: 0 10px;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group input.sol-form-control {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  width: 100%;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset .sol-form-group input.sol-form-control::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #9e9e9e;
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend {
  display: block;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend {
    display: none;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile {
  display: none;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile {
    display: block;
  }
}
.sol-create-new-event-page .sol-new-event-form .sol-fieldset__legend--mobile.details {
  font-size: 12px;
  white-space: nowrap;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -10px;
}
.sol-create-new-event-page .sol-new-event-form button[type='submit'] {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  border: 1px solid #0071BB;
  font-size: 17px;
  font-weight: 400;
  background-color: #0071BB;
  color: #fff;
  padding: 9px 20px;
  margin-bottom: 20px;
}
.sol-create-new-event-page .sol-new-event-form button[type='submit']:hover {
  background-color: #e5f0f8;
  color: #0071BB;
  border: 1px solid #0071BB;
}
.sol-create-new-event-page .sol-eventlist-filters__areas,
.sol-create-new-event-page .sol-eventlist-filters__categories {
  width: 75%;
  margin-bottom: 30px;
  margin-top: 0;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-eventlist-filters__areas,
  .sol-create-new-event-page .sol-eventlist-filters__categories {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-eventlist-filters__areas fieldset,
.sol-create-new-event-page .sol-eventlist-filters__categories fieldset {
  padding: 0;
  border-top: none;
  border-color: rgba(0, 113, 187, 0.5);
}
.sol-create-new-event-page .sol-eventlist-filters__areas--header,
.sol-create-new-event-page .sol-eventlist-filters__categories--header {
  background-color: rgba(0, 113, 187, 0.2);
  position: relative;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--header i,
.sol-create-new-event-page .sol-eventlist-filters__categories--header i {
  position: absolute;
  font-size: 19px;
  top: 10px;
  left: 8px;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--header legend,
.sol-create-new-event-page .sol-eventlist-filters__categories--header legend {
  padding: 10px 30px;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li {
  flex: 0 0 50%;
  padding: 16px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e0e0e0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li:nth-child(odd),
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li:nth-child(odd) {
  border-right: 1px solid #e0e0e0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li label,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li label {
  position: relative;
  padding-left: 32px;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li label:before,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li label:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: rgba(224, 224, 224, 0.3);
  border: 1px solid #9e9e9e;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li label input,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li label input {
  width: 18px;
  height: 18px;
  position: relative;
  top: 0;
  border-radius: 0;
  display: none;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li label input:checked + span,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li label input:checked + span {
  position: absolute;
  top: -1px;
  left: 3px;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--categorylist li label input:checked + span:before,
.sol-create-new-event-page .sol-eventlist-filters__categories--categorylist li label input:checked + span:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  color: #0071BB;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li {
  flex: 1 0 50%;
  padding: 16px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #e0e0e0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li:nth-child(even),
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li:nth-child(even) {
  border-left: 1px solid #e0e0e0;
}
.sol-create-new-event-page .sol-eventlist-filters__areas--arealist li input,
.sol-create-new-event-page .sol-eventlist-filters__categories--arealist li input {
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
}
.sol-create-new-event-page .dates-checkbox label {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 600;
  color: #0071BB;
}
.sol-create-new-event-page .sol-form-group__error-msg,
.sol-create-new-event-page .sol-form-group__error-message {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  width: 100%;
  color: tomato;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .sol-create-new-event-page .sol-form-group__error-msg,
  .sol-create-new-event-page .sol-form-group__error-message {
    width: 100%;
  }
}
.sol-create-new-event-page .sol-add-new-date-time {
  margin-bottom: 30px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-size: 17px;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  color: #fff;
  padding: 9px 20px;
  border-radius: 0;
  border: 0;
}
.sol-create-new-event-page .sol-add-new-date-time:hover {
  background-color: #e5f0f8;
  color: #0071BB;
}
.sol-create-new-event-page .sol-remove-date-time-btn {
  width: 36px;
  height: 36px;
  background-color: #D12E65;
  border: none;
  margin-top: 10px;
}
.sol-create-new-event-page .sol-remove-date-time-btn i {
  font-size: 21px;
  color: #fff;
  padding-top: 5px;
}
#recommended-image-size {
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #888888;
  margin-bottom: 15px;
}
.sol-file-uploader__label {
  margin-bottom: 0 !important;
}
.sol-new-event__multiple-dates,
.sol-new-event__multiple-days {
  max-width: 75%;
  display: block;
  padding: 5px 10px;
  background-color: #F8F8F8;
  border: 1px solid #E7E7E7;
}
@media (max-width: 991px) {
  .sol-new-event__multiple-dates,
  .sol-new-event__multiple-days {
    max-width: none;
  }
}
.sol-new-event__multiple-dates fieldset,
.sol-new-event__multiple-days fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.sol-new-event__multiple-days {
  margin-bottom: 10px !important;
}
.sol-new-event__consent {
  max-width: 75%;
}
@media (max-width: 991px) {
  .sol-new-event__consent {
    max-width: none;
  }
}
.sol-new-event__consent fieldset {
  border-color: rgba(0, 113, 187, 0.5);
}
.sol-new-event__consent fieldset legend {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 700;
  padding: 0 10px;
}
.sol-new-event__consent fieldset legend:after {
  content: "*";
  font-size: 16px;
  color: red;
  margin-left: 5px;
}
.sol-new-event__multiple-dates,
.sol-new-event__consent,
.sol-new-event__multiple-days {
  margin: 10px 10px 10px 0;
}
.sol-new-event__multiple-dates fieldset,
.sol-new-event__consent fieldset,
.sol-new-event__multiple-days fieldset {
  padding: 8px 16px 16px;
}
@media (max-width: 767px) {
  .sol-new-event__multiple-dates,
  .sol-new-event__consent,
  .sol-new-event__multiple-days {
    max-width: none;
  }
}
.sol-new-event__multiple-dates input,
.sol-new-event__consent input,
.sol-new-event__multiple-days input {
  width: 18px;
  height: 18px;
  position: relative;
  top: 0;
  border-radius: 0;
  display: none;
}
.sol-new-event__multiple-dates input + label,
.sol-new-event__consent input + label,
.sol-new-event__multiple-days input + label {
  position: relative;
  padding-left: 32px;
  margin-top: 10px;
  display: block;
}
.sol-new-event__multiple-dates input + label:before,
.sol-new-event__consent input + label:before,
.sol-new-event__multiple-days input + label:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: rgba(224, 224, 224, 0.3);
  border: 1px solid #9e9e9e;
}
.sol-new-event__multiple-dates input:checked + label:before,
.sol-new-event__consent input:checked + label:before,
.sol-new-event__multiple-days input:checked + label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-align: center;
  color: #0071BB;
}
.sol-new-event__multiple-dates label,
.sol-new-event__consent label,
.sol-new-event__multiple-days label {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  display: inline;
  font-weight: 400;
}
.sol-new-event__multiple-dates legend,
.sol-new-event__consent legend,
.sol-new-event__multiple-days legend {
  font-size: 15px;
  display: block;
  font-weight: 700;
  margin-top: 8px;
}
.sol-new-event-form > div label[required="required"]::after,
.sol-new-event-form > div legend[required="required"]::after,
.sol-new-event-form .sol-form-group label[required="required"]::after {
  display: inline-block;
  content: "\002a";
  color: red;
  font-size: 14px;
  margin-left: 3px;
}
.sol-new-event__date-time__startDate,
.sol-new-event__date-time__endDate {
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  padding: 10px 14px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: black;
  margin-top: 0px;
  margin-bottom: 25px;
}
.sol-new-event__date-time__startDate::placeholder,
.sol-new-event__date-time__endDate::placeholder {
  color: rgba(158, 158, 158, 0.2);
}
.sol-new-event__date-time label {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-top: 8px;
}
.dateLabel {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  display: block;
  font-weight: 700;
  margin-bottom: 5px;
}
.dateLabel:after {
  display: inline-block;
  content: "\002a";
  color: red;
  font-size: 14px;
  margin-left: 3px;
}
.sol-new-event__date-time {
  padding: 10px 14px;
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 10px;
  width: 75%;
}
@media (max-width: 991px) {
  .sol-new-event__date-time {
    width: 100%;
  }
}
.sol-new-event__date-time .application--wrap {
  min-height: 100px;
}
.sol-new-event__date-time .application--wrap .layout.row.wrap {
  margin-left: 20px !important;
}
.sol-new-event__date-time .spacer {
  max-width: 50px;
}
.sol-new-event__date-time .input-group--text-field.input-group--prepend-icon .input-group__details {
  max-width: none !important;
}
.sol-new-event__date-time button.btn {
  border-radius: 0;
  line-height: 15px;
  background-color: rgba(0, 113, 187, 0.2);
  border: 1px solid #0071BB;
}
.sol-new-event__date-time button.btn--icon {
  background-color: transparent;
}
.sol-new-event__date-time button.btn--icon:hover {
  background-color: #e5f0f8;
  color: #0071BB;
}
.sol-new-event__date-time button.btn:hover {
  text-decoration: none;
  background-color: rgba(0, 113, 187, 0.4);
  border-radius: 0;
}
.sol-new-event__date-time button.btn span:before,
.sol-new-event__date-time button.btn div:before {
  border-radius: 0;
}
.sol-new-event__date-time .picker--date__header-selector-date {
  font-size: 18px;
  margin-bottom: 15px;
}
.sol-new-event__date-time .picker--time__clock > span {
  top: calc(44%);
  left: calc(44%);
}
.sol-new-event__date-time label:after {
  font-size: 17px;
  color: red;
  margin-left: 5px;
}
.sol-file-uploader__file-name {
  color: rgba(0, 113, 187, 0.5);
  font-size: 15px;
  font-weight: 600;
}
.sol-file-uploader__file-name:before {
  content: "\f03e";
  font-family: "Font Awesome 5 Pro";
  margin-right: 5px;
  font-size: 19px;
  color: black;
}
.sol-file-uploader__remove-btn {
  font-weight: 300;
  font-size: 17px;
  padding: 6px 16px;
  border-radius: 0;
  margin-left: 10px;
  background-color: #D12E65 !important;
  color: white;
}
.sol-file-uploader__error-message {
  font-size: 15px;
  display: block;
  width: 75%;
  color: #e5f0f8;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: -20px;
}
.event-one-line {
  font-weight: 400 !important;
  display: inline-flex;
  margin: 0 !important;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .event-one-line {
    display: inline;
  }
}
.default-font {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
}
.default-border-and-background {
  border: 1px solid #e0e0e0;
  background-color: rgba(158, 158, 158, 0.1);
}
.default-spacing {
  padding: 10px 14px;
  margin-bottom: 20px;
}
.asd__fade-enter-active,
.asd__fade-leave-active {
  transition: all 0.2s ease;
}
.asd__fade-enter,
.asd__fade-leave-active {
  opacity: 0;
}
.asd__list-complete-enter,
.asd__list-complete-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.asd__list-complete-leave-active {
  position: absolute;
  visibility: hidden;
}
.datepicker-trigger {
  position: relative;
  overflow: visible;
}
.asd__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  background-color: #fff;
}
.asd__wrapper *,
.asd__wrapper :after,
.asd__wrapper :before {
  box-sizing: border-box;
}
.asd__wrapper--full-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: none;
  z-index: 2;
}
.asd__inner-wrapper {
  transition: all 0.3s ease;
  position: relative;
}
.asd__datepicker-header,
.asd__keyboard-shortcuts-trigger-wrapper {
  position: relative;
}
.asd__keyboard-shortcuts-trigger {
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  font: inherit;
  border-width: 26px 33px 0 0;
  border-top: 26px solid transparent;
  border-right: 33px solid #0070BA;
}
.asd__keyboard-shortcuts-trigger span {
  color: #fff;
  position: absolute;
  bottom: 0;
  right: -28px;
}
.asd__keyboard-shortcuts-show {
  display: block !important;
}
.asd__keyboard-shortcuts-close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 7px;
  right: 5px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
}
.asd__keyboard-shortcuts-menu {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-image: initial;
  border-radius: 2px;
  padding: 22px;
  margin: 33px;
  text-align: left;
}
.asd__keyboard-shortcuts-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.asd__keyboard-shortcuts-list {
  list-style: none;
  margin: 6px 0;
  padding: 0;
  white-space: normal;
}
.asd__keyboard-shortcuts-symbol {
  font-family: monospace;
  font-size: 12px;
  text-transform: uppercase;
  background: #f2f2f2;
  padding: 2px 6px;
  margin-right: 4px;
}
.asd__change-month-button {
  position: absolute;
  top: 12px;
  z-index: 1;
  background: #fff;
}
.asd__change-month-button--previous {
  left: 0;
  padding-left: 15px;
}
.asd__change-month-button--next {
  right: 0;
  padding-right: 15px;
}
.asd__change-month-button > button {
  background-color: #fff;
  border: 1px solid #e4e7e7;
  border-radius: 3px;
  padding: 4px 8px;
  cursor: pointer;
}
.asd__change-month-button > button:hover {
  border: 1px solid #c4c4c4;
}
.asd__change-month-button > button > svg {
  height: 19px;
  width: 19px;
  fill: #82888a;
}
.asd__days-legend {
  position: absolute;
  top: 50px;
  left: 10px;
  padding: 0 10px;
}
.asd__day-title {
  display: inline-block;
  width: 14.28571%;
  text-align: center;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.8em;
  margin-left: -1px;
}
.asd__month-table {
  border-collapse: collapse;
  border-spacing: 0;
  background: #fff;
  width: 100%;
  max-width: 100%;
}
.asd__month {
  transition: all 0.3s ease;
  display: inline-block;
  padding: 15px;
}
.asd__month--hidden {
  height: 275px;
  visibility: hidden;
}
.asd__month-name {
  font-size: 1.3em;
  text-align: center;
  margin: 0 0 30px;
  line-height: 1.4em;
  font-weight: 700;
}
.asd__month-year-select {
  -webkit-appearance: none;
  border: none;
  background-color: inherit;
  cursor: pointer;
  color: blue;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
}
.asd__month-year-select::-ms-expand {
  display: none;
}
.asd__day {
  line-height: 38px;
  height: 38px;
  padding: 0;
  overflow: hidden;
}
.asd__day--enabled {
  border: 1px solid #e4e7e7;
}
.asd__day--enabled:hover {
  background-color: #e4e7e7;
}
.asd__day--enabled:focus {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}
.asd__day--disabled,
.asd__day--empty {
  opacity: 0.5;
}
.asd__day--disabled button,
.asd__day--empty button {
  cursor: default;
}
.asd__day--empty {
  border: none;
}
.asd__day--disabled:hover {
  background-color: transparent;
}
.asd__day-button {
  background: transparent;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  color: inherit;
  text-align: center;
  user-select: none;
  font-size: 15px;
  font-weight: inherit;
  padding: 0;
}
.asd__action-buttons {
  min-height: 50px;
  padding-top: 10px;
  margin-bottom: 12px;
}
.asd__action-buttons button {
  display: block;
  position: relative;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
}
.asd__action-buttons button:hover {
  text-decoration: underline;
}
.asd__action-buttons button:first-child {
  float: left;
  left: 15px;
}
.asd__action-buttons button:nth-child(2) {
  float: right;
  right: 15px;
}
.asd__mobile-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 15px!important;
  text-align: center;
  height: 50px;
}
.asd__mobile-header h3 {
  font-size: 20px;
  margin: 0;
}
.asd__mobile-only {
  display: none;
}
@media (max-width: 600px) {
  .asd__mobile-only {
    display: block;
  }
}
.asd__mobile-close {
  border: none;
  position: absolute;
  top: 7px;
  right: 5px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
}
.asd__mobile-close__icon {
  position: relative;
  font-size: 1.6em;
  font-weight: 700;
  padding: 0;
}
/*# sourceMappingURL=vue-airbnb-style-datepicker.min.css.map */
.asd__day.asd__day--enabled.asd__day--selected button {
  background-color: #0071BB;
}
.sol-events-listing-page li.sv-channel-item {
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}
@media (max-width: 767px) {
  .sol-events-listing-page li.sv-channel-item {
    padding: 20px 0 5px;
  }
}
.sol-events-listing-page .sol-article-item {
  position: relative;
  min-height: 180px;
  padding: 12px 0 0 330px;
}
@media (max-width: 991px) {
  .sol-events-listing-page .sol-article-item {
    padding: 0;
  }
}
.sol-events-listing-page .sol-article-item.sol-no-img {
  padding-right: 60px;
  padding-left: 20px;
}
.sol-events-listing-page .sol-article-item-img {
  left: 0;
}
@media (max-width: 991px) {
  .sol-events-listing-page .sol-article-item-img {
    position: relative;
    overflow: hidden;
  }
}
.sol-events-listing-page .sol-article-item.sol-no-img .sol-events-listing-page .sol-article-item-date {
  background-color: #0071BB;
}
.sol-events-listing-page .sol-article-item-heading {
  margin-bottom: 16px;
  line-height: normal;
}
.sol-events-listing-page .sol-article-item-event-info {
  margin-top: 10px;
}
.sol-events-listing-page .sol-article-item-event-info::after,
.sol-events-listing-page .sol-article-item-event-info::before {
  display: table;
  content: '';
}
.sol-events-listing-page .sol-article-item-event-info::after {
  clear: both;
}
@media (max-width: 767px) {
  .sol-events-listing-page .sol-article-item-event-info {
    position: relative;
    top: unset;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
}
.sol-events-listing-page .sol-article-item-event-info-item {
  float: left;
  margin-right: 0;
  font-size: 11px;
}
.sol-events-listing-page .sol-article-item-event-info-item i {
  margin-right: 6px;
  color: #0071BB;
}
.sol-events-listing-page .sol-article-item-event-info-item.date {
  position: absolute;
  top: calc(50% - (78px / 2));
  right: -36px;
  z-index: 2;
  display: block;
  width: 78px;
  height: 78px;
  padding-top: 17px;
  color: #fff;
  background-color: #D12E65;
  border: 2px solid #fff;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .sol-events-listing-page .sol-article-item-event-info-item.date {
    top: auto;
    bottom: -20px;
    left: -15px;
    z-index: 2;
    margin-left: 0;
  }
}
.sol-events-listing-page .sol-article-item-event-info-item.date .sol-article-item-date-day {
  display: block;
  margin-bottom: 2px;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .sol-events-listing-page .sol-article-item-event-info-item.date .sol-article-item-date-day {
    position: relative;
    top: -4px;
    left: 28px;
    display: inline-block;
    margin-bottom: 0;
    font-size: 19px;
  }
}
.sol-events-listing-page .sol-article-item-event-info-item.date .sol-article-item-date-month {
  display: block;
  font-weight: 300;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .sol-events-listing-page .sol-article-item-event-info-item.date .sol-article-item-date-month {
    position: relative;
    top: 13px;
    left: 3px;
    display: inline-block;
    font-size: 15px;
  }
}
.sol-events-listing-page .sol-article-item-event-info-item.startTime {
  position: relative;
  display: inline-block;
  float: none;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-size: 16px;
}
.sol-events-listing-page .sol-article-item-event-info-item.startTime i {
  color: #D12E65;
  font-size: 20px;
}
.sol-events-listing-page .sol-article-item-event-info-item.endTime {
  position: relative;
  display: inline-block;
  float: none;
  color: #2c2c2c;
  font-size: 16px;
}
.sol-events-listing-page .sol-article-item-event-info-item.endTime i {
  color: #D12E65;
  font-size: 20px;
}
.sol-events-listing-page .sol-article-item-event-info-item.location {
  position: relative;
  float: none;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-size: 16px;
}
.sol-events-listing-page .sol-article-item-event-info-item.location i {
  color: #D12E65;
  font-size: 20px;
}
.sol-events-listing-page .sol-article-item-event-info-item.inline-date {
  position: relative;
  display: block;
  float: none;
  margin-bottom: 5px;
  color: #2c2c2c;
  font-size: 16px;
}
.sol-events-listing-page .sol-article-item-event-info .sol-deviding-dash {
  display: inline-block;
  margin-left: 3px;
  font-size: 16px;
}
@media (max-width: 991px) {
  .sol-events-listing-page .sol-article-item-content {
    margin-top: 16px;
    margin-bottom: 12px;
  }
}
.sol-event-pink p.font-btn {
  margin-bottom: 0;
  padding-bottom: 0;
}
.sol-event-pink p.font-btn a:hover {
  background-color: #D12E65;
  border: 1px solid #D12E65;
}
.sol-menu-container.pinboard .sol-menu-item .sol-menu-button {
  right: 10px;
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  background: #3f4458;
  -webkit-box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c, 0 -1px 0 #20222c, 0 3px 13px rgba(0,0,0,0.08);
          box-shadow: 1px 0 0 #20222c, -1px 0 0 #20222c, 0 1px 0 #20222c, 0 -1px 0 #20222c, 0 3px 13px rgba(0,0,0,0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
          animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
          box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #20222c;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #20222c;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #3f4458;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #20222c;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #3f4458;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: #3f4458;
  color: #fff;
  fill: #fff;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #fff;
  fill: #fff;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  left: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
/*
      /*rtl:begin:ignore*/
/*
      */
  right: 0;
/*
      /*rtl:end:ignore*/
/*
      */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #eee;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(255,255,255,0.15);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(192,187,167,0.1);
}
.numInputWrapper span:active {
  background: rgba(192,187,167,0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(255,255,255,0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255,255,255,0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(255,255,255,0.5);
}
.numInputWrapper:hover {
  background: rgba(192,187,167,0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(192,187,167,0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255,255,255,0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #3f4458;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(192,187,167,0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #3f4458;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #3f4458;
  color: #fff;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #20222c;
          box-shadow: -1px 0 0 #20222c;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(255,255,255,0.95);
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
      -ms-flex-preferred-size: 14.2857143%;
          flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #646c8c;
  border-color: #646c8c;
}
.flatpickr-day.today {
  border-color: #eee;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #eee;
  background: #eee;
  color: #3f4458;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #80cbc4;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  border-color: #80cbc4;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #80cbc4;
          box-shadow: -10px 0 0 #80cbc4;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
          box-shadow: -5px 0 0 #646c8c, 5px 0 0 #646c8c;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(255,255,255,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(255,255,255,0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
          box-shadow: -5px 0 0 #80cbc4, 5px 0 0 #80cbc4;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #20222c;
          box-shadow: 1px 0 0 #20222c;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(255,255,255,0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255,255,255,0.95);
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255,255,255,0.95);
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: rgba(255,255,255,0.95);
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: rgba(255,255,255,0.95);
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #6a7395;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
  border-radius: 0;
}
.flatpickr-months .flatpickr-month {
  height: 35px;
}
span.flatpickr-day:not(.startRange):not(.inRange) + span.flatpickr-day.selected.endRange {
  border-radius: 50px;
}
span.flatpickr-day.startRange + span.flatpickr-day.selected.endRange:hover {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  color: white;
  background: #0071BB;
  border-color: #e5f0f8;
}
.flatpickr-day.inRange {
  box-shadow: -5px 0 0 #f2f2f2, 5px 0 0 #f2f2f2;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  color: #fff;
  background: #0071BB;
  border-color: #e5f0f8;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #0071BB;
}
.sol-vuejobs__transition-enter-active,
.sol-vuejobs__transition-leave-active {
  transition: opacity 0.2s;
}
.sol-vuejobs__transition-enter,
.sol-vuejobs__transition-leave-to {
  opacity: 0%;
}
.sol-vuejobs__list-sorting {
  margin-bottom: 20px;
}
.sol-vuejobs__list-sorting label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}
.sol-vuejobs__list-filters {
  margin-bottom: 20px;
}
.sol-vuejobs__list-filters label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}
.sol-vuejobs__list ul {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  list-style-type: none;
}
.sol-vuejobs__list-item {
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}
.sol-vuejobs__list-item:first-of-type {
  border-top: 1px solid #e0e0e0;
}
.sol-vuejobs__list-item h2 {
  font-weight: 300;
  font-size: 24px;
}
.sol-vuejobs__list-item h2 a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sol-vuejobs__list-item h2 a::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  display: inline-block;
  font-size: 24px;
  content: "\f054";
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: #0071BB;
  font-weight: 300;
  background-color: rgba(0, 113, 187, 0.1);
  border-radius: 50%;
  transition-timing-function: ease;
  transition-duration: 400ms;
  transition-property: background-color, color;
}
.sol-vuejobs__list-item h2 a::before.fa-pull-left {
  margin-right: 0.3em;
}
.sol-vuejobs__list-item h2 a::before.fa-pull-right {
  margin-left: 0.3em;
}
@media (max-width: 767px) {
  .sol-vuejobs__list-item h2 a::before {
    width: 23px;
    height: 23px;
    font-size: 16px;
  }
}
.sol-vuejobs__list-item h2 a:hover span {
  text-decoration: underline;
}
.sol-vuejobs__list-item h2 a:hover::before {
  color: #fff;
  background-color: #0071BB;
}
.sol-vuejobs__list-item h3 {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
}
.sol-vuejobs__application-metadata {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}
.sol-vuejobs__application-metadata-item {
  display: flex;
  margin-bottom: 8px;
}
@media (max-width: 479px) {
  .sol-vuejobs__application-metadata-item {
    flex-direction: column;
  }
}
.sol-vuejobs__application-metadata-item > div {
  margin-right: 8px;
}
.sol-vuejobs__show-more {
  display: flex;
  justify-content: center;
}
.sol-vuejobs__show-more button {
  padding: 9px 16px;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  border: 1px solid #2c2c2c;
  cursor: pointer;
}
.sol-vuejobs__show-more button:hover {
  color: #fff;
  background-color: #0071BB;
  border-color: #0071BB;
}
.sol-vuejobs__assignment-sharing {
  margin-bottom: 16px;
}
.sol-vuejobs__assignment-sharing-links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  color: #0071BB;
  font-size: 22px;
  text-decoration: none;
  background-color: rgba(0, 113, 187, 0.1);
  border-radius: 50%;
  transition-timing-function: ease;
  transition-duration: 400ms;
  transition-property: background-color, color;
}
.sol-vuejobs__assignment-sharing-links a:hover {
  color: #fff;
  background-color: #0071BB;
}
.sol-vuejobs__assignment-details section {
  margin-bottom: 20px;
}
.sol-vuejobs__assignment-details-item {
  margin-bottom: 8px;
  font-size: 15px;
}
.sol-vuejobs__assignment-details-item-name {
  margin-right: 8px;
  font-weight: bold;
}
.sol-vuejobs__assignment-details-item-value {
  font-weight: 400;
  font-size: 15px;
}
.sol-vuejobs__assignment-apply-link {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
.sol-vuejobs__assignment-apply-link a {
  position: relative;
  display: block;
  height: auto;
  min-height: 42px;
  padding: 5px 50px 5px 10px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.4px;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #9e9e9e;
  border-radius: 0;
}
@media (max-width: 767px) {
  .sol-vuejobs__assignment-apply-link a {
    font-size: 17px;
  }
}
.sol-vuejobs__assignment-apply-link a::after {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 30px;
  height: 27px;
  padding-top: 7px;
  padding-left: 4px;
  color: #fff;
  font-weight: 100;
  font-size: 19px;
  font-family: 'Font Awesome 5 Pro';
  line-height: normal;
  text-align: center;
  background-color: #508128;
  border-radius: 50%;
  transform: translateY(-50%);
  content: '\f054';
}
.sol-vuejobs__assignment-apply-link a:hover {
  color: #fff;
  text-decoration: underline;
  background-color: #508128;
  border: 1px solid #508128;
}
.sol-vuejobs__assignment-apply-link a:hover::after {
  color: #508128;
  background-color: #fff;
}
.sol-vuejobs__assignment-apply-link a i.fa-external-link {
  display: inline;
}
.sol-vuejobs__assignment-apply-link a i.fa-external-link::before {
  position: relative;
  top: -3px;
  margin-left: 8px;
  font-size: 14px;
}
.sol-vuejobs__assignment-details-contact {
  margin-bottom: 8px;
  font-size: 15px;
}
.sol-vuejobs__assignment-details-contact-name {
  font-weight: bold;
}
.sol-vuejobs__assignment-description {
  margin-top: 35px;
  border-top: 2px solid #e0e0e0;
}
.sol-vuejobs__assignment-description h2 {
  font-weight: 300;
  font-size: 48px;
  line-height: 54px;
}
.sol-vuejobs__assignment-description h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 1.2em;
}
.sv-login-portlet.sol-form-decoration {
  padding-bottom: 0;
}
.sv-login-portlet.sol-form-decoration fieldset {
  margin: 0;
  padding: 0;
}
.sol-news-listing-page ul.sv-defaultlist {
  margin: 30px 0;
}
.sol-news-listing-page ul.sv-defaultlist li.sv-channel-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}
.sol-news-listing-page ul.sv-defaultlist li.sv-channel-item:last-of-type {
  border-bottom: none;
}
.sol-right.sol-show-sm {
  display: block;
  margin-top: -10px;
  margin-bottom: 30px;
}
.sol-pinboard .sv-defaultlist .sv-channel-item {
  margin: 10px 0;
  padding: 0;
  padding-top: 35px;
  border-top: 1px solid #e0e0e0;
}
.sol-pinboard .sv-defaultlist .sv-channel-item:last-of-type {
  border-bottom: 1px solid #e0e0e0;
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item {
  display: flex;
  flex-wrap: wrap;
  min-height: 110px;
  padding: 0;
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date {
  flex: 0 0 78px;
  height: 78px;
  padding-top: 15px;
  text-align: center;
  border: 2px solid white;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date {
    flex: 0 0 58px;
    height: 58px;
    padding-top: 8px;
  }
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date-month {
  display: block;
  color: #fff;
  font-weight: 300;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date-month {
    font-size: 15px;
  }
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date-day {
  display: block;
  margin-bottom: 2px;
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-date-day {
    margin-bottom: 0;
    font-size: 21px;
  }
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-content {
  flex: 1 0;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-content {
    padding: 0 0 0 10px;
  }
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-heading {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-heading {
    margin-bottom: 8px;
  }
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-desc {
  display: -webkit-box;
  /* autoprefixer: on */
  margin-bottom: 15px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
.sol-pinboard .sv-defaultlist .sv-channel-item .sol-article-item-event-info-item {
  font-size: 15px;
}
.sv-standardsearch-portlet .sol-did-you-mean,
.sv-standardsearch-portlet .sol-num-hits {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 12px;
}
.sv-standardsearch-portlet .sv-search-hit {
  position: relative;
  margin-bottom: 20px;
  padding: 20px 50px 20px 40px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 4px 0 rgb(0 0 0%);
}
.sv-standardsearch-portlet .sv-search-hit .font-heading-3 {
  display: block;
  margin-top: 0;
  margin-bottom: 3px;
  color: #2c2c2c;
  font-weight: 800;
  font-size: 15px;
  text-decoration: none;
}
.sv-standardsearch-portlet .sv-search-hit .font-heading-3:hover {
  color: #0071BB;
  text-decoration: underline;
}
.sv-standardsearch-portlet .sv-search-hit .font-normal {
  font-size: 15px;
  line-height: 1.4em;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text {
  font-size: 13px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fa,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fal,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.far,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fas,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fab {
  margin-right: 5px;
  margin-left: 10px;
  color: #0071BB;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fa:first-child,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fal:first-child,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.far:first-child,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fas:first-child,
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text i.fab:first-child {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text > strong {
  margin-left: 10px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-info-text > strong:first-of-type {
  margin-left: 0;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text {
  font-size: 12px;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text a {
  color: #0071BB;
}
.sv-standardsearch-portlet .sv-search-hit .sol-hit-path-text a:hover {
  color: #2c2c2c;
  text-decoration: underline;
}
.sol-section form.sol-top-search-field input {
  border-radius: 0;
}
.sol-section form.sol-top-search-field button {
  right: -14px;
}
.sol-section ol.sv-search-result {
  max-width: 870px;
}
.sol-section ol.sv-search-result .sv-search-hit {
  padding-top: 20px !important;
  border-bottom: 1px solid #e5f0f8;
  border-radius: 0;
}
.sol-section ol.sv-search-result .sv-search-hit img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.sol-section ol.sv-search-result .sv-search-hit p:last-of-type {
  margin-top: 0;
  font-weight: 400;
  font-size: 17px;
}
@media (max-width: 767px) {
  .sol-section ol.sv-search-result .sv-search-hit p:last-of-type {
    font-size: 14px;
  }
}
.pagination {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 420px;
  margin-top: 20px;
  padding-bottom: 50px;
}
.pagination::after,
.pagination::before {
  display: table;
  content: '';
}
.pagination::after {
  clear: both;
}
.pagination a,
.pagination span.current {
  width: 36px;
  height: 36px;
  margin-top: 0;
  margin-right: 10px;
  margin-left: 10px;
  padding: 8px 0 0;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  box-shadow: none;
}
.pagination a:hover,
.pagination span.current:hover {
  color: #0071BB;
  background-color: #e5f0f8;
}
.pagination span.current,
.pagination span.current:hover {
  color: #fff;
  font-weight: 700;
  background-color: #0071BB;
}
.pagination span.current strong,
.pagination span.current:hover strong {
  font-weight: 300;
}
.pagination > *:first-child:not(span),
.pagination > *:last-child:not(span) {
  position: absolute;
  top: 40px;
  width: auto;
  height: auto;
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 6px 16px;
  color: white;
  font-size: 17px;
  text-decoration: none;
  background-color: #0071BB;
  border: 1px solid #0071BB;
  border-radius: 0;
}
.pagination > *:first-child:not(span).prev,
.pagination > *:last-child:not(span).prev {
  left: 0;
}
.pagination > *:first-child:not(span).next,
.pagination > *:last-child:not(span).next {
  right: 100px;
}
.pagination > *:first-child:not(span):hover,
.pagination > *:last-child:not(span):hover {
  color: #0071BB;
  background-color: transparent;
}
.pagination span.current.next,
.pagination span.current.prev {
  display: none;
}
.sv-facetedsearch-portlet > div:last-of-type > p:first-of-type {
  margin-top: 10px;
  font-size: 16px;
}
.sv-facetedsearch-portlet > div:last-of-type > p:first-of-type strong {
  margin: 0 4px;
}
.sv-facetedsearch-portlet > div:last-of-type > div:first-of-type {
  margin-top: 20px;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet > div:last-of-type > div:first-of-type {
    margin-top: 35px;
    margin-bottom: 20px;
  }
}
.sv-facetedsearch-portlet ol.sv-search-result {
  max-width: none;
}
.sv-facetedsearch-portlet .sol-did-you-mean,
.sv-facetedsearch-portlet .sol-num-hits {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}
.sv-facetedsearch-portlet .sol-num-hits {
  margin-bottom: 20px;
  font-weight: 400;
}
.sv-facetedsearch-portlet .font-btn {
  background: none;
  border: none;
  appearance: none;
  padding: 14px 20px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  line-height: 10px;
  text-transform: none;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: none;
  cursor: pointer;
}
.sv-facetedsearch-portlet .font-btn:hover {
  color: #fff;
  background-color: #0071BB;
}
.sv-facetedsearch-portlet .font-btn i::before {
  position: relative;
  top: 1px;
}
.sv-facetedsearch-portlet .sv-search-facets {
  display: flex;
  justify-content: space-between;
  order: 0;
  float: none;
  width: 100%;
  max-width: none !important;
  margin-top: 0.7em;
  margin-left: 0;
  padding: 0 !important;
  background-color: transparent;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sv-search-facets {
    flex-direction: column;
  }
}
.sv-facetedsearch-portlet .sv-search-facet {
  position: relative;
  width: 45%;
  margin-bottom: 20px;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.sv-facetedsearch-portlet .sv-search-facet:nth-child(1) {
  z-index: 5;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sv-search-facet {
    width: 100%;
  }
}
.sv-facetedsearch-portlet .sv-search-facet-name {
  display: none;
}
.sv-facetedsearch-portlet .sv-search-facet__button {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 34px;
  height: 34px;
  background: #0071BB;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sv-search-facet__button {
    right: -8px;
  }
}
.sv-facetedsearch-portlet .sv-search-facet__button::before,
.sv-facetedsearch-portlet .sv-search-facet__button::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 1.5px;
  margin-top: -1px;
  margin-left: -10px;
  background-color: #fff;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: all;
  content: '';
}
.sv-facetedsearch-portlet .sv-search-facet__button::after {
  transform: rotate(90deg);
}
.sv-facetedsearch-portlet .sv-search-facet__header {
  background: none;
  border: none;
  appearance: none;
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px 50px 16px 15px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.1;
  text-align: left;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #9e9e9e;
  cursor: pointer;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sv-search-facet__header {
    font-size: 17px;
  }
}
.sv-facetedsearch-portlet .sv-search-facet__header.sol-active {
  background-color: #0071BB;
}
.sv-facetedsearch-portlet .sv-search-facet__header.sol-active.sv-search-facet__header {
  color: #fff;
}
.sv-facetedsearch-portlet .sv-search-facet__header.sol-active .sv-search-facet__button {
  background-color: #fff;
}
.sv-facetedsearch-portlet .sv-search-facet__header.sol-active .sv-search-facet__button::after {
  background-color: #0071BB;
  transform: rotate(0deg);
}
.sv-facetedsearch-portlet .sv-search-facet-item {
  text-align: left;
  border-bottom: none;
}
.sv-facetedsearch-portlet .sv-search-facet-item-selected a::before {
  display: inline-block;
  margin-right: 10px;
  font-family: 'Font Awesome 5 Pro';
  content: '\f00d';
}
.sv-facetedsearch-portlet .sv-search-facet-items {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-bottom: 3px solid #0071BB;
}
.sv-facetedsearch-portlet .sv-search-facet-items a {
  float: none;
  margin-right: 0;
  color: #0071BB;
  font-weight: 400;
  text-decoration: underline;
}
.sv-facetedsearch-portlet .sv-search-facet-items a:hover {
  color: #2c2c2c;
}
.sv-facetedsearch-portlet .sv-search-hit {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 2px;
}
.sv-facetedsearch-portlet .sv-search-hit a {
  color: #0071BB;
  font-size: 19px;
  text-decoration: underline;
}
.sv-facetedsearch-portlet .sv-search-hit a:hover {
  color: #2c2c2c;
}
.sv-facetedsearch-portlet .sv-search-hit a span:first-child {
  display: table-cell;
}
.sv-facetedsearch-portlet .sv-search-hit a span.sv-search-hit__icon {
  display: inline-block;
  margin-left: 10px;
  padding: 4px;
  color: #fff;
  font-size: 17px;
  text-decoration: none;
  background-color: #0071BB;
}
.sv-facetedsearch-portlet .sv-search-hit p {
  margin-bottom: 10px;
}
.sv-facetedsearch-portlet .sv-search-hit .sol-hit-path-text {
  display: block;
  margin-top: 5px;
  font-size: 15px;
}
.sv-facetedsearch-portlet .sv-search-hit .sol-hit-path-text a {
  font-weight: 400;
  text-decoration: underline;
}
.sv-facetedsearch-portlet .sv-search-hit .sol-hit-path-text img {
  display: none;
}
.sv-facetedsearch-portlet .sv-search-hit .sol-hit-info-text {
  font-weight: 400;
  font-size: 15px;
}
.sv-facetedsearch-portlet form {
  position: relative;
  max-width: none;
}
.sv-facetedsearch-portlet form input[type='text'] {
  background: none;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 0 25px;
  font-weight: 300;
  font-size: 25px;
  line-height: 34px;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: border-color;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet form input[type='text'] {
    padding: 0 0 0 15px;
    font-size: 16px;
  }
}
.sv-facetedsearch-portlet form button[type='submit'] {
  background: none;
  appearance: none;
  position: absolute;
  right: 0;
  display: block;
  width: 60px;
  padding-right: 0;
  padding-left: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #0071BB;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: background-color;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet form button[type='submit'] {
    width: 50px;
  }
}
.sv-facetedsearch-portlet form button[type='submit'] i {
  position: relative;
  top: 11px !important;
  width: 60px;
  padding-bottom: 20px;
  font-weight: 300;
  font-size: 25px;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet form button[type='submit'] i {
    left: -5px;
  }
}
.sv-facetedsearch-portlet .sol-pagination {
  text-align: center;
}
.sv-facetedsearch-portlet .sol-pagination .pagination {
  display: inline-block;
  margin-top: 50px;
}
.sv-facetedsearch-portlet .sol-pagination .pagination span.current.prev,
.sv-facetedsearch-portlet .sol-pagination .pagination span.current.next {
  display: none;
}
.sv-facetedsearch-portlet .sol-top-search form {
  padding: 2px;
  background-color: #e5f0f8;
}
.sv-facetedsearch-portlet .sol-top-search form > div > button {
  top: -4px;
  right: 12px;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sol-top-search form > div > button {
    top: -2px;
  }
}
.sv-facetedsearch-portlet .sol-top-search form > div > button:focus {
  outline: none !important;
}
.sv-facetedsearch-portlet .sol-top-search form > div > input {
  height: 48px;
  color: #2c2c2c;
  font-size: 18px;
}
@media (max-width: 767px) {
  .sv-facetedsearch-portlet .sol-top-search form > div > input {
    height: 42px;
  }
}
::-webkit-search-cancel-button {
  /* select all "X" buttons in search inputs */
  width: 15px;
  height: 15px;
  /*
   * Base64 encoded custom "X" icon
   * Natively 30x30, but downscaled for highres screens
   */
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15px;
  appearance: none;
  /* hide the default "X" button */
}
.sol-menu-toggle-button {
  background: none;
  border: none;
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  padding: 5px;
  color: #fff;
  font-weight: 900;
  font-size: 11px !important;
  font-family: 'Roboto', sans-serif;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.sol-menu-toggle-button:focus,
[data-whatinput='keyboard'] .sol-menu-toggle-button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: -2px;
}
[data-whatinput='initial'] .sol-menu-toggle-button:focus,
[data-whatinput='mouse'] .sol-menu-toggle-button:focus,
[data-whatinput='touch'] .sol-menu-toggle-button:focus {
  z-index: inherit;
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-menu-toggle-button--active {
  color: #0071BB;
}
@media (max-width: 991px) {
  .sol-menu-toggle-button {
    width: 50px;
    min-width: 0;
    height: 50px;
    min-height: 0;
    font-size: 0 !important;
  }
}
@media (max-width: 380px) {
  .sol-menu-toggle-button {
    width: 40px;
  }
}
.sol-menu-toggle-button i.fa,
.sol-menu-toggle-button i.fal,
.sol-menu-toggle-button i.far,
.sol-menu-toggle-button i.fas,
.sol-menu-toggle-button i.fab {
  display: block;
  width: 100%;
  height: 28px;
  margin-bottom: 6px;
  font-size: 28px;
}
@media (max-width: 991px) {
  .sol-menu-toggle-button i.fa,
  .sol-menu-toggle-button i.fal,
  .sol-menu-toggle-button i.far,
  .sol-menu-toggle-button i.fas,
  .sol-menu-toggle-button i.fab {
    margin-bottom: 0;
  }
}
.sol-hamburger {
  width: 30px;
  padding: 0;
}
.sol-hamburger:focus,
[data-whatinput='keyboard'] .sol-hamburger:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: -2px;
}
[data-whatinput='initial'] .sol-hamburger:focus,
[data-whatinput='mouse'] .sol-hamburger:focus,
[data-whatinput='touch'] .sol-hamburger:focus {
  z-index: inherit;
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-hamburger > span {
  transition-timing-function: ease;
  transition-duration: 250ms;
  transition-property: transform, width;
  display: block;
  width: 30px;
  height: 2px;
  background-color: #2c2c2c;
}
.sol-hamburger > span:last-child {
  margin-bottom: 0;
}
.sol-hamburger .sol-hamburger__middle {
  margin-top: 7px;
  margin-bottom: 7px;
}
.sol-hamburger--active .sol-hamburger__top {
  width: 32px;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(45deg) translateX(5.5px) translateY(6.5px) translateZ(0);
}
.sol-hamburger--active .sol-hamburger__middle {
  width: 0;
}
.sol-hamburger--active .sol-hamburger__bottom {
  width: 32px;
  transform: rotateX(0deg) rotateY(0deg) rotateZ(-45deg) translateX(6.5px) translateY(-7.5px) translateZ(0);
}
.sol-menu {
  background-color: #fff;
}
.sol-menu__root-name {
  display: block;
  background-color: transparent;
  padding: 15px;
  font-size: 28px;
  line-height: 1.2em;
  font-weight: 400;
  text-decoration: none;
  color: #2c2c2c;
  border-bottom: 3px solid #0071BB;
  margin-top: 0;
  margin-bottom: 1px;
  overflow: hidden;
}
.sol-menu__root-name a {
  text-decoration: none;
}
.sol-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sol-menu-item {
  position: relative;
  font-size: 16px;
  line-height: 1;
}
.sol-menu-item a {
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  color: #2c2c2c;
  padding: 0 55px 0 20px;
  text-decoration: none;
  border-bottom: 1px solid #9e9e9e;
  height: 55px;
  transition-timing-function: ease;
  transition-duration: 200ms;
  transition-property: color;
}
.sol-menu-item a:focus,
[data-whatinput='keyboard'] .sol-menu-item a:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: -2px;
}
[data-whatinput='initial'] .sol-menu-item a:focus,
[data-whatinput='mouse'] .sol-menu-item a:focus,
[data-whatinput='touch'] .sol-menu-item a:focus {
  z-index: inherit;
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-menu-item a:hover {
  text-decoration: underline;
}
.sol-menu-item[data-expanded='true'] > a {
  color: #2c2c2c;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.sol-menu-item[data-expanded='true'][data-menu-level="1"] > a {
  background-color: rgba(224, 224, 224, 0.2);
  font-weight: 600;
  letter-spacing: 0.5px;
  border-bottom: 1px solid #e0e0e0;
}
.sol-menu-item[data-is-current='true'] > a {
  font-weight: 600;
}
.sol-menu-item[data-is-current='true'] > a::before {
  content: "";
  background-color: #0071BB;
  bottom: -1px;
  left: 0;
  position: absolute;
  top: -1px;
  transition: background-color 0.15s ease-in-out;
  width: 6px;
}
.sol-menu-item[data-menu-level='2'] {
  background-color: rgba(224, 224, 224, 0.4);
}
.sol-menu-item[data-menu-level='2'] a {
  padding-left: 31px;
  font-size: 16px;
  border-bottom-color: #fff;
}
.sol-menu-item[data-menu-level='3'] {
  background-color: rgba(224, 224, 224, 0.6);
}
.sol-menu-item[data-menu-level='3'] a {
  padding-left: 41px;
}
.sol-menu-item[data-menu-level='4'] {
  background-color: rgba(224, 224, 224, 0.8);
}
.sol-menu-item[data-menu-level='4'] a {
  padding-left: 51px;
}
.sol-menu-item[data-menu-level='5'] {
  background-color: rgba(224, 224, 224, 0.9);
}
.sol-menu-item[data-menu-level='5'] a {
  padding-left: 57px;
}
.sol-menu-item[data-expanded="true"][data-has-children="true"] > a {
  font-weight: 600;
}
.sol-menu-item__button {
  background: none;
  border: none;
  appearance: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 55px;
  width: 55px;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sol-menu-item__button:focus,
[data-whatinput='keyboard'] .sol-menu-item__button:focus {
  z-index: 10;
  outline: 2px solid #2A5A45;
  outline-offset: -2px;
}
[data-whatinput='initial'] .sol-menu-item__button:focus,
[data-whatinput='mouse'] .sol-menu-item__button:focus,
[data-whatinput='touch'] .sol-menu-item__button:focus {
  z-index: inherit;
  outline: none;
  box-shadow: none;
  appearance: none;
}
.sol-menu-item__button:hover {
  background-color: rgba(224, 224, 224, 0.4);
}
.sol-menu-item__button i.fa,
.sol-menu-item__button i.far,
.sol-menu-item__button i.fal,
.sol-menu-item__button i.fab,
.sol-menu-item__button i.fas {
  width: 30px;
  height: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding-top: 6.5px;
  padding-left: 1px;
  text-align: center;
  font-size: 17px;
  position: relative;
  top: -1px;
  transition: all 200ms ease 20ms;
}
.sol-mobile-header .sol-menu {
  position: absolute;
  top: 161px;
  width: 100%;
}
@media (max-width: 767px) {
  .sol-mobile-header .sol-menu {
    top: 55px;
  }
}
.sol-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0 0 0%);
  transition: opacity 300ms ease;
}
.sol-menu-overlay--noscript {
  overflow: auto;
}
.sol-menu-slide-enter,
.sol-menu-slide-leave,
.sol-menu-slide-leave-active {
  opacity: 0%;
}
.sol-menu-slide-enter-active {
  z-index: 9999;
}
.env-button {
  border-radius: 0;
}
.env-button--primary {
  background-color: #0071BB;
}
.env-button--ghost {
  font-weight: 400;
}
.env-button--ghost:hover {
  background-color: #0071BB;
}
.env-link {
  text-decoration: underline;
}
.env-switch__slider::before {
  top: -2px;
}
@media (max-width: 767px) {
  .env-modal-dialog p.env-text {
    font-size: 16px;
  }
}
