.sol-startpage-wrapper {
  margin-top: 0;

  @media @md {
    //  margin-top: -136px;
  }

  .sol-top {
    position: relative;
  }

  & .sol-startpage-content-bottom,
  & .sol-startpage-content-top {
    & > div:first-child {
      padding-right: 15px;

      @media @md {
        padding-right: 10px;
      }
    }

    & > div:last-child {
      padding-left: 15px;

      @media @md {
        padding-left: 10px;
      }
    }

    & .sv-column-12 {
      & .sol-row {
        margin: 30px 0;
        padding: 0 10px;

        @media @md {
          margin: 20px 0;
          padding: 0;
        }

        @media @sm {
          margin: 15px 0;
          padding: 0;
        }
      }
    }

    & .sol-col-md-4 {
      max-width: 28% !important;
      padding-right: 0;
      padding-left: 0;

      @media @md {
        max-width: 31% !important;
        margin: 0 10px;
      }

      @media @sm {
        max-width: none !important;
        margin-bottom: 24px;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  & .sol-startpage-content-bottom {
    margin-top: 35px;
    margin-bottom: 65px;
    padding: 0 20px 0 15px;

    @media @md {
      margin-top: 15px;
      padding: 0 25px 0 20px;
    }

    @media @sm {
      margin-top: 0;
      padding: 0 6px;
    }

    .sol-left {
      h2.font-heading-2 {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }

    .sol-right {
      padding-top: 33px;

      @media @sm {
        padding-top: 0;
      }
    }
  }

  & .sol-startpage-content-top {
    padding: 0 5px;

    @media @sm {
      margin-top: 10px;
      padding: 20px 16px 0;
    }
  }
}

.sv-grid-main.sv-fluid-grid .sv-row.sol-startpage-content-bottom *.sv-column-6 {
  @media (max-width: 1200px) {
    flex: 1 0 100%;
    max-width: none;
  }

  @media @sm {
    margin-bottom: 0;
  }
}
