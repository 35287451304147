.sol-tool-nav {
  position: relative;
  display: flex;

  &-item {
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;

      display: block;
      margin: 0;
      padding: 2px 16px;
      color: @header-top-text;
      font-weight: 400;
      font-size: 16px;
      font-family: @main-font;
      line-height: 22px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      @media @sm {
        color: @header-top-mobile-text;
        font-size: 13px;
      }

      @media @xs {
        padding: 2px 6px;
      }

      & i.fa,
      & i.fal,
      & i.fas,
      & i.fab,
      & i.far {
        display: block;
        margin-bottom: 8px;
        font-weight: 100;
        font-size: 24px;

        @media @sm {
          margin-bottom: 3px;
          font-size: 21px;
        }

        @media @xs {
          font-size: 18px;
        }
      }
    }

    & .sol-close {
      .focus-outline;
      .no-appearance;

      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      padding: 0;
      color: #000;
      line-height: 20px;
      text-align: center;

      & i.fa,
      & i.fal,
      & i.fas,
      & i.fab,
      & i.far {
        font-size: 11px;
      }
    }
  }

  &-box {
    position: absolute;
    z-index: 1;
    width: 230px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 5px 0 rgb(0 0 0 / 20%);
    cursor: default;

    @media @md-strict {
      right: 45px;
      margin-left: 0;
    }

    @media @sm {
      left: 50%;
      width: 215px;
      margin-left: -130px;
      padding: 12px;
    }

    & a.sol-btn-round {
      .transition(border);

      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      padding-top: 1px;
      color: @header-top-box-share-icon;
      font-size: 15px;
      text-align: center;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;

      @media @sm {
        width: 40px;
        height: 40px;
        padding-top: 0;
        font-size: 21px;
        line-height: 37px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;

      a {
        font-size: 16px;
      }
    }
  }
}

// READSPEAKER

.sv-readspeaker_expandingplayer-portlet {
  position: relative;

  & > a {
    display: none;
  }

  & div[id^='readspeaker_player'].rsexpanded {
    position: absolute;
    right: 0;
    width: auto !important;
    height: auto;
    padding: 17px 17px 22px;
    text-align: center;
    background-color: rgb(255 255 255);
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 1px 1px 5px 0;
  }
}

#readspeaker_button1 {
  &:hover {
    & a {
      color: @color-2;
    }
  }
}

.sol-header--transparent {
  & .sol-tool-nav {
    & .sol-js-toggle-search {
      display: none;
    }

    &-item-contact {
      display: block;
    }
  }
}

.sol-translate-disclaimer {
  padding-bottom: 10px;
  font-size: 13px;
}
