.rsbtn {
  display: inline-block;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    position: relative;
    top: 8px;
  }

  & a.rsbtn_play {
    .focus-outline;
    .no-appearance;
    .icon(@fa-var-volume-up, 15px, inline-block, before);

    display: inline-table;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 30px;
    color: @header-top-text;
    font-weight: 400;
    font-size: 16px;
    font-family: @main-font;
    text-decoration: none;
    cursor: pointer;

    @media @sm {
      margin: 0 0 9px;
      color: @header-top-mobile-text;
      font-size: 15px;
    }

    @media @xs {
      margin: 0 0 3px;
      font-size: 13px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      position: relative;
      top: -14px;
    }

    &::before {
      position: relative;
      top: -4px;
      display: block;
      margin-right: 5px;
      margin-bottom: 8px;
      font-weight: 100;
      font-size: 24px;
      font-family: 'Font Awesome 5 Pro';
      text-align: center;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        top: -10px;
      }

      @media @sm {
        top: -5px;
        font-size: 21px;
      }

      @media @xs {
        top: -5px;
        font-size: 18px;
      }
    }

    & .rsbtn_left {
      position: relative;
      top: -2px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        top: -5px;
      }

      @media @sm {
        top: -8px;
      }

      @media @xs {
        top: -9px;
      }
    }
  }
}
