.sol-popular-pages {
  display: flex;
  justify-content: center;
  padding: 10px 15px 5px;
  background-color: @top-content-popular-bg;
  .sol-clearfix;

  @media @sm {
    position: relative;
    display: none !important;
    padding: 16px 12px 8px;
    background-color: @color-2;
  }

  &__header {
    display: none !important;
    margin-bottom: 20px;
    padding-left: 8px;
    text-align: left;
  }

  & > a,
  & > div {
    float: left;
    margin-bottom: 5px;
    color: @top-content-popular-text;

    @media @sm {
      font-size: 13px;
    }
  }

  & > nav {
    display: block;
    padding: 10px 12px;
    color: @white;

    @media @sm {
      padding: 0;
    }

    & > a {
      margin-right: 15px;
      padding: 6px 14px;
      color: @white;
      font-weight: 300;
      font-size: 16px;
      text-decoration: none;
      background-color: @color-1;
      border: 1px solid @color-1;
      .transition(background-color, color, border);

      &:last-of-type {
        background-color: @color-4;
        border: 1px solid @color-4;

        &:hover {
          /*             border: 1px solid @color-4;
            background-color: #FBDCE2;
            color: @color-4; */
          text-decoration: underline;
          background-color: lighten(@color-4, 10%);
          border: 1px solid lighten(@color-4, 10%);
        }
      }

      @media @sm {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 12px;
        padding: 8px 20px;
      }

      @media @xs {
        margin-right: 4px;
        margin-bottom: 12px;
        padding: 6px 16px;
      }

      &:hover {
        /*           border: 1px solid @color-1;
          background-color: @color-2;
          color: @color-1; */
        text-decoration: underline;
        background-color: @hover;
        border: 1px solid @hover;
      }

      & i.fa,
      & i.fal,
      & i.far,
      & i.fas,
      & i.fab {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        padding-top: 1px;
        padding-left: 1px;
        text-align: center;
        border: 1px solid @top-content-popular-text;
        border-radius: 50%;
      }
    }
  }
}
//** Display popular pages links only on subweb Bostäder (mobile) **//
.sol-subweb-bostader .sol-popular-pages {
 @media @sm { 
    display: flex !important;
    margin-top: 30px;
  } 
}