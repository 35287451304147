.sol-event-status {
  display: block;
  margin-bottom: 10px;
  padding: 15px;
  text-align: center;
  background-color: rgb(235 63 0);

  h2 {
    display: inline-block;
    margin: 0;
    color: white;
  }
}
