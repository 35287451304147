header {
  position: relative;
  z-index: 50;
  width: 100%;

  &.sol-original-header {
    @media @md-strict {
      min-height: 105px;
    }

    @media @sm {
      min-height: 0;
    }

    & .sol-bottom {
      border-bottom: 1px solid @color-2;
    }
  }

  @media @sm {
    position: relative;
    margin-bottom: 0;
  }

  & .sol-top-menu {
    & > .sv-grid-main {
      max-width: 1255px;
      padding: 0;
    }
  }

  .sol-top-search {
    .transition(~  'background-color, height', 200ms);

    position: relative;
    display: none;
    height: 0;
    overflow: hidden;
    background-color: @search-top-bg;

    &.sol-active {
      height: 115px;
      background-color: @search-top-bg-active;

      @media @md {
        height: 100px;
      }

      @media @sm {
        position: absolute;
        top: 64px;
        z-index: 99999;
        width: 100%;
        max-height: 120px;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 40%);
        transition: none;
      }
    }

    & form {
      position: relative;
      display: block;
      width: 80%;
      max-width: 700px;
      margin: 35px auto;

      @media @md {
        width: 70%;
        margin-top: 25px;
        margin-bottom: 25px;
      }

      @media @sm {
        margin-top: 15px;
        margin-bottom: 20px;
      }

      @media @xs {
        width: 90%;
      }

      & button[type='submit'] {
        background-color: @color-1;
      }

      & div {
        border: 1px solid transparent;
      }

      & div:hover {
        border: 1px solid @color-1;

        & button[type='submit'] {
          background-color: @color-2;
          border: 1px solid @color-1;

          & i {
            color: @color-1;

            @media @sm {
              color: #fff !important;
            }
          }

          @media @sm {
            background-color: @color-1;
          }
        }
      }
    }

    &-close {
      .no-appearance;
      .focus-outline;
      .transition(color);

      position: absolute;
      top: 12px;
      left: 102%;
      padding: 10px 20px;
      color: @white;
      text-decoration: underline;
      background-color: @color-1;
      border: 1px solid @color-1;
      cursor: pointer;

      @media @md-strict {
        top: 15px;
        left: 102%;
        display: block;
      }

      @media @sm {
        top: 45px;
        right: calc(50% - 33px);
        left: unset;
        display: block;
        padding: 9px 20px;
      }

      &:hover {
        color: @color-1;
        background-color: @color-2;
      }
    }
  }

  .sol-top {
    position: relative;
    background: @color-1;

    @media @sm {
      top: 0;
      height: 64px;
      padding-top: 13px;
      padding-right: 0;
      padding-bottom: 8px;
      padding-left: 0;
      background-color: @color-1;
      border-bottom: 2px solid @color-2;
    }

    & > .sv-grid-main {
      @media @xs {
        padding-right: 0;
      }
    }

    & > .sv-grid-main {
      display: flex;
      justify-content: flex-end;

      & > div:first-child {
        display: flex;
        flex: 1 0 auto;
        justify-content: space-between;

        @media @sm {
          justify-content: flex-end;
        }

        #ToolmenuSkript {
          display: none;
        }
      }

      > * {
        flex: 0 0 auto;
      }
    }

    .sol-top-logo {
      height: 50px;

      & > img {
        width: auto;
        height: 50px;
        color: @white;
      }

      @media @sm {
        display: none;

        & > img {
          height: 32px;
        }
      }
    }
  }

  .sol-bottom {
    position: relative;
    top: 50px;
    text-align: center;
    background-color: @white;

    @media @md {
      display: none;
    }

    @media @sm {
      background-color: @header-menu-bg;
    }
  }
}

.sol-to-content {
  position: absolute;
  top: -3px;
  right: 100%;
  width: auto;
  height: auto;
  margin-right: 20px;
  margin-left: 100%;
  overflow: hidden;
  color: @white;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  text-align: center;
  background-color: @color-1;

  &:focus {
    position: relative;
    width: auto;
    height: auto;

    @media @sm {
      position: static;
      display: none;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

.sol-header {
  min-height: 106px;

  @media @sm {
    min-height: 64px;
  }

  .sol-top {
    min-height: 106px;
    padding-top: 28px;
  }

  &--transparent {
    background-color: @color-1;
    border-bottom: 3px solid @white;

    @media @sm {
      border-bottom: none;
    }

    .sol-top {
      top: 0;
      height: 55px;
      min-height: 55px;
      background-color: transparent;
    }
  }

  @media @sm {
    background-color: @color-1;

    .sol-top {
      top: 0;
      min-height: 64px;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}
