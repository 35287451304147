/* subpage/page-listing.less */
.sol-page-listing {
  //border-top: 2px solid @page-listing-border;
  border-radius: 3px;
  background-color: @page-listing-bg;
  padding: 20px 0 15px 10px;
  margin-bottom: -28px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  ul {
    .no-list-style;
  }
  &-item {
    flex: 1 0 25%;
    margin: 10px 40px 10px 0;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media @md {
      flex: 1 0 100%;
      margin: 0;
    }
    &__link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__name {
      color: inherit;
      margin-bottom: 20px;
      margin-top: 20px;
      font-weight: 300;
    }
    &__icon {
      font-size: 30px;
      float: left;
      margin-right: 7px;
      height: 30px;
      width: 30px;
      color: @page-listing-item-icon-color;
      background-color: @page-listing-item-icon-bg;
      border-radius: 50%;
      text-align: center;
      position: relative;
      top: -5px;
      padding-left: 2.5px;
      padding-top: 0;
      margin-left: -12px;
    }
  }
  &-child {
    &__link {
      display: block;
      padding: 16px 0;
      border-top: 1px solid @gray-2;
      font-size: 17px;
      color: @page-listing-child-color;
      text-decoration: none;
      .transition(color);
      &:hover {
        text-decoration: underline;
      }
      & i.fa-external-link {
        font-size: 11px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }

    }
    &__icon {
      font-size: 21px;
      padding-top: 5px;
      background-color:  @color-1;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      text-align: center;
      float: left;
      margin-right: 8px;
      color: @page-listing-child-icon-color;
      position: relative;
      top: -5px;
      padding-left: 2.5px;
    }
    &.hide {
      display:none;
    }
    &:last-of-type {
      & a {
        border-bottom: 1px solid @gray-2;
      }
    }
  }

  & .sol-show-all {
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: none;

    & .sol-show-more-btn {
      padding: 6px 12px;
      border: 1px solid @gray-1;
      transition-property: color,background-color;
      transition-duration: 0.2s;

      &:hover {
        background-color: @color-1;
        color: @white;
      }
    }
  }
}
