.sol-breadcrumbs-wrapper {
  position: relative;
  height: 56px;
  margin-top: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: @breadcrumbs-bg;

  @media @sm {
    position: absolute;
    z-index: 99;
    width: calc(100% - 55px);
    margin-top: -55px;
  }

  & > .sv-grid-main {
    height: 100%;

    & > .sv-script-portlet {
      height: 100%;

      & > nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 100%;
      }
    }
  }

  .sol-hide-sm;

  @media @sm {
    position: relative;
    z-index: 49;
    display: block;
    width: calc(100% - 55px);
    height: 55px;
    margin-top: 0;
    padding-top: 4px;
    padding-bottom: 6px;
    background-color: #e8f3f8;
  }
}

.sol-breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @media @sm {
    padding: 0;
  }

  @media @xs {
    max-width: 300px;
    max-height: 48px;
    overflow: hidden;
  }

  & li:first-of-type {
    & .sol-breadcrumbs__link {
      font-weight: 700;
    }
  }

  &__link {
    display: inline-block;
    color: @breadcrumbs-text;
    font-size: 13px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: @breadcrumbs-text-hover;
      text-decoration: underline;
    }

    &--active {
      color: @breadcrumbs-text-active;
    }

    &:not(&--active)::after {
      display: inline-block;
      height: 0.8em;
      margin: 0 8px;
      border-right: 1px solid @breadcrumbs-text-active;
      transform: rotate(15deg);
      content: '';
    }
  }
}
