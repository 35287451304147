.sol-publish-info {
  padding: 10px 0 5px;
  font-weight: 400;
  font-size: 15px;

  & dt,
  & dd {
    display: inline;
    margin: 0;
  }

  & dl {
    margin: 0;
  }

  & dt {
    margin-right: 0;
    font-weight: 600;
    font-size: 15px;

    @media @sm {
      font-size: 13px;
      line-height: 20px;
    }

    @media @xs {
      display: block;
    }
  }
}
